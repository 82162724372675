const COST_ESTIMATION_ELEMENT = {
  MATERIAL: {
    status_code: "MATERIAL",
    description: "วัตถุดิบ",
  },
  LABOR: {
    status_code: "LABOR",
    description: "ค่าแรง",
  },
  CAPITAL_COST: {
    status_code: "CAPITAL_COST",
    description: "เครื่องจั้กร และ ค่าใช้จ่ายในการลงทุน",
  },
};

module.exports = COST_ESTIMATION_ELEMENT;
