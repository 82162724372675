import _ from 'lodash';
import getTotalMaterialUsageOnPlace from '../../../cost-estimation/getTotalMaterialUsageOnPlace';
import { config } from '../../../../config';
import getPlaceLaborCostInDay from '../../../cost-estimation/getPlaceLaborCostInDay';
import filterAllowanceMaterial from '../../../cost-estimation/filterAllowanceMaterial';

const calculateCostDivideByWeight = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
  PlaceLaborCost,
  information,
  ObjectId,
}) => {
  const currentDate = currentLot?.receipt_date;
  const place = warehouseProfile?.place;

  const { total: totalAllLotWeight } = await getTotalMaterialUsageOnPlace({
    place,
    currentDate,
    axios: api,
    maxFetchSize: config.maxFetchSize,
    isFrontend,
  });

  const totalLaborCost = await getPlaceLaborCostInDay({
    axios: api,
    currentDate,
    placeId: place?._id,
    isFrontend,
    maxFetchSize: config.maxFetchSize,
    PlaceLaborCost,
    information,
    ObjectId,
  });

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  const allowMaterial = filterAllowanceMaterial(previousLotList, place);
  const costPerWeight = totalLaborCost / totalAllLotWeight;

  const materialListWithCost = _.map(allowMaterial, (each) => {
    const materialCost = (each?.quantity || 0) * costPerWeight;
    return {
      ...each,
      cost: materialCost,
    };
  });

  const totalCost = _.sumBy(materialListWithCost, 'cost');
  return { cost: totalCost, costList: materialListWithCost };
};

export default calculateCostDivideByWeight;
