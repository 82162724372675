import React from 'react';
import { MATERIAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import PropTypes from 'prop-types';

import MaterialAmountAndUnit from './MaterialAmountAndUnit';
import MaterialAmountPercentUsedCost from './MaterialAmountPercentUsedCost';

const MaterialCost = ({
  method,
  useCostFromPreviousMaterial,
  previousLotList,
  place,
  peerPlace,
}) => {
  switch (method) {
    case MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_UNIT_COST.status_code:
      return (
        <MaterialAmountAndUnit
          useCostFromPreviousMaterial={useCostFromPreviousMaterial}
          previousLotList={previousLotList}
          place={place}
        />
      );
    case MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_PERCENT_USED_COST
      .status_code:
      return (
        <MaterialAmountPercentUsedCost
          useCostFromPreviousMaterial={useCostFromPreviousMaterial}
          previousLotList={previousLotList}
          place={place}
          peerPlace={peerPlace}
        />
      );
    default:
      return <div></div>;
  }
};

export default MaterialCost;

MaterialCost.propTypes = {
  method: PropTypes.string,
  useCostFromPreviousMaterial: PropTypes.bool,
  previousLotList: PropTypes.array,
  place: PropTypes.object,
};
