import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function MaterialReportDashboard
 * @description Display a collections or a list of MaterialReportDashboard from database
 */

export default function MaterialReportDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const me = useSelector((state) => state.me);

  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.placeAll({ name: '', page: 1, size: config.maxFetchSize }),
      );
    } catch (error) {
      console.error('Place Error');
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const filteredPlace = _.filter(
    place.rows,
    (eachPlace) =>
      eachPlace?.allow_all_department ||
      _.includes(
        _.map(eachPlace?.allow_departments, (each) => each._id),
        me?.userData?.department?._id,
      ),
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (place.isLoading) {
    return <Loading />;
  }

  if (!place.isLoading && place.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <div>
            <div className="font-display">รายวัน</div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
              {me?.userData?.role?.level >= 2 && (
                <div className="my-4  px-4">
                  <Link
                    to={`/ims/dashboard/material-report-dashboard/detail?type=daily&material=&date=${dayjs().format(
                      'YYYY-MM-DD',
                    )}&interval=false`}
                  >
                    <div
                      className={
                        '  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 bg-green-600 '
                      }
                    >
                      <div className="flex justify-center">
                        <i className="fas fa-calendar-day text-4xl text-white" />
                      </div>
                      <p className=" text-white mt-4 font-display">
                        รายงานการใช้วัตถุดิบรายวัน <br />
                        ทั้งหมด
                      </p>
                    </div>
                  </Link>
                </div>
              )}
              {_.map(filteredPlace, (eachPlace, placeIndex) => (
                <div className="my-4  px-4" key={placeIndex}>
                  <Link
                    to={`/ims/dashboard/material-report-dashboard/detail?type=daily&material=&date=${dayjs().format(
                      'YYYY-MM-DD',
                    )}&interval=false&place=${eachPlace?._id}`}
                  >
                    <div
                      className={
                        '  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 bg-green-500 '
                      }
                    >
                      <div className="flex justify-center">
                        <i className="fas fa-calendar-day text-4xl text-white" />
                      </div>
                      <p className=" text-white mt-4 font-display">
                        รายงานการใช้วัตถุดิบรายวัน
                        <br />
                        คลัง{eachPlace?.name}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div>
            {' '}
            <div className="font-display">รายเดือน</div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
              {me?.userData?.role?.level >= 2 && (
                <div className="my-4  px-4">
                  <Link
                    to={`/ims/dashboard/material-report-dashboard/detail?type=monthy&material=&date=${dayjs().format(
                      'YYYY-MM-DD',
                    )}&interval=false`}
                  >
                    <div
                      className={
                        '  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 bg-blue-600 '
                      }
                    >
                      <div className="flex justify-center">
                        <i className="fas fa-calendar-day text-4xl text-white" />
                      </div>
                      <p className=" text-white mt-4 font-display">
                        รายงานการใช้วัตถุดิบรายเดือน
                        <br />
                        ทั้งหมด
                      </p>
                    </div>
                  </Link>
                </div>
              )}
              {_.map(filteredPlace, (eachPlace, placeIndex) => (
                <div className="my-4  px-4" key={placeIndex}>
                  <Link
                    to={`/ims/dashboard/material-report-dashboard/detail?type=monthy&material=&date=${dayjs().format(
                      'YYYY-MM-DD',
                    )}&interval=false&place=${eachPlace?._id}`}
                  >
                    <div
                      className={
                        '  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 bg-blue-500 '
                      }
                    >
                      <div className="flex justify-center">
                        <i className="fas fa-calendar-day text-4xl text-white" />
                      </div>
                      <p className=" text-white mt-4 font-display">
                        รายงานการใช้วัตถุดิบรายเดือน
                        <br />
                        คลัง{eachPlace?.name}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialReportDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialReportDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
