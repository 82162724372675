import React from 'react';
import {
  currencyFormatter,
  filterAllowanceMaterial,
} from '@iarcpsu/emanufac-utils/functions';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';

const CardChildLotByPlace = ({ lotList }) => {
  const groupByPlace = _.groupBy(lotList, 'place._id');

  // Order place by receipt date
  const orderedPlace = _.orderBy(groupByPlace, (o) => o[0].receipt_date, 'asc');

  return (
    <div>
      <Card>
        <CardContent>
          {_.map(orderedPlace, (placedOrder) => {
            const selectedPlace = placedOrder[0].place;
            const allowanceLot = filterAllowanceMaterial(
              placedOrder,
              selectedPlace,
            );
            const totalQuantity = _.sumBy(allowanceLot, 'quantity');
            return (
              <div className="my-2">
                <div className="font-semibold my-1">{selectedPlace?.name}</div>
                <div>รวมปริมาณ {currencyFormatter.format(totalQuantity)} </div>
                <div className="grid grid-cols-2">
                  {_.map(allowanceLot, (lot, index) => (
                    <div className="m-1 p-1 border rounded-md" key={index}>
                      <h4>{lot?.material?.name}</h4>
                      <div className="text-sm">
                        <MultiUnitProdOrMatBox
                          foundMaterial={lot?.material}
                          quantity={lot?.quantity}
                        />
                      </div>
                      <div className="text-xs p-1 bg-gray-200">
                        {currencyFormatter.format(
                          (lot?.quantity * 100) / totalQuantity,
                        )}{' '}
                        %
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default CardChildLotByPlace;

CardChildLotByPlace.propTypes = {
  lotList: PropTypes.arrayOf(PropTypes.object),
};
