import { LABOR_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import calculateCostTDABC from './labor/calculateCostTDABC';
import calculateCostDivideByWeight from './labor/calculateCostDivideByWeight';
import calculateCostLumpsumByMaterialOriginal from './labor/calculateCostLumpsumByMaterialOriginal';
import calculateCostLumpsumByWeight from './labor/calculateCostLumpsumByWeight';

const calculateLaborCost = async ({
  warehouseProfile,
  previousLotList,
  currentLot,
  api,
  isFrontend,
  information,
  mongooseModel = {},
}) => {
  let cost = 0;
  let costList = [];
  let tempData = { cost: 0, costList: [] };
  switch (warehouseProfile?.labor?.method) {
    case LABOR_COST_ESTIMATION_METHOD.DIVIDE_BY_LOT_WEIGHT.status_code:
      tempData = await calculateCostDivideByWeight({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        information,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    case LABOR_COST_ESTIMATION_METHOD.LUMPSUM_BY_MATERIAL.status_code:
      tempData = await calculateCostLumpsumByWeight({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        information,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    case LABOR_COST_ESTIMATION_METHOD.LUMPSUM_BY_MATERIAL_FROM_ORIGINAL
      .status_code:
      tempData = await calculateCostLumpsumByMaterialOriginal({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        information,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    case LABOR_COST_ESTIMATION_METHOD.TDABC.status_code:
      tempData = await calculateCostTDABC({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        information,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    default:
      break;
  }
  return { cost, costList };
};

export default calculateLaborCost;
