import React from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import NameBox from '../Common/NameBox';

const BoxQuotationBySales = ({ quotationBySales, startDate, endDate }) => {
  const orderByPrice = _.orderBy(quotationBySales, ['price'], ['desc']);
  return (
    <div>
      <div className="flex justify-between">
        <h3 className="font-semibold text-lg">ผู้เสนอราคา</h3>
        <div>
          <Link
            to={`/spm/quotation/by-sales?startDate=${dayjs(startDate).format(
              'YYYY-MM-DD',
            )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
          >
            <Button endIcon={<ExternalLink size={16} />}>พนักงานทั้งหมด</Button>
          </Link>
        </div>
      </div>
      <div className="my-2">
        {_.map(orderByPrice, (eachSales) => (
          <div className="flex justify-between my-1">
            <div className="w-full lg:w-2/3 cursor-pointer hover:text-gray-500 hover:underline">
              <Link
                to={`/spm/quotation/by-sales/${
                  eachSales?.sales?._id
                }?startDate=${dayjs(startDate).format(
                  'YYYY-MM-DD',
                )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
              >
                <div className="flex gap-1 items-center">
                  <NameBox user={eachSales?.sales} />
                  <ExternalLink size={16} />
                </div>
              </Link>
            </div>
            <div className="text-right w-full lg:w-1/3">
              <div className="text-sm text-gray-500">
                {eachSales?.total} ใบเสนอราคา
              </div>
              {currencyFormatter.format(eachSales?.price)} บาท
            </div>
          </div>
        ))}
      </div>
      <div className="text-xs text-gray-800 text-right">
        คลิกที่ชื่อพนักงานเพื่อดูรายการของแต่ละคน
      </div>
    </div>
  );
};

export default BoxQuotationBySales;

BoxQuotationBySales.propTypes = {
  quotationBySales: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};
