import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  BoxQuotationByProductOrMaterial,
  BoxQuotationBySales,
  BoxQuotationCostByProductOrMaterial,
  BoxQuotationStatus,
  DaySpotAndRangePicker,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';
import { useQuery } from '@/utils/functions';

/**
 * @function QuotationDashboard
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function QuotationDashboard({ title, subtitle }) {
  const params = useParams();
  const browserQuery = useQuery();
  const [startDate, setStartDate] = useState(
    browserQuery.get('start')
      ? dayjs(browserQuery.get('start')).startOf('month').toDate()
      : dayjs().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(
    browserQuery.get('end')
      ? dayjs(browserQuery.get('end')).endOf('month').toDate()
      : dayjs().endOf('month').toDate(),
  );
  const [groupByStatusDataIsLoading, setGroupByStatusDataIsLoading] = useState(
    true,
  );
  const [groupByStatusData, setGroupByStatusData] = useState([]);
  const [groupByProductData, setGroupByProductData] = useState([]);
  const [groupByMaterialData, setGroupByMaterialData] = useState([]);
  const [groupBySalesData, setGroupBySalesData] = useState([]);
  const [groupByProductIsLoading, setGroupByProductIsLoading] = useState(true);
  const [groupBySalesIsLoading, setGroupBySalesIsLoading] = useState(true);

  const queryGroupByDateData = async () => {
    try {
      const groupByStatusQuery = new URLSearchParams({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
      });
      const { data: tempGroupByDateData } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/quotation/group-by-status?${groupByStatusQuery.toString()}`,
      );
      setGroupByStatusData(tempGroupByDateData);
      console.log('tempGroupByDateData', tempGroupByDateData);
      setGroupByStatusDataIsLoading(false);
    } catch (error) {
      console.error('Quotation Dashboard Error', error);
    }
  };

  const queryGroupByProduct = async () => {
    try {
      const groupByStatusQuery = new URLSearchParams({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        completed: true,
      });
      const { data: tempGroupByProduct } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/quotation/group-by-product?${groupByStatusQuery.toString()}`,
      );
      setGroupByProductData(tempGroupByProduct);
      console.log('temp group by product', tempGroupByProduct);
      setGroupByProductIsLoading(false);
    } catch (error) {
      console.error('Quotation Dashboard Error', error);
    }
  };

  const queryGroupBySales = async () => {
    try {
      const groupByStatusQuery = new URLSearchParams({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        completed: true,
      });
      const { data: tempGroupBySales } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/quotation/group-by-sales?${groupByStatusQuery.toString()}`,
      );
      setGroupBySalesData(tempGroupBySales);
      console.log('temp group by sales', tempGroupBySales);
      setGroupBySalesIsLoading(false);
    } catch (error) {
      console.error('Quotation Dashboard Error', error);
    }
  };

  const queryGroupByMaterial = async () => {
    try {
      const groupByStatusQuery = new URLSearchParams({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        completed: true,
      });
      const { data: tempGroupByMaterial } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/quotation/group-by-material?${groupByStatusQuery?.toString()}`,
      );
      setGroupByMaterialData(tempGroupByMaterial);
      console.log('temp group by material', tempGroupByMaterial);
      setGroupByProductIsLoading(false);
    } catch (error) {
      console.error('Quotation Dashboard Error', error);
    }
  };

  useEffect(() => {
    setGroupByStatusDataIsLoading(true);
    setGroupByProductIsLoading(true);
    queryGroupByDateData();
    queryGroupByProduct();
    queryGroupByMaterial();
    queryGroupBySales();
    return () => {};
  }, [params, startDate, endDate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-2">
        <Card>
          <CardContent>
            <DaySpotAndRangePicker
              state="month"
              flex
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              addSearchQuery
              anotherQuery="?"
              showTypeSelector
            />
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            {groupByStatusDataIsLoading ? (
              <Skeleton />
            ) : (
              <div>
                <BoxQuotationStatus
                  quotationAnalytic={groupByStatusData}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      <div className="flex flex-wrap my-1">
        <div className="w-full md:w-1/2 md:pr-1">
          <Card>
            <CardContent>
              {groupByProductIsLoading ? (
                <Skeleton />
              ) : (
                <div>
                  <BoxQuotationByProductOrMaterial
                    quotationByProducts={groupByProductData?.rows}
                    typeKey="product"
                    typeKeyTH="สินค้า"
                    startDate={startDate}
                    endDate={endDate}
                  />
                  {_.size(groupByMaterialData?.rows) > 0 &&
                    groupByMaterialData?.rows[0]?.product_as_material?._id && (
                      <BoxQuotationByProductOrMaterial
                        quotationByProducts={groupByMaterialData?.rows}
                        typeKey="product_as_material"
                        typeKeyTH="วัตถุดิบ"
                        startDate={startDate}
                        endDate={endDate}
                      />
                    )}
                </div>
              )}
            </CardContent>
          </Card>
          <Card className="my-2">
            <CardContent>
              {groupByProductIsLoading ? (
                <Skeleton />
              ) : (
                <div>
                  <BoxQuotationCostByProductOrMaterial
                    quotationByProducts={groupByProductData?.rows}
                    typeKey="product"
                    typeKeyTH="สินค้า"
                    startDate={startDate}
                    endDate={endDate}
                  />
                  {_.size(groupByMaterialData?.rows) > 0 &&
                    groupByMaterialData?.rows[0]?.product_as_material?._id && (
                      <BoxQuotationCostByProductOrMaterial
                        quotationByProducts={groupByMaterialData?.rows}
                        typeKey="product_as_material"
                        typeKeyTH="วัตถุดิบ"
                        startDate={startDate}
                        endDate={endDate}
                      />
                    )}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        <div className="w-full md:w-1/2 md:pl-1">
          <Card>
            <CardContent>
              {groupBySalesIsLoading ? (
                <Skeleton />
              ) : (
                <div>
                  <BoxQuotationBySales
                    quotationBySales={groupBySalesData?.rows}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

QuotationDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuotationDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
