import React, { useContext } from 'react';
import { INVENTORY_WORKFLOW } from '@iarcpsu/emanufac-constant';
import { currencyFormatter } from '@iarcpsu/emanufac-utils/functions';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

import NameBox from '../Box/Common/NameBox';
import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';

const CardInfoMaterialStockLot = ({
  materialStockLot,
  information,
  showDetail = true,
}) => {
  const imsSetting = information?.setting?.ims;
  const { findWord } = useContext(WordLocalizeContext);
  return (
    <div>
      <Card>
        <CardContent>
          <div className="text-lg font-semibold my-1">
            ข้อมูลล็อตวัตถุดิบปัจจุบัน
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">หมายเลขอ้างอิง</div>
                <div className="w-2/3">{materialStockLot?.running_number}</div>
              </div>
              {imsSetting?.display?.lot_number && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">หมายเลขล็อต</div>
                  <div className="w-2/3">{materialStockLot?.lot_number}</div>
                </div>
              )}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วันที่รับเข้าคลัง</div>
                <div className="w-2/3">
                  {imsSetting?.showTime
                    ? dayjs(materialStockLot?.receipt_date).format(
                        'D MMM BBBB HH.mm',
                      )
                    : dayjs(materialStockLot?.receipt_date).format(
                        'D MMM BBBB',
                      )}
                </div>
              </div>
              {imsSetting?.display?.purchase_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">
                    {findWord('purchase_date') || 'วันที่ซื้อ'}
                  </div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.purchase_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}
              {imsSetting?.display?.production_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วัดผลิต</div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.production_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}
              {imsSetting?.display?.expiration_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วันหมดอายุ</div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.expiration_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}{' '}
              <br className="my-2" />
              {showDetail && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">ผู้สร้างล็อต</div>
                  <div className="w-2/3">
                    {materialStockLot?.employee ? (
                      <NameBox user={materialStockLot?.employee} />
                    ) : (
                      materialStockLot?.recipient_name
                    )}
                  </div>
                </div>
              )}{' '}
              {showDetail && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วันที่บันทึกข้อมูล</div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.createdAt).format(
                      'D MMM BBBB HH.mm',
                    )}
                  </div>
                </div>
              )}{' '}
              {showDetail && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">อัพเดทล่าสุด</div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.updatedAt).format(
                      'D MMM BBBB HH.mm',
                    )}
                  </div>
                </div>
              )}{' '}
            </div>{' '}
            <div className="w-full lg:w-1/2">
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วัตถุดิบ</div>
                <div className="w-2/3">
                  {materialStockLot?.material?.type_code || ''}{' '}
                  {materialStockLot?.material?.name}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">คลังวัตถุดิบ</div>
                <div className="w-2/3">
                  {materialStockLot?.place?.name || '-'}
                </div>
              </div>{' '}
              {imsSetting?.display?.source && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">
                    {findWord('material_source') || 'แหล่งวัตถุดิบ'}
                  </div>
                  <div className="w-2/3">
                    {materialStockLot?.source?.name || '-'}
                  </div>
                </div>
              )}
              {showDetail && imsSetting?.display?.machine && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">เครื่องจักร</div>
                  <div className="w-2/3">
                    {materialStockLot?.machine?.name || '-'}
                  </div>
                </div>
              )}
              {showDetail &&
                materialStockLot?.place?.place_action?.enable_customer && (
                  <div className="flex w-full my-2">
                    <div className="w-1/3 font-semibold">ลูกค้า</div>
                    <div className="w-2/3">
                      {materialStockLot?.customer?.name || '-'}
                    </div>
                  </div>
                )}
              {showDetail &&
                materialStockLot?.place?.place_action
                  ?.enable_add_prefer_product && (
                  <div className="flex w-full my-2">
                    <div className="w-1/3 font-semibold">สินค้า</div>
                    <div className="w-2/3">
                      {materialStockLot?.prefer_product?.name || '-'}
                    </div>
                  </div>
                )}
              {showDetail &&
                imsSetting?.display?.workflow_type_and_duration && (
                  <div className="flex w-full my-2">
                    <div className="w-1/3 font-semibold">รูปแบบกิจกรรม</div>
                    <div className="w-2/3">
                      {
                        INVENTORY_WORKFLOW[materialStockLot?.inventory_workflow]
                          ?.description
                      }
                    </div>
                  </div>
                )}{' '}
              {showDetail && imsSetting?.display?.workflow_type_and_duration && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">ระยะเวลา</div>
                  <div className="w-2/3">
                    {materialStockLot?.duration} นาที
                    {materialStockLot?.duration >= 60 && (
                      <div>
                        ( {Math.floor(materialStockLot?.duration / 60)} ชั่วโมง{' '}
                        {materialStockLot?.duration % 60} นาที)
                      </div>
                    )}
                  </div>
                </div>
              )}
              <hr className="my-4 w-3/4" />
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ปริมาณเริ่มต้น</div>
                <div className="w-2/3">
                  <MultiUnitProdOrMatBox
                    foundMaterial={materialStockLot?.material}
                    noWrap
                    quantity={materialStockLot?.quantity}
                  />{' '}
                </div>
              </div>{' '}
              {showDetail && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">คงเหลือ</div>
                  <div className="w-2/3">
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.material}
                      noWrap
                      quantity={materialStockLot?.amount}
                    />{' '}
                  </div>
                </div>
              )}{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ราคาทั้งล็อต</div>
                <div className="w-2/3">
                  {currencyFormatter.format(materialStockLot?.price) || '-'} บาท
                  <div className="text-sm">
                    ราคาต่อหน่วย:{' '}
                    {currencyFormatter.format(
                      materialStockLot?.price / materialStockLot?.quantity,
                    )}{' '}
                    บาท
                  </div>
                </div>
              </div>
              {showDetail && !_.isEmpty(materialStockLot?.another_employees) && (
                <div>
                  <div className="font-semibold py-1">พนักงานที่ร่วมทำงาน</div>
                  {_.map(materialStockLot?.another_employees, (each, index) => (
                    <li key={index}>
                      {each?.employee?.firstname} {each?.employee?.lastname}
                      {each?.amount >= 1 && (
                        <span>
                          <span className="font-semibold text-blue-600">
                            x {each?.amount}
                          </span>
                          {'  '}
                          คน
                        </span>
                      )}
                    </li>
                  ))}
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CardInfoMaterialStockLot;

CardInfoMaterialStockLot.propTypes = {
  materialStockLot: PropTypes.object,
  information: PropTypes.object,
};
