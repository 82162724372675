import React from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button, Chip } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

const BoxQuotationCostByProductOrMaterial = ({
  quotationByProducts,
  typeKey,
  typeKeyTH,
  startDate,
  endDate,
}) => {
  const orderByPrice = _.orderBy(quotationByProducts, ['price'], ['desc']);

  const mostQuantity = orderByPrice[0];
  const anotherQuantity = _.tail(orderByPrice);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-lg font-semibold mb-2">
          มูลค่า{typeKeyTH}ที่เสนอราคา
        </div>
        <Link
          to={`/spm/quotation/by-${typeKey}?startDate=${dayjs(startDate).format(
            'YYYY-MM-DD',
          )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
        >
          <Button endIcon={<ExternalLink size={16} />}>
            {typeKeyTH}ทั้งหมด
          </Button>
        </Link>
      </div>
      <div className="w-full">
        <Link
          className="text-xl flex items-center gap-2"
          to={`/spm/quotation/by-${typeKey}/detail/${
            mostQuantity?.[typeKey]?._id
          }?startDate=${dayjs(startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
            endDate,
          ).format('YYYY-MM-DD')}`}
        >
          <Chip label="#1" size="small" className="mr-1" />{' '}
          {mostQuantity?.[typeKey]?.name} <ExternalLink size={14} />
        </Link>
        <div className="flex justify-end">
          {currencyFormatter.format(mostQuantity?.price)} บาท
        </div>
      </div>
      <hr className="my-2" />
      {_.map(anotherQuantity, (eachProduct) => (
        <div className="flex justify-between">
          <Link
            className="flex gap-2 items-center"
            to={`/spm/quotation/by-${typeKey}/detail/${
              eachProduct?.[typeKey]?._id
            }?startDate=${dayjs(startDate).format(
              'YYYY-MM-DD',
            )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
          >
            <div>{eachProduct?.[typeKey]?.name}</div>
            <ExternalLink size={12} />
          </Link>
          {currencyFormatter.format(eachProduct?.price)} บาท
        </div>
      ))}
    </div>
  );
};

export default BoxQuotationCostByProductOrMaterial;

BoxQuotationCostByProductOrMaterial.propTypes = {
  quotationByProducts: PropTypes.arrayOf(PropTypes.object),
  typeKey: PropTypes.string,
  typeKeyTH: PropTypes.string,
};
