import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  MaterialLotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function DetailCostEstimationByProcess
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailCostEstimationByProcess({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const materailStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processGet(params.id));
      dispatch(
        actions.materialStockLotAll({
          selectedProcess: params.id,
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <MaterialLotTable
            imsSetting={information?.setting?.ims}
            multiMaterial
            materialStockLot={materailStockLot}
            page={page}
            size={size}
            setPage={setPage}
            setSize={setSize}
            total={materailStockLot.total}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCostEstimationByProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCostEstimationByProcess.defaultProps = {
  title: '',
  subtitle: '',
};
