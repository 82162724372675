import _ from 'lodash';
import findMaterialOriginalCost from '../../../cost-estimation/findMaterialOriginalCost';

// TODO: ยังไม่ได้ทดสอบในฝั่ง Backend
const calculateCostFromMaterialAmount = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
}) => {
  let cost = 0;

  const place = warehouseProfile?.place;
  console.log('On Filter Material On Place');
  console.log('Previous lot lots size', _.size(previousLotList));
  const materialOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id?.toString() === place?._id?.toString(),
  );
  const allowCategory = _.map(place?.allow_categories, (e) => e.toString());

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  const filterAllowanceMaterial = _.filter(materialOnPlace, (each) =>
    _.includes(allowCategory, each?.material?.material_type?.toString()),
  );

  console.log('Start find Original Cost List');

  const originalCostList = findMaterialOriginalCost({
    allowanceMaterial: filterAllowanceMaterial,
    allLotList: previousLotList,
    materialOnPlace,
    useCostFromPrevious:
      warehouseProfile?.material?.use_cost_from_pervious_material,
  });

  // console.log('Original Cost List', originalCostList);

  const findAverageCost = (eachMaterialOriginalCost) => {
    const amountOfList = _.size(eachMaterialOriginalCost);
    const totalCost = _.sumBy(
      eachMaterialOriginalCost,
      (each) => each?.unit_cost || 0,
    );
    return totalCost / amountOfList;
  };
  console.log('Start find Total from Unuse Previous Cost');

  const totalFromNoUsePreviousCost = _.sum(
    _.map(
      originalCostList,
      (each) => each?.unit_cost || 0 * (each?.quantity || 0),
    ),
  );

  const totalFromUsePreviousCost = _.sum(
    _.map(
      originalCostList,
      (each) => findAverageCost(each?.original_costs) * (each?.quantity || 0),
    ),
  );

  if (warehouseProfile?.material?.use_cost_from_pervious_material) {
    cost = totalFromUsePreviousCost;
  } else {
    cost = totalFromNoUsePreviousCost;
  }
  console.log('Finish Calculation in Calculate Cost From Material Amount');
  return { cost, costList: originalCostList };
};

export default calculateCostFromMaterialAmount;
