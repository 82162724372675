import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  BackButton,
  DecreaseProductForm,
  Error,
  Loading,
  ProductStockForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { PRODUCT_TRANSACTION_TYPE } from '@iarcpsu/emanufac-constant';
import { Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

const defaultValues = {
  product_transaction_type: '',
  // production_number: '',
  warehouse: '',
  type: '',
  supplier: '',
  customer: '',
  quantity: '',
  price: '',
  discount: '',
  product: '',
  remark: '',
  payment: '',
  shipping: '',
  tracking_number: '',
  shipping_cost: '',
  seller: '',
  production_date: new Date(),
  expiration_date: new Date(),
  inventory_date: new Date(),
  issue_date: new Date(),
  sale_date: new Date(),
};

// Original By ApiwanaSuwannapoom

const DecreaseProductStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const product = useSelector((state) => state.product);
  const warehouse = useSelector((state) => state.warehouse);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const paymentType = useSelector((state) => state.paymentType);
  const shippingType = useSelector((state) => state.shippingType);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      inventory_date: dayjs().format('YYYY-MM-DD'),
    },
  });

  const tempSelectedType = _.find(
    productTransactionType?.rows,
    (each) => each?._id === query.get('txnType'),
  );

  const [type, setType] = useState(
    tempSelectedType || {
      direction: PRODUCT_TRANSACTION_TYPE.desc.status_code,
    },
  );
  const [filterWarehouse, setFilterWarehouse] = useState(null);

  useEffect(() => {
    dispatch(actions.productTransactionTypeReset());
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.productTransactionTypeAll({
        direction: PRODUCT_TRANSACTION_TYPE.desc.status_code,
      }),
    );
    dispatch(
      actions.warehouseAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    dispatch(
      actions.customerAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    dispatch(
      actions.paymentTypeAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    dispatch(
      actions.shippingTypeAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.productAll({
        name: '',
        size: '',
        page: 1,
      }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    if (!query.get('txnType') && _.isArray(productTransactionType?.rows)) {
      setValue(
        'product_transaction_type',
        productTransactionType?.rows?.[0]?._id,
      );
    }

    return () => {};
  }, [productTransactionType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    console.error('Error', errors);
    const payload = data;
    console.log('Data', data);
    const selectProductLot = _.reduce(
      data.products,
      (result, value) => {
        _.map(value.lot, (eachLot) => {
          if (eachLot?.enable) {
            result.push({
              ...eachLot,
              amount: eachLot?.lot?.amount - parseFloat(eachLot?.quantity),
              lotId: eachLot?.lot?._id,
            });
          }
        });
        return result;
      },
      [],
    );

    console.log('Select Product Lot', selectProductLot);

    try {
      if (_.isEmpty(selectProductLot)) {
        alert('กรุณาเลือกล็อตสินค้า');
      } else if (!_.isEmpty(selectProductLot)) {
        payload.productLots = selectProductLot;
        payload.price = _.sumBy(selectProductLot, (lot) => lot.price);
        payload.total_price = _.sumBy(selectProductLot, (lot) => lot.price);
        // Adding Price and discount
        // payload.discount = parseInt(discount, 10);
        //  - discount;
        console.log('payload', payload);
        await dispatch(actions.productStockLotDecrease(payload));
        reset(defaultValues);
        Swal.fire({
          icon: 'success',
          title: 'เบิกสินค้าสำเร็จ',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.goBack();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (product.isLoading || productTransactionType.isLoading) {
    return <Loading />;
  }
  if (
    !product.isLoading &&
    product.isCompleted &&
    !productTransactionType.isLoading &&
    productTransactionType.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full px-1 py-2">
              <Card className="p-6">
                <div className="py-2">
                  <ProductStockForm
                    control={control}
                    errors={errors}
                    warehouse={warehouse.rows}
                    productTransactionType={productTransactionType.rows}
                    customer={customer.rows}
                    me={me.userData}
                    paymentType={paymentType.rows}
                    shippingType={shippingType.rows}
                    type={type}
                    setType={setType}
                    setFilterWarehouse={setFilterWarehouse}
                  />
                </div>
              </Card>
            </div>
            <div className="w-full px-1 py-2">
              <DecreaseProductForm
                product={product.rows}
                type={type}
                filterWarehouse={filterWarehouse}
                control={control}
                setValue={setValue}
                watch={watch}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={product?.message} />;
};

DecreaseProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DecreaseProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default DecreaseProductStockLot;
