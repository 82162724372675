import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function QuotationByProductList
 * @description Display a collections or a list of quotationByProductList from database
 */

export default function QuotationByProductList({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState();
  const browserQuery = useQuery();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.productAll({ page, size, name }));
    } catch (error) {
      dispatch(actions.productError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (product?.rows) {
      setTotal(product?.total);
    }

    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <Card className="my-2">
          <CardContent>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <TextField
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label="ค้นหา"
                  size="small"
                  fullWidth
                />
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อสินค้า</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(product?.rows) ? (
                    _.map(product?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>
                          <span className="font-semibold">
                            {row?.type_code}
                          </span>{' '}
                          {row?.name}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/spm/quotation/by-product/detail/${
                              row?._id
                            }?startDate=${
                              browserQuery.get('startDate')
                                ? dayjs(browserQuery.get('startDate')).format(
                                    'YYYY-MM-DD',
                                  )
                                : ''
                            }&endDate=${
                              browserQuery.get('endDate')
                                ? dayjs(browserQuery.get('endDate')).format(
                                    'YYYY-MM-DD',
                                  )
                                : ''
                            }`}
                          >
                            <Button variant="contained">รายละเอียด</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(e, newPage) => {
                setPage(newPage + 1);
              }}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
                setPage(1);
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

QuotationByProductList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuotationByProductList.defaultProps = {
  title: '',
  subtitle: '',
};
