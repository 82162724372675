import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { NotAuthorized } from '@iarcpsu/emanufac-components/src/components/Error';

import accessRight from '../../utils/functions/accessRight';
import {
  CustomerTypes,
  EditCustomerType,
  ModuleSettingCRM,
} from '../../views/CRM';

export function SettingSPM() {
  const module = 'CRM';
  const prefix = '/setting/crm';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/customer-types`}>
          <CustomerTypes title="จัดการประเภทลูกค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/customer-type/edit/:id`}>
          <EditCustomerType title="แก้ไขประเภทลูกค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/module-setting`}>
          <ModuleSettingCRM
            title="ตั้งค่าระบบลูกค้า/คู่ค้าสัมพันธ์"
            subtitle={name}
          />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingSPM;
