import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { DATA_TYPE } from '@/utils/constants';

import 'dayjs/locale/th';

dayjs.extend(BuddhistCalendar);

const SettingIMS = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);

  const { control, handleSubmit } = useForm({
    defaultValues: { setting: information?.setting },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'setting.wms.additionalAttribute',
  });

  useEffect(() => {
    dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              ims: {
                ...data.setting.ims,
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        <div className="w-full py-2 px-2 font-semibold font-display ">
          รายละเอียดในคลังวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          ส่วนประกอบในฟอร์มการเพิ่มลดวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            {/* <Controller
              control={control}
              name="setting.ims.display.receipt_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันที่รับวัตถุดิบ"
                />
              )}
            /> */}
            <Controller
              control={control}
              name="setting.ims.display.purchase_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันที่ซื้อ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.production_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันผลิต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.expiration_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันหมดอายุ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.lot_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="หมายเลขล็อต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.source"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แหล่งวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.display.bill_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เลขที่บิล"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.display.total_on_create_material_lot"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงผลรวมปริมาณวัตถุดิบ ในการสร้างล็อตวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.display.machine"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เครื่องจักรที่ใช้ทำงาน"
                />
              )}
            />{' '}
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบในรายละเอียดวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.ims.materialAttribute.net"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ปริมาตรสุทธิ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.materialAttribute.register_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เลขทะเบียนวัตถุดิบ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.materialAttribute.common_price"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ราคาทั่วไป"
                />
              )}
            />
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบเพิ่มเติม</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            {_.map(fields, (eachField, index) => (
              <div key={eachField.id} className="flex my-2">
                <div className="w-1/2 px-1">
                  <Controller
                    control={control}
                    name={`setting.ims.additionalAttribute.[${index}].name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ชื่อ"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="w-1/4 px-1">
                  <Controller
                    control={control}
                    name={`setting.ims.additionalAttribute.[${index}].type`}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>รูปแบบข้อมูล</InputLabel>
                        <Select {...field} size="small" fullWidth>
                          {_.map(
                            _.filter(DATA_TYPE, { is_basic: true }),
                            (eachDataType, idx) => (
                              <MenuItem
                                key={idx}
                                value={eachDataType?.type_code}
                              >
                                {eachDataType?.description}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-1/4 px-1">
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
            <div>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => append({})}
              >
                เพิ่ม
              </Button>
            </div>
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">การใช้การประมาณต้นทุน</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.ims.cost_estimation.use_cost_estimation_profile"
              render={({ field }) => (
                <div className="flex gap-2 items-center">
                  <div>ใช้ระบบต้นทุนกิจกรรมตามเวลาเบื้องต้น</div>
                  <FormControlLabel
                    {...field}
                    control={<Switch defaultChecked={field.value} />}
                    label="ใช้ Cost Estimation Profile"
                  />
                </div>
              )}
            />
            <div className="w-full my-2">
              คลังที่จะตั้งเป็นคลังเริ่มต้นในหน้าประมาณต้นทุน
              <div className="py-1">
                <Controller
                  control={control}
                  name="setting.ims.cost_estimation.default_place_in_cost_estimation"
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel>คลังเริ่มต้น</InputLabel>
                      <Select size="small" {...field}>
                        <MenuItem value={null}>ทุกคลัง</MenuItem>
                        {_.map(place?.rows, (eachPlace, placeIndex) => (
                          <MenuItem key={placeIndex} value={eachPlace?._id}>
                            {eachPlace?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="w-full my-2">
              จุดเริ่มต้นของวัตถุดิบ
              <div className="py-1">
                <Controller
                  control={control}
                  name="setting.ims.cost_estimation.begining_place_in_cost_estimation"
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel>คลังเริ่มต้น</InputLabel>
                      <Select size="small" {...field}>
                        <MenuItem value={null}>ทุกคลัง</MenuItem>
                        {_.map(place?.rows, (eachPlace, placeIndex) => (
                          <MenuItem key={placeIndex} value={eachPlace?._id}>
                            {eachPlace?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingIMS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingIMS.defaultProps = {
  title: '',
  subtitle: '',
};
export default SettingIMS;
