import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MaterialLumpSumCostInput = ({
  control,
  index,
  defaultValue,
  watch,
  materials,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `material.${index}.labor_cost_with_condition.conditions`,
  });

  return (
    <div>
      {!watch(`material.${index}.labor_cost_with_condition.enable`) && (
        <Controller
          control={control}
          name={`material.${index}.unit_labor_cost`}
          defaultValue={defaultValue?.unit_labor_cost || 0}
          render={({ field }) => (
            <TextField
              {...field}
              label="ต้นทุนค่าแรงต่อหน่วย"
              variant="outlined"
              size="small"
              fullWidth
            />
          )}
        />
      )}
      <div>
        <Controller
          control={control}
          name={`material.${index}.labor_cost_with_condition.enable`}
          defaultValue={
            defaultValue?.labor_cost_with_condition?.enable || false
          }
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ใช้แบบมีเงื่อนไข"
            />
          )}
        />
      </div>

      {watch(`material.${index}.labor_cost_with_condition.enable`) && (
        <div>
          <div>
            {_.map(fields, (eachField, fieldIndex) => (
              <div key={eachField.id} className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                  {!_.isEmpty(materials) && (
                    <Controller
                      control={control}
                      name={`material.${index}.labor_cost_with_condition.conditions[${fieldIndex}].material`}
                      defaultValue={eachField.material}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          options={materials}
                          size="small"
                          placeholder="ถ้ามาจากวัตถุดิบ"
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            if (!value?._id) {
                              return option?._id === value;
                            }
                            return option?._id === value?._id;
                          }}
                          className="z-40"
                          // prettier-ignore
                          getOptionLabel={(option) => `${option?.name || ''}`
                       }
                          renderInput={(params) => (
                            <TextField label="ถ้ามาจากวัตถุดิบ" {...params} />
                          )}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="w-full lg:w-1/4 px-1">
                  <Controller
                    control={control}
                    name={`material.${index}.labor_cost_with_condition.conditions[${fieldIndex}].cost`}
                    defaultValue={eachField.cost || 0}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ต้นทุนค่าแรง"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="w-full lg:w-1/4 px-1">
                  <Button
                    size="small"
                    onClick={() => remove(fieldIndex)}
                    variant="contained"
                    color="error"
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button size="small" onClick={() => append()} variant="contained">
            เพิ่มเงื่อนไข
          </Button>
        </div>
      )}
    </div>
  );
};

export default MaterialLumpSumCostInput;

MaterialLumpSumCostInput.propTypes = {
  control: PropTypes.object,
  index: PropTypes.number,
  defaultValue: PropTypes.object,
};
