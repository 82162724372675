import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

const getEmployeePayrollUnitCostOnTimeBackend = async ({
  representEmployee,
  timePair,
  information,
  PayrollAdjustment,
  ObjectId,
}) => {
  try {
    const pipeline = [
      {
        $match: {
          employee: ObjectId(representEmployee?._id),
        },
      },
      {
        $match: {
          $and: [
            {
              date: {
                $lte: dayjs(timePair?.[1]).endOf('date').toDate(),
              },
            },
          ],
        },
      },
    ];
    const data = await PayrollAdjustment.aggregate(pipeline);
    const latestAdjustment = _.last(data);
    const lastAdjustmentSalary = latestAdjustment?.new_salary;
    // console.log('Last Adjustment Salary', lastAdjustmentSalary);
    const isPayMonthly = latestAdjustment?.is_pay_monthy;
    const pamsSetting = information?.setting?.pams;

    let unitCost = 0;
    if (isPayMonthly) {
      unitCost =
        lastAdjustmentSalary /
        (pamsSetting?.period_date *
          pamsSetting?.daily_payroll?.working_hour *
          60);
    } else {
      unitCost =
        lastAdjustmentSalary / pamsSetting?.daily_payroll?.working_hour / 60;
    }
    return unitCost;
  } catch (error) {
    console.error('Cannot Calculate payroll unit cost', error);
    return 0;
  }
};

export default getEmployeePayrollUnitCostOnTimeBackend;
