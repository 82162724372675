import BomCostBox from './Bom/BomCostBox';
import MaterialBomCompareBox from './Bom/MaterialBomCompareBox';
import ProductBomBox from './Bom/ProductBomBox';
import NameBox from './Common/NameBox';
import SearchBox from './Common/SearchBox';
import SidemenuBox from './Common/SidemenuBox';
import CapitalCostDivideByWeight from './CostEstimation/CapitalCost/CapitalCostDivideByWeight';
import CapitalCostTDABC from './CostEstimation/CapitalCost/CapitalCostTDABC';
import CostEstimationOverallContainer from './CostEstimation/CostEstimationOverallContainer';
import CostFetchingOnTable from './CostEstimation/CostFetchingOnTable';
import CostProfileRendering from './CostEstimation/CostProfileRendering';
import LaborCostDivideByWeight from './CostEstimation/LaborCost/LaborCostDivideByWeight';
import LaborCostLumpsum from './CostEstimation/LaborCost/LaborCostLumpsum';
import LaborCostTDABC from './CostEstimation/LaborCost/LaborCostTDABC';
import MaterialAmountAndUnit from './CostEstimation/MaterialCost/MaterialAmountAndUnit';
import CustomerCommentBox from './CRM/CustomerCommentBox';
import CustomerRatingBox from './CRM/CustomerRatingBox';
import RatingTag from './CRM/RatingTag';
import ConveyorSketchGeneraror from './Machine/ConveyorSketchGeneraror';
import MachineCard from './Machine/MachineCard';
import MachineCostEquationBox from './Machine/MachineCostEquationBox';
import AllMaterialUsageCard from './Material/AllMaterialUsageCard';
import BoxMaterialFindFromLot from './Material/BoxMaterialFindFromLot';
import BoxPreferMaterial from './Material/BoxPreferMaterial';
import BoxRemainingMaterial from './Material/BoxRemainingMaterial';
import EmployeeCostEquationBox from './Material/EmployeeCostEquationBox';
import MaterialCostEquationBox from './Material/MaterialCostEquationBox';
import MaterialLotCostDriverBox from './Material/MaterialLotCostDriverBox';
import MaterialPlaceActionCard from './Material/MaterialPlaceActionCard';
import MaterialQuantityDisplay from './Material/MaterialQuantityDisplay';
import MaterialSourceBox from './Material/MaterialSourceBox';
import MaterialStockFilterBox from './Material/MaterialStockFilterBox';
import MaterialStockTab from './Material/MaterialStockTab';
import MaterialTransactionTab from './Material/MaterialTransactionTab';
import MaterialUsageBox from './Material/MaterialUsageBox';
import BoxAllMOMeasurementVariableBySteps from './MMS/BoxAllMOMeasurementVariableBySteps';
import BoxResultingFunction from './MMS/BoxResultingFunction';
import CommentBox from './MMS/CommentBox';
import InfoManufacturingOrder from './MMS/InfoManufacturingOrderBox';
import ManufacturingTransactionBox from './MMS/ManufacTransactionBox';
import ManufacturingOrderCalendar from './MMS/ManufacturingOrderCalendar';
import ManufacturingOrderCalendarByStep from './MMS/ManufacturingOrderCalendarByStep';
import MaterialMergingDisplay from './MMS/MaterialMergingDisplay';
import MeasurementVariableListBox from './MMS/MeasurementVariableListBox';
import OneMeasurementVariableBox from './MMS/OneMeasurementVariableBox';
import ProcessHandleProductOnlyControlBox from './MMS/ProcessHandleProductOnlyControlBox';
import ReportMaterialUsageTableRowElement from './MMS/ReportMaterialUsageTableRowElement';
import BoxDetailProcess from './Process/BoxDetailProcess';
import MaterialSplittingProcessActionContainerBox from './Process/MaterialSplittingProcessActionContainerBox';
import ProcessActionContainerBox from './Process/ProcessActionContainerBox';
import ProcessControlBox from './Process/ProcessControlBox';
import ProcessDetailBox from './Process/ProcessDetailBox';
import ProcessInfoBox from './Process/ProcessInfoBox';
import ProcessNoInfoBox from './Process/ProcessNoInfoBox';
import ProcessStartTimeBox from './Process/ProcessStartTimeBox';
import ProcessStatusBox from './Process/ProcessStatusBox';
import ProcessEmployeeCostDriverBox from './ProcessCostEstimation/ProcessEmployeeCostDriverBox';
import ProcessMaterialCostDriverBox from './ProcessCostEstimation/ProcessMaterialCostDriverBox';
import EmployeeTimeDrivenBox from './Product/EmployeeTimeDrivenBox';
import MachineTimeDrivenBox from './Product/MachineTimeDrivenBox';
import ProductAsMaterialResultBox from './Product/ProductAsMaterialResultBox';
import ProductResultBox from './Product/ProductResultBox';
import ProductStockFilterBox from './Product/ProductStockFilterBox';
import ProductStockLotSticker from './Product/ProductStockLotSticker';
import ProductStockTab from './Product/ProductStockTab';
import ProductTransactionTab from './Product/ProductTransactionTab';
import BoxQuotationByProductOrMaterial from './Quotation/BoxQuotationByProduct';
import BoxQuotationBySales from './Quotation/BoxQuotationBySales';
import BoxQuotationCostByProductOrMaterial from './Quotation/BoxQuotationCostByProductOrMaterial';
import BoxQuotationStatus from './Quotation/BoxQuotationStatus';
import MiniBoxQuotationStatus from './Quotation/MiniBoxQuotationStatus';
import QuotationPriceAndQuantityByStatus from './Quotation/QuotationPriceAndQuantityByStatus';
import DashboardElementRenderingComponent from './Rendering/DashboardElementRenderingComponent';
import MMSDashboardRenderingComponent from './Rendering/MMSDashboardRenderingComponent';
import MultiUnitProdOrMatBox from './Rendering/MultiUnitProdOrMatBox';
import OnePluginDisplayRenderingComponent from './Rendering/OnePluginDisplayRenderingComponent';
import PluginDisplayRenderingComponent from './Rendering/PluginDisplayRenderingComponent';
import PluginEditRenderingComponent from './Rendering/PluginEditRenderingComponent';
import StepBox from './Step/StepBox';
import StepControlBox from './Step/StepControlBox';
import StepEmployee from './Step/StepEmployee';
import StepInfoBox from './Step/StepInfoBox';
import StepMeasurementAndMaterialDisplayBox from './Step/StepMeasurementAndMaterialDisplayBox';
import MRPBox from './MRP';
/**
 * @namespace Component/Box
 * @description Box ต่างๆ รวมถึง Panel หรือ บางอันอาจจะคล้ายๆ Card บางอันมีฟังก์ชันระดับ Call API
 * ภายใน แต่ส่วนใหญ่จะไม่มี
 */
export {
  AllMaterialUsageCard,
  BomCostBox,
  BoxAllMOMeasurementVariableBySteps,
  BoxDetailProcess,
  BoxMaterialFindFromLot,
  BoxPreferMaterial,
  BoxQuotationByProductOrMaterial,
  BoxQuotationBySales,
  BoxQuotationCostByProductOrMaterial,
  BoxQuotationStatus,
  BoxRemainingMaterial,
  BoxResultingFunction,
  CapitalCostDivideByWeight,
  CapitalCostTDABC,
  CommentBox,
  ConveyorSketchGeneraror,
  CostEstimationOverallContainer,
  CostFetchingOnTable,
  CostProfileRendering,
  CustomerCommentBox,
  CustomerRatingBox,
  DashboardElementRenderingComponent,
  EmployeeCostEquationBox,
  EmployeeTimeDrivenBox,
  InfoManufacturingOrder,
  LaborCostDivideByWeight,
  LaborCostLumpsum,
  LaborCostTDABC,
  MachineCard,
  MachineCostEquationBox,
  MachineTimeDrivenBox,
  ManufacturingOrderCalendar,
  ManufacturingOrderCalendarByStep,
  ManufacturingTransactionBox,
  MaterialAmountAndUnit,
  MaterialBomCompareBox,
  MaterialCostEquationBox,
  MaterialLotCostDriverBox,
  MaterialMergingDisplay,
  MaterialPlaceActionCard,
  MaterialQuantityDisplay,
  MaterialSourceBox,
  MaterialSplittingProcessActionContainerBox,
  MaterialStockFilterBox,
  MaterialStockTab,
  MaterialTransactionTab,
  MaterialUsageBox,
  MeasurementVariableListBox,
  MiniBoxQuotationStatus,
  MMSDashboardRenderingComponent,
  MRPBox,
  MultiUnitProdOrMatBox,
  NameBox,
  OneMeasurementVariableBox,
  OnePluginDisplayRenderingComponent,
  PluginDisplayRenderingComponent,
  PluginEditRenderingComponent,
  ProcessActionContainerBox,
  ProcessControlBox,
  ProcessDetailBox,
  ProcessEmployeeCostDriverBox,
  ProcessHandleProductOnlyControlBox,
  ProcessInfoBox,
  ProcessMaterialCostDriverBox,
  ProcessNoInfoBox,
  ProcessStartTimeBox,
  ProcessStatusBox,
  ProductAsMaterialResultBox,
  ProductBomBox,
  ProductResultBox,
  ProductStockFilterBox,
  ProductStockLotSticker,
  ProductStockTab,
  ProductTransactionTab,
  QuotationPriceAndQuantityByStatus,
  RatingTag,
  ReportMaterialUsageTableRowElement,
  SearchBox,
  SidemenuBox,
  StepBox,
  StepControlBox,
  StepEmployee,
  StepInfoBox,
  StepMeasurementAndMaterialDisplayBox,
};

export default {
  BomCostBox,
  BoxAllMOMeasurementVariableBySteps,
  BoxDetailProcess,
  BoxPreferMaterial,
  BoxRemainingMaterial,
  BoxResultingFunction,
  CommentBox,
  InfoManufacturingOrder,
  ManufacturingTransactionBox,
  MaterialBomCompareBox,
  MaterialQuantityDisplay,
  MaterialSourceBox,
  MaterialUsageBox,
  MeasurementVariableListBox,
  MultiUnitProdOrMatBox,
  NameBox,
  OneMeasurementVariableBox,
  PluginDisplayRenderingComponent,
  PluginEditRenderingComponent,
  ProcessControlBox,
  ProcessDetailBox,
  ProcessInfoBox,
  ProcessNoInfoBox,
  ProductAsMaterialResultBox,
  ProductBomBox,
  ProductResultBox,
  SearchBox,
  StepBox,
  StepControlBox,
  StepEmployee,
  StepInfoBox,
  StepMeasurementAndMaterialDisplayBox,
  ProcessStartTimeBox,
  ProcessStatusBox,
  ConveyorSketchGeneraror,
  OnePluginDisplayRenderingComponent,
  DashboardElementRenderingComponent,
  MMSDashboardRenderingComponent,
  ReportMaterialUsageTableRowElement,
  ProcessHandleProductOnlyControlBox,
  SidemenuBox,
  ProcessActionContainerBox,
  MaterialSplittingProcessActionContainerBox,
  MaterialMergingDisplay,
  MaterialStockTab,
  MaterialTransactionTab,
  MRPBox,
  AllMaterialUsageCard,
  ProductStockLotSticker,
  MachineCard,
  BoxMaterialFindFromLot,
  ProductStockTab,
  ProductTransactionTab,
  MaterialPlaceActionCard,
  ManufacturingOrderCalendar,
  EmployeeTimeDrivenBox,
  ManufacturingOrderCalendarByStep,
  MaterialLotCostDriverBox,
  MaterialCostEquationBox,
  EmployeeCostEquationBox,
  ProcessMaterialCostDriverBox,
  ProcessEmployeeCostDriverBox,
  MachineTimeDrivenBox,
  MachineCostEquationBox,
  MaterialStockFilterBox,
  ProductStockFilterBox,
  BoxQuotationStatus,
  BoxQuotationByProductOrMaterial,
  BoxQuotationBySales,
  BoxQuotationCostByProductOrMaterial,
  MiniBoxQuotationStatus,
  CostProfileRendering,
  CapitalCostDivideByWeight,
  CapitalCostTDABC,
  LaborCostDivideByWeight,
  LaborCostLumpsum,
  LaborCostTDABC,
  MaterialAmountAndUnit,
  CustomerCommentBox,
  CustomerRatingBox,
  RatingTag,
  CostEstimationOverallContainer,
  CostFetchingOnTable,
  QuotationPriceAndQuantityByStatus,
};
