import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  BackButton,
  CardQuotation,
  Error,
  Loading,
  QuotationProductTable,
  QuotationReport,
  UpdateRatingDialog,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function DetailQuotation
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailQuotation({ title, subtitle, log = false }) {
  const dispatch = useDispatch();
  const quotation = log
    ? useSelector((state) => state.quotationLog)
    : useSelector((state) => state.quotation);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const params = useParams();
  const [ratingDialogOpen, setRatingDialogOpen] = useState(false);

  const queryDataFromServer = async () => {
    try {
      if (log) {
        dispatch(actions.quotationLogGet(params.id));
      } else {
        dispatch(actions.quotationGet(params.id));
      }
    } catch (error) {
      dispatch(actions.quotationError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleUpdateCustomerRating = async (customerId, newRating) => {
    try {
      await dispatch(
        actions.customerPut(customerId, {
          rating: newRating,
          employee: me?.userData?._id,
        }),
      );
      queryDataFromServer();
    } catch (error) {
      console.error('Edit Customer Fail', error);
      window.alert('ไม่สามารถแก้ไขคะแนนลูกค้าได้', error?.message);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePrintPdf = () => {
    try {
      QuotationReport(quotation, information, true);
    } catch (error) {
      QuotationReport(quotation, information);
    }
  };

  if (quotation.isLoading) {
    return <Loading />;
  }

  if (!quotation.isLoading && quotation.isCompleted) {
    return (
      <div>
        <UpdateRatingDialog
          customer={quotation?.customer}
          handleUpdate={(customerId, newRating) => {
            // setRatingDialogOpen(false);
            handleUpdateCustomerRating(customerId, newRating);
          }}
          open={ratingDialogOpen}
          handleCloseDialog={() => setRatingDialogOpen(false)}
        />
        <div className="flex items-center justify-between">
          {renderTitle()}
          <div className="flex items-center gap-1">
            <Button
              variant="contained"
              color={'teal'}
              onClick={() => {
                handlePrintPdf();
              }}
            >
              พิมพ์
            </Button>
            {!log && (
              <Link
                to={
                  quotation?.completed || quotation?.deleted
                    ? ''
                    : `/spm/quotation/edit/${quotation?._id}`
                }
              >
                <Button
                  variant="contained"
                  color={'warning'}
                  disabled={quotation?.completed || quotation?.deleted}
                >
                  แก้ไข
                </Button>
              </Link>
            )}
          </div>
        </div>
        <BackButton />
        <div className="my-2">
          <CardQuotation
            quotation={quotation}
            setRatingDialogOpen={setRatingDialogOpen}
          />
        </div>
        <div className="my-2">
          <QuotationProductTable quotation={quotation} />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailQuotation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  log: PropTypes.bool,
};

DetailQuotation.defaultProps = {
  title: '',
  subtitle: '',
};
