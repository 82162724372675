import AddToMaterialInventory from './AddToMaterialInventory';
import CreateRequestOfMaterial from './CreateRequestOfMaterial';
import EditMaterialUsageTransaction from './EditMaterialUsageTransaction';
import EditMeasurement from './EditMeasurement';
import EditProcess from './EditProcess';
import ExpandMaterialSplitting from './ExpandMaterialSplitting';
import HandleAddToWarehouse from './HandleAddToWarehouse';
import HandleNextStep from './HandleNextStep';
import MainDetailProcess from './MainDetailProcess';
import MeasurementPreEdit from './MeasurementPreEdit';
import ProcessAssignment from './ProcessAssignment';
import ProcessBom from './ProcessBom';
import ProcessCostDetail from './ProcessCostDetail';
import ProcessDelivery from './ProcessDelivery';
import ProcessBarcodePrint from './ProductBarcodePrint';
import ProductionLines from './ProductionLines';
import RecieveMaterial from './RecieveMaterial';
import RequestAddWarehouse from './RequestAddWarehouse';
import ReturnMaterial from './ReturnMaterial';
import SelectCurrentStep from './SelectCurrentStep';
import SelectEditMaterialUsage from './SelectEditMaterialUsage';
import SplitMoreProcess from './SplitMoreProcess';
import SplittingProcessClose from './SplittingProcessClose';
import SplittingProcessRecieveMaterial from './SplittingProcessRecieveMaterial';
import WithdrawMaterial from './WithdrawMaterial';

/**
 * @namespace MMS/Process
 * @description การดำเนิกการในไลน์ผลิตทั้งหมด ไมว่าจะเป็นการเบิกของ คืนอง เพิ่มลงในคลัง
 * อยู่ในโฟลเดอร์ views/MMS/Process
 */
export {
  AddToMaterialInventory,
  CreateRequestOfMaterial,
  EditMaterialUsageTransaction,
  EditMeasurement,
  EditProcess,
  ExpandMaterialSplitting,
  HandleAddToWarehouse,
  HandleNextStep,
  MainDetailProcess,
  MeasurementPreEdit,
  ProcessAssignment,
  ProcessBarcodePrint,
  ProcessBom,
  ProcessCostDetail,
  ProcessDelivery,
  ProductionLines,
  RecieveMaterial,
  RequestAddWarehouse,
  ReturnMaterial,
  SelectCurrentStep,
  SelectEditMaterialUsage,
  SplitMoreProcess,
  SplittingProcessClose,
  SplittingProcessRecieveMaterial,
  WithdrawMaterial,
};

export default {
  AddToMaterialInventory,
  EditMaterialUsageTransaction,
  EditMeasurement,
  EditProcess,
  HandleAddToWarehouse,
  HandleNextStep,
  MainDetailProcess,
  ProcessAssignment,
  ProcessBom,
  ProductionLines,
  RequestAddWarehouse,
  ReturnMaterial,
  SelectEditMaterialUsage,
  WithdrawMaterial,
  SelectCurrentStep,
  CreateRequestOfMaterial,
  RecieveMaterial,
  ProcessBarcodePrint,
  SplitMoreProcess,
  MeasurementPreEdit,
  SplittingProcessRecieveMaterial,
  SplittingProcessClose,
  ExpandMaterialSplitting,
  ProcessCostDetail,
  ProcessDelivery,
};
