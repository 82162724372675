import _ from 'lodash';
import findLotWithCostRealTDABC from '../../../cost-estimation/findLotWithCostRealTDABC';

const calTDABCReal = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
  information,
  PayrollAdjustment,
  ObjectId,
}) => {
  try {
    const place = warehouseProfile?.place;
    const allowedLot = await findLotWithCostRealTDABC({
      information,
      api,
      previousLotList,
      isFrontend,
      includeAnotherEmployee: true,
      selectedPlace: place,
      PayrollAdjustment,
      ObjectId,
    });
    const totalCost = _.sumBy(allowedLot, (each) => each?.cost);
    return { cost: totalCost, costList: allowedLot };
  } catch (error) {
    console.error('Error Cannot Get TDABC Lot', error?.message);
    return {
      cost: 0,
      costList: [],
    };
  }
};

export default calTDABCReal;
