import _ from 'lodash';

const generateMaterialActionLink = (place, lotList, nonRestrictPlace) => {
  let action = '';
  if (place?.place_action?.enable) {
    if (nonRestrictPlace) {
      if (
        place?.place_action?.for_stranger?.move &&
        place?.place_action?.for_stranger?.transform
      ) {
        // TODO: To be Implemented
        action = `/ims/fixed-transform?source=${place?._id}&lots=${_.map(
          _.uniq(lotList),
          (each) => each,
        )}&inplace=true&fixedoutput=true`;
      }
      // Only Move
      else if (place?.place_action?.for_stranger?.move) {
        action = `/ims/fixed-move?source=${place?._id}&lots=${_.map(
          _.uniq(lotList),
          (each) => each,
        )}&destination=${
          place?.place_action?.destination_place?._id
        }&forcemove=true`;
      }
      // Only Transform
      else if (place?.place_action?.for_stranger?.transform) {
        action = `/ims/fixed-transform?source=${place?._id}&lots=${_.map(
          _.uniq(lotList),
          (each) => each,
        )}&inplace=true&fixedoutput=true`;
      }
    } else if (place?.place_action?.move && place?.place_action?.transform) {
      action = `/ims/fixed-transform?source=${place?._id}&lots=${_.map(
        _.uniq(lotList),
        (each) => each,
      )}&destination=${
        place?.place_action?.destination_place?._id
      }&forcemove=true&fixedoutput=true`;
    } else if (place?.place_action?.move) {
      action = `/ims/fixed-move?source=${place?._id}&lots=${_.map(
        _.uniq(lotList),
        (each) => each,
      )}&destination=${
        place?.place_action?.destination_place?._id
      }&forcemove=true&move-after-action=true`;
    } else if (place?.place_action?.add_to_product_warehouse) {
      action = `/ims/fixed-move-transform-warehouse?source=${
        place?._id
      }&lots=${_.map(_.uniq(lotList), (each) => each)}&destination=${
        place?.place_action?.destination_warehouse
      }&forcemove=true`;
    }
  } else {
    action = null;
  }
  return action;
};

export default generateMaterialActionLink;
