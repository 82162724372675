/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  calculateCostOnProfile,
  currencyFormatter,
} from '@iarcpsu/emanufac-utils/functions';
import { Skeleton } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';

const CostFetchingOnTable = ({ currentLot, costEstimationProfiles }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [overallResults, setOverallResults] = useState([]);
  const [previousLotList, setPreviousLotList] = useState([]);
  const information = useSelector((state) => state.information);

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-stock-lot/with-previous-lot/${currentLot?._id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const tempOrderedLot = _.orderBy(uniqueLots, 'createdAt');
      setPreviousLotList(tempOrderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    }
  };

  const getCostOnProfile = async () => {
    try {
      setIsLoading(true);
      const orderProfile = _.orderBy(
        costEstimationProfiles,
        ['createdAt'],
        ['asc'],
      );
      const tempTotalResult = [];
      for await (const profile of orderProfile) {
        const tempResult = await calculateCostOnProfile({
          api,
          currentLot,
          information,
          isFrontend: true,
          previousLotList,
          profile,
        });
        const tempPayload = {
          name: profile?.name,
          ...tempResult,
        };

        tempTotalResult.push(tempPayload);
      }
      setOverallResults(tempTotalResult);
      setIsLoading(false);
      console.log('Total Result', tempTotalResult);
    } catch (error) {
      console.error('Get Cost On Profile error', error.message);
    }
  };

  useEffect(() => {
    if (currentLot?._id) {
      getPreviousLots();
    }

    return () => {};
  }, [currentLot]);

  useEffect(() => {
    if (!_.isEmpty(costEstimationProfiles) && !_.isEmpty(previousLotList)) {
      getCostOnProfile();
    }

    return () => {};
  }, [previousLotList]);

  return (
    <div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          {_.map(overallResults, (each, index) => (
            <div key={index} className="my-1">
              {_.size(overallResults) > 1 && (
                <div className="text-xs">{each?.name}</div>
              )}
              {currencyFormatter.format(each?.cost)} บาท
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CostFetchingOnTable;

CostFetchingOnTable.propTypes = {
  currentLot: PropTypes.object,
  costEstimationProfiles: PropTypes.arrayOf(PropTypes.object),
};
