import React, { useContext } from 'react';
import { ArrowDown, ArrowUp, CornerUpRight, RefreshCw } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { MATERIAL_GROUP_BY_PAGE_TYPE_MISPLACE } from '@iarcpsu/emanufac-constant';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

const MaterialStockPageActionButtons = ({
  place,
  allowNavigation = true,
  information,
  setIsUseRedis = () => {},
}) => {
  const { findWordInPlaceByCustomPlace } = useContext(WordLocalizeContext);
  let history;

  if (allowNavigation) {
    history = useHistory();
  } else {
    history = () => {};
  }

  const misplaceTableLink = () => {
    if (
      place?.place_action?.page_for_material_by_group_misplace ===
      MATERIAL_GROUP_BY_PAGE_TYPE_MISPLACE.CLICKABLE_TABLE.status_code
    ) {
      return `/ims/material-stock/group-clickable?by=material-source&place=${
        place?._id || ''
      }&mistakeplace=true`;
    }
    return `/ims/material-stock/group?by=material-source&place=${
      place?._id || ''
    }&mistakeplace=true`;
  };

  return (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button
        variant="contained"
        startIcon={<ArrowDown size={16} />}
        onClick={() =>
          history.push(`/ims/material-stock/create/${place?._id || ''}`)
        }
      >
        เพิ่มวัตถุดิบ
      </Button>
      <Button
        variant="contained"
        startIcon={<ArrowUp size={16} />}
        color="error"
        onClick={() =>
          history.push(`/ims/material-stock/withdraw/${place?._id || ''}`)
        }
      >
        เบิกวัตถุดิบ
      </Button>
      {place?.place_action?.add_to_product_warehouse && (
        <Button
          variant="contained"
          color="success"
          startIcon={<i className="fas fa-layer-group" />}
          onClick={() => {
            history.push(
              `/ims/material-stock-to-product?place=${place?._id || ''}`,
            );
          }}
        >
          แปลงเป็นสินค้า
        </Button>
      )}{' '}
      {place?.place_action?.display_material_lot_group_by_button && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<i className="fas fa-layer-group" />}
          onClick={() => {
            history.push(
              `/ims/material-stock/group?by=material-source&place=${
                place?._id || ''
              }`,
            );
          }}
        >
          {findWordInPlaceByCustomPlace('group_by_source', place) ||
            'แสดงเป็นกลุ่มตามที่มา'}
        </Button>
      )}{' '}
      {place?.place_action?.display_material_lot_group_misplace && (
        <Button
          variant="contained"
          color="teal"
          startIcon={<i className="fas fa-store-slash" />}
          onClick={() => {
            history.push(misplaceTableLink());
          }}
        >
          {findWordInPlaceByCustomPlace('stranger_material', place) ||
            'วัตถุดิบไม่ตรงคลัง'}
        </Button>
      )}
      <Button
        variant="contained"
        color="warning"
        startIcon={<CornerUpRight size={16} />}
        onClick={() => {
          history.push(`/ims/move/${place?._id}`);
        }}
      >
        ย้ายวัตถุดิบ
      </Button>{' '}
      {information?.setting?.ims?.allowMaterialTransform && (
        <Button
          variant="contained"
          color="info"
          startIcon={<i className="fas fa-magic"></i>}
          onClick={() => {
            history.push(`/ims/transform/${place?._id}`);
          }}
        >
          แปลงวัตถุดิบ
        </Button>
      )}{' '}
      <Button
        variant="contained"
        color="teal"
        startIcon={<RefreshCw size={16} />}
        onClick={() => {
          setIsUseRedis(false);
        }}
      >
        รีเฟรช
      </Button>{' '}
    </div>
  );
};

export default MaterialStockPageActionButtons;

MaterialStockPageActionButtons.propTypes = {
  place: PropTypes.object,
  allowNavigation: PropTypes.bool,
  information: PropTypes.object,
  setIsUseRedis: PropTypes.func,
};
