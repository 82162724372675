import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

import 'dayjs/locale/th';

dayjs.extend(BuddhistCalendar);

const SettingInventoryOperation = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  const { control, handleSubmit } = useForm({
    defaultValues: { setting: information?.setting },
  });

  useEffect(() => {
    dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              ims: {
                ...data.setting.ims,
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        <div className="w-full py-2 px-2 font-semibold font-display ">
          กระบวนการทำงาน
        </div>
        <div className="w-1/2 py-2 px-2  ">การตัดวัตถุดิบออกจากสต็อก</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.usingFIFO"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">
                    เลือกตามล็อตวัตถุดิบ
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={information?.setting?.ims?.usingFIFO}
                      />
                    }
                    label="เลือกโดยใช้ First In First Out"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">เพิ่มให้ใส่เป้าหมายของการเบิก</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.allowWithdrawDestination"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={information?.setting?.ims?.usingFIFO}
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2  ">
          อนุญาตให้การผลิตเบิกได้จากคลัง WIP เท่านั้น
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.allowManufacturingUseWIPOnly"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims
                            ?.allowManufacturingUseWIPOnly
                        }
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">อนุญาตให้มีการแปลงวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.allowMaterialTransform"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims?.allowMaterialTransform
                        }
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2  ">
          เปิดใช้การบังคับให้แต่ละคลังจุวัตถุดิบแต่ละประเภทได้เท่านั้น
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.useInventoryCategoryRestriction"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims
                            ?.useInventoryCategoryRestriction
                        }
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2  ">
          ค่าเริ่มต้นเมื่อมีการกรอกต้นทุนวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.default_cost_per_unit"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">
                    ต้นทุนรวมทั้งล็อต
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims?.default_cost_per_unit
                        }
                      />
                    }
                    label="ต้นทุนต่อหน่วย"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2  ">
          ให้มีการใส่สัดส่วน หรือ ปริมาณของวัถตถุดิบที่เป็นส่วนผสม
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.wip_show_fraction_add"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims?.wip_show_fraction_add
                        }
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full p-2 font-semibold">
          กระบวนการต่างๆ ในคลังและการดำเนินการกับวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">เปิดใช้กระบวนการต่างๆเพิ่มเติม</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.ims.operationDisplay.displayByMaterialSource"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="จัดกลุ่มตามที่มาของวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.operationDisplay.displayPurchaseDateInputBox"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงช่องให้ใส่วันที่ซื้อสินค้า (Purchase Date)"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.operationDisplay.displaySupplierInput"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงช่องให้ใส่คู่ค้า"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.operationDisplay.automaticSupplierFindFromSource"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ให้หา Supplier ให้เองจากแหล่งวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.peerMaterialWithProductType"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ให้เพิ่มจับคู่ระหว่างวัตถุดิบและสินค้าได้โดยใช้ Product Type"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.showTime"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ให้กรอกและแสดงเวลา"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.showGroupEmployeeInputInSpreadWay"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงช่องให้กรอกกลุ่มพนักงาน และ ปริมาณคน (ในรูปแบบลิสต์ให้กรอกจำนวนคน)"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.operationDisplay.showGroupEmployeeInputWhenInMove"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ให้แสดงช่องให้กรอกจำนวนกลุ่มของพนักงาน ในหน้าย้ายวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.operationDisplay.showGroupEmployeeInputWhenInTransform"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ให้แสดงช่องให้กรอกจำนวนกลุ่มของพนักงาน ในหน้าแปลงวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.operationDisplay.showDurationInputWhenInMove"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงช่องให้กรอกเวลา ในหน้าแปลงวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.operationDisplay.showDurationInputWhenInTransform"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงช่องให้กรอกเวลา ในหน้าแปลงวัตถุดิบ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.ims.display.workflow_type_and_duration"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงรูปแบบของกิจกรรม และระยะเวลา"
                />
              )}
            />{' '}
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingInventoryOperation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingInventoryOperation.defaultProps = {
  title: '',
  subtitle: '',
};
export default SettingInventoryOperation;
