import _ from 'lodash';

import { recieveAllMaterials } from '../checkCanStartProcess';

export const processStatusCheck = (
  currentProcess,
  currentStep,
  information,
  me,
) => {
  // Process Status
  const isNoCurrentStep = currentProcess?.current?.index === -1;
  const isProcessCancle = currentProcess?.deleted;
  const duringMinorProcess =
    currentProcess?.is_during_minor_process &&
    !currentProcess?.is_minor_process;

  // เราจะทำเหมือนว่า Admin เป็นพนักงานในทุกแผนกโดยทันที
  const isAdmin = me?.userData?.role?.level >= 1;

  // กรณี Step สุดท้าย ทำเพื่อ Recheck กันพลาด
  const isLaststep =
    _.find(
      currentProcess?.steps,
      (_step) => _step?.index === _.size(currentProcess?.steps) - 1,
    ) && currentProcess?.current?.index === _.size(currentProcess?.steps) - 1;

  const currentMaterialRequest = currentProcess?.material_withdraws;

  const isProcessComplete = currentProcess?.completed === true;
  const isRequireVerifyMaterialRequest =
    information?.setting?.mms?.verifiedMaterialRequest;

  // CurrentStepStatus
  const isNoStep = _.isEmpty(currentProcess?.product?.steps);
  const isCurrentStepComplete = currentStep?.completed === true;

  const isCanGoNext = isRequireVerifyMaterialRequest
    ? recieveAllMaterials(currentMaterialRequest)
    : true;

  const isNeedMaterial =
    !_.isEmpty(currentProcess?.materials) && !currentProcess?.isRequestMaterial;

  const allowLastStepHandleProduct =
    information?.setting?.mms?.allowLastStepHandleProduct;

  const isDepartmentStep = () => {
    if (
      currentStep?.responsible?.department === me?.userData?.department?._id
    ) {
      return true;
    }
    const otherDepartmentArrays = _.map(
      me?.userData?.other_departments,
      (_dept) => _dept?._id,
    );
    if (
      _.includes(otherDepartmentArrays, currentStep?.responsible?.department)
    ) {
      return true;
    }
    return false;
  };

  const requiredDelivery =
    information?.setting?.mms?.process?.addDeliverProcessBeforeEnd;
  const isMyWork =
    currentStep?.responsible?.employee?._id === me?.userData?._id;

  return {
    currentMaterialRequest: currentProcess?.material_withdraws,
    processStatus: {
      isStart: currentProcess?.current?.step !== -1,
      isRequireMaterial: !_.isEmpty(currentProcess?.materials),
      isRequestMaterial: currentProcess?.isRequestMaterial,
      isRequireVerifyRequest: information?.setting?.mms?.verifiedMaterialRequest
        ? !currentProcess?.isRequestMaterial
        : false,
      isNoCurrentStep: currentProcess?.current?.index === -1,
      isNotStart:
        isNoCurrentStep &&
        isRequireVerifyMaterialRequest &&
        !recieveAllMaterials(currentMaterialRequest),
      isLastStepCompleteButProcessNotComplete:
        isLaststep && isCurrentStepComplete && !isProcessComplete,
      isComplete: isProcessComplete,
      isReceiveAllMaterial: isCanGoNext,
      isGreatestComplete:
        isLaststep && isCurrentStepComplete && isProcessComplete,
      isReqMaterialInProcess:
        !_.isEmpty(currentProcess?.materials) &&
        !currentProcess?.isRequestMaterial,
    },
    step: {
      isNoStep,
      isLaststep,
      isCurrentStepComplete,
    },
    allow: {
      allowRequestMaterial:
        !isProcessCancle &&
        !isProcessComplete &&
        isCanGoNext &&
        !(isLaststep && isCurrentStepComplete) &&
        !(
          currentProcess?.current?.index === -1 &&
          currentProcess?.isRequestMaterial
        ) &&
        (isAdmin || isDepartmentStep() || isMyWork),
      allowReturnMaterial:
        !isProcessCancle &&
        !isProcessComplete &&
        isCanGoNext &&
        !(isLaststep && isCurrentStepComplete) &&
        !(
          currentProcess?.current?.index === -1 &&
          currentProcess?.isRequestMaterial
        ) &&
        currentProcess?.isRequestMaterial &&
        (!isRequireVerifyMaterialRequest ||
          (isRequireVerifyMaterialRequest &&
            recieveAllMaterials(currentMaterialRequest))) &&
        (isAdmin || isDepartmentStep() || isMyWork),
      allowSkipStep:
        !isProcessCancle &&
        !isNoCurrentStep &&
        isCanGoNext &&
        !(isLaststep && isCurrentStepComplete) &&
        !(isLaststep && allowLastStepHandleProduct) &&
        !duringMinorProcess &&
        (isAdmin || isDepartmentStep() || isMyWork),
      allowHandleStart:
        !isProcessCancle &&
        isNoCurrentStep &&
        (!isRequireVerifyMaterialRequest ||
          (isRequireVerifyMaterialRequest &&
            recieveAllMaterials(currentMaterialRequest))),
      allowStopProcess:
        !isProcessCancle &&
        // เป็น Step สุดท้ายแล้ว comlete หรือเป็นงานสุดท้ายแต่มี flag allowLastStepHandleProduct
        // แต่ก็ต้องไม่ require Delivery ด้วย
        ((isLaststep && isCurrentStepComplete) ||
          (!requiredDelivery && allowLastStepHandleProduct && isLaststep)) &&
        !isProcessComplete &&
        (isAdmin ||
          (allowLastStepHandleProduct && isDepartmentStep()) ||
          isMyWork),
      allowHandleLastStep:
        !isProcessCancle &&
        allowLastStepHandleProduct &&
        isLaststep &&
        !isCurrentStepComplete &&
        !isProcessComplete &&
        (isAdmin ||
          (allowLastStepHandleProduct && isDepartmentStep()) ||
          isMyWork),
      allowDelivery:
        !isProcessCancle &&
        isLaststep &&
        isCurrentStepComplete &&
        requiredDelivery &&
        !isProcessComplete &&
        (isAdmin ||
          (allowLastStepHandleProduct && isDepartmentStep()) ||
          isMyWork) &&
        currentProcess?.delivery?.delivered === false,
    },
  };
};

export default processStatusCheck;
