import React, { useEffect, useState } from 'react';
import { EyeOff } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CostEstimationProfiles
 * @description Display a collections or a list of CostEstimationProfiles from database
 */

export default function CostEstimationProfiles({ title, subtitle }) {
  const dispatch = useDispatch();
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(null);

  const query = gql`
    query FindCostEstimationProfiles(
      $costEstimationProfileinput: CostEstimationProfileInput
    ) {
      findCostEstimationProfiles(input: $costEstimationProfileinput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          manufacturing_mode
          hidden
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        costEstimationProfileinput: { page, size },
      });
      const costEstimationProfileData = queryResult?.findCostEstimationProfiles;
      dispatch(
        actions.costEstimationProfileStateSet(costEstimationProfileData),
      );
    } catch (error) {
      dispatch(actions.costEstimationProfileError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (costEstimationProfile) {
      setTotal(costEstimationProfile.total);
    }

    return () => {};
  }, [costEstimationProfile]);

  const handleDeleteProfile = async (id) => {
    try {
      Swal.fire({
        icon: 'warning',
        title: 'คุณแน่ใจที่จะลบโปรไฟล์นี้?',
        showCancelButton: true,
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.costEstimationProfileDelete(id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (costEstimationProfile.isLoading) {
    return <Loading />;
  }
  if (!costEstimationProfile.isLoading && costEstimationProfile.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <Link to="/cem/cost-estimation-profile/create">
            <Button variant="contained" color="primary">
              เพิ่ม
            </Button>
          </Link>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อโปรไฟล์</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">รูปแบบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(costEstimationProfile?.rows) ? (
                    _.map(costEstimationProfile?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>
                        <TableCell>
                          <div className="flex gap-1 items-center">
                            {row?.name}
                            {row?.hidden && <EyeOff size={16} />}
                          </div>
                        </TableCell>
                        <TableCell>{row?.manufacturing_mode}</TableCell>
                        <TableCell>
                          <div className="flex gap-1">
                            <Link
                              to={`/cem/cost-estimation-profile/detail/${row._id}`}
                            >
                              <Button
                                variant="contained"
                                color="info"
                                size="small"
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                            <Link
                              to={`/cem/cost-estimation-profile/edit/${row._id}`}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                size="small"
                              >
                                แก้ไข
                              </Button>
                            </Link>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                handleDeleteProfile(row._id);
                              }}
                              size="small"
                            >
                              ลบ
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(e, newPage) => setPage(newPage + 1)}
              onRowsPerPageChange={(e) => setSize(e.target.value)}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

CostEstimationProfiles.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CostEstimationProfiles.defaultProps = {
  title: '',
  subtitle: '',
};
