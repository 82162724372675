/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ArrowDown,
  ArrowUp,
  Check as CheckIcon,
  Info as InfoIcon,
  PauseCircle as PauseCircleIcon,
  X as XIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';
import { timeDurationWord } from '@/utils/functions/durationTimeDisplay';
import { processStatusCheck } from '@/utils/functions/MMS/processStatusCheck';

import 'dayjs/locale/th';

import RatingTag from '../../Box/CRM/RatingTag';
import { ManufacturingOrderReport } from '../../Report';

dayjs.locale('th');
dayjs.extend(buddhistEra);

/**
 * @function ManufacturingOrderTable
 * @memberof MMS
 * @desctiption ตารางรายการคำสั่งผลิต สำหรับใช้ในหน้าหลักของคำสั่งผลิต และหน้าประวัติคำสั่งผลิตด้วย
 * @return {React.Component} React Component
 */
export default function ManufacturingOrderTable({
  manufacturingOrder,
  page,
  size,
  total,
  handleDelete,
  handleChangeRowsPerPage,
  handleChangePage,
  displayCancleButton = true,
  displayStatusBadge = true,
  statusCenter = true,
  showInsideStatus = true,
  orderByField,
  orderBy,
  setOrderByField,
  setOrderBy,
  showSales = false,
  showTotalPrice = false,
}) {
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const isOnHistoryPage = !displayCancleButton;

  const {
    setting: { mms: mmsSetting },
  } = information;

  const isAdminPosition = me?.userData?.role?.level >= 1;
  const renderProcessData = (process) => {
    if (!displayStatusBadge) {
      return <div></div>;
    }

    // เสร็จแล้ว
    if (process?.completed) {
      return (
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max">
          <CheckIcon color="green" size={18} />
        </div>
      );
    }

    // ยกเลิก
    if (process?.deleted) {
      return (
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max">
          <XIcon color="red" size={18} />
        </div>
      );
    }

    // ยังไม่เริ่ม
    if (
      process?.current?.index === null ||
      process?.current?.index === undefined ||
      process?.current?.index === -1
    ) {
      return (
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max">
          <InfoIcon color="gray" size={18} />
        </div>
      );
    }

    return <div></div>;
  };

  const renderTimeStatus = (expectedDate, calculatedDate) => {
    const timeDuration = timeDurationWord(expectedDate, calculatedDate);
    return (
      <div className={`${timeDuration.cssClass}`}>{timeDuration.word}</div>
    );
  };

  const renderDeleteBadge = (isDeleted, deletedReason) => {
    if (isDeleted) {
      return (
        <div>
          <div className=" text-red-500 font-bold rounded-md bg-gray-100 px-2 py-1 text-center   mt-1">
            ยกเลิก
          </div>
          <div className=" text-red-500">สาเหตุการยกเลิก: {deletedReason} </div>
        </div>
      );
    }
    return <div></div>;
  };

  const statusColorClass = (status) => {
    switch (status) {
      case MANUFAC_ORDER_STATUS.IN_PROGRESS.status_code:
        return 'bg-blue-300';
      case MANUFAC_ORDER_STATUS.LATED.status_code:
        return 'bg-yellow-300';
      case MANUFAC_ORDER_STATUS.SUCCESS.status_code:
        return 'bg-green-300';
      case MANUFAC_ORDER_STATUS.CANCEL.status_code:
        return 'bg-red-300';
      default:
        return '';
    }
  };

  const renderStepAndStatus = (process) => {
    const processStatus = processStatusCheck(process, process?.current?.step);

    if (process?.current?.index === -1) {
      // ในกรณีที่ยังไม่เริ่มการผลิต
      return (
        <div className="flex">
          <div className="text-gray-500 bg-gray-50 rounded-md p-1 px-2 ">
            {'ยังไม่เริ่มการผลิต'}
          </div>
        </div>
      );
    }
    if (process?.deleted === true) {
      // ในกรณีที่ยกเลิก
      return (
        <div className="flex">
          <div className="text-red-500 bg-gray-50 rounded-md p-1 px-2 ">
            ยกเลิกไลน์การผลิต
            {process?.delete_note ? (
              <p>สาเหตุการยกเลิก : {process?.delete_note}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    }

    // if (processStatus.processStatus.isGreatestComplete) {
    //   // ในกรณีที่ถึง step สุดท้ายแล้ว สำเร็จ ให้ขึ้นสถานะสำเร็จของการผลิต
    //   return (
    //     <div className="flex">
    //       <div className="text-green-500 bg-gray-50 rounded-md p-1 px-2 ">
    //         {'การผลิตเสร็จสิ้น'}
    //       </div>
    //     </div>
    //   );
    // }
    // return (
    //   <div className="flex">
    //     <div className="text-yellow-500   bg-gray-50 rounded-md p-1 px-2 ">
    //       {`ขั้นตอนปัจจุบัน: ${process?.current?.step?.name}`}
    //     </div>
    //   </div>
    // );

    return (
      <div>{process?.current_step_name || process?.current?.step?.name}</div>
    );
  };

  const handlePrint = (selectedProcess) => {
    try {
      ManufacturingOrderReport(selectedProcess, information);
    } catch (error) {
      ManufacturingOrderReport(selectedProcess, information, true);
    }
  };

  const findTotalPrice = (selectedMO) => {
    const totalPriceList = _.map(selectedMO?.process, (eachProcess) => {
      if (eachProcess?.deleted) return 0;
      return eachProcess?.quantity * eachProcess?.price_per_unit || 0;
    });

    const totalPrice = _.sum(totalPriceList) - (selectedMO?.discount || 0);

    return totalPrice;
  };

  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="12%" />
              <col width="10%" />
              <col width="10%" />
              {mmsSetting?.usingAsDefault?.showExpectedDate && (
                <col width="10%" />
              )}
              <col width="10%" />
              <col width="15%" />
              {showInsideStatus && <col width="10%" />}
              {showSales && <col width="10%" />}
              {showTotalPrice && <col width="10%" />}
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('running_number');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex">
                    คำสั่งผลิต{' '}
                    {orderByField === 'running_number' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>{' '}
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('status');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex">
                    สถานะ{' '}
                    {orderByField === 'status' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('start_date');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex">
                    วันที่สั่ง{' '}
                    {orderByField === 'start_date' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                {mmsSetting?.usingAsDefault?.showExpectedDate && (
                  <TableCell
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      setOrderByField('expected_date');
                      if (orderBy === 'asc') {
                        setOrderBy('desc');
                      } else {
                        setOrderBy('asc');
                      }
                    }}
                  >
                    <div className="font-bold flex">
                      วันที่ต้องการ
                      {orderByField === 'expected_date' &&
                      orderBy === 'desc' ? (
                        <ArrowDown size="16" className="self-center" />
                      ) : (
                        <ArrowUp size="16" className="self-center" />
                      )}
                    </div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                {showInsideStatus && (
                  <TableCell>
                    <div className="font-bold">งานปัจจุบัน</div>
                  </TableCell>
                )}{' '}
                {showSales && (
                  <TableCell>
                    <div className="font-bold">พนักงานขาย</div>
                  </TableCell>
                )}{' '}
                {showTotalPrice && (
                  <TableCell>
                    <div className="font-bold">มูลค่า (บาท)</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">ผู้สั่งผลิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(manufacturingOrder?.rows) ? (
                manufacturingOrder?.rows.map((row, index) => {
                  const calculatedDate = row?.completed
                    ? dayjs(row.updatedAt)
                    : dayjs();
                  return (
                    <TableRow
                      key={row?._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {(page - 1) * size + index + 1}
                      </TableCell>
                      <TableCell>
                        <div className="flex">
                          <b>
                            {row?.prefix}
                            <div className="text-nowrap">
                              {row?.running_number || '-'}{' '}
                            </div>
                            {row?.is_claimed && (
                              <span className="px-2 bg-gray-200 font-normal rounded-md mt-2 text-sm">
                                งานเคลม
                              </span>
                            )}
                          </b>{' '}
                          {row?.completed && (
                            <div className="mx-2">
                              {' '}
                              <CheckIcon
                                className="self-center text-green-500"
                                size="16"
                              />{' '}
                            </div>
                          )}{' '}
                        </div>
                        {renderDeleteBadge(row?.deleted, row?.delete_note)}
                      </TableCell>
                      <TableCell>
                        <div
                          className={`${statusColorClass(
                            row?.status,
                          )} rounded-md text-center p-2`}
                        >
                          {MANUFAC_ORDER_STATUS[row?.status]?.description}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {mmsSetting?.timing?.showTime
                            ? dayjs(row?.start_date).format(
                                'D MMM BBBB HH:mm น.',
                              )
                            : dayjs(row?.start_date).format('D MMM BBBB ')}
                        </div>
                      </TableCell>
                      {mmsSetting?.usingAsDefault?.showExpectedDate && (
                        <TableCell>
                          {row?.expected_date ? (
                            <div className={` ${statusCenter}`}>
                              {mmsSetting?.timing?.showTime
                                ? dayjs(row?.expected_date).format(
                                    'D MMM BBBB HH:mm น.',
                                  )
                                : dayjs(row?.expected_date).format(
                                    'D MMM BBBB ',
                                  )}
                            </div>
                          ) : (
                            '-'
                          )}

                          <div className={`${statusCenter}`}>
                            {!isOnHistoryPage &&
                              renderTimeStatus(
                                row?.expected_date,
                                calculatedDate,
                              )}
                          </div>
                        </TableCell>
                      )}
                      <TableCell>
                        <div className="whitespace-normal">
                          {row?.order?.customer?.name ||
                            row?.customer?.name ||
                            '-'}
                          <RatingTag customer={row?.customer} />
                        </div>
                      </TableCell>
                      <TableCell>
                        {!row?.process && <Skeleton variant="text" />}
                        <ul className="list-decimal">
                          {_.map(
                            _.take(row?.process, 4),
                            (_process, procIndex) => (
                              <li key={procIndex}>
                                <div className="flex gap-1 ">
                                  <div className="self-center">
                                    <span className=" font-semibold font-display">
                                      {_process?.produce_material_instead
                                        ? _process?.product_as_material
                                            ?.type_code
                                        : _process?.product?.type_code}
                                    </span>{' '}
                                    <span>
                                      {_.truncate(
                                        _process?.produce_material_instead
                                          ? _process?.product_as_material?.name
                                          : _process?.product?.name,
                                      )}
                                    </span>{' '}
                                    <span className=" font-semibold font-display">
                                      {_.truncate(
                                        (_process?.produce_base_project_instead &&
                                          _process?.base_project?.type_code) ||
                                          '',
                                      )}
                                    </span>{' '}
                                    <span>
                                      {_.truncate(
                                        (_process?.produce_base_project_instead &&
                                          _process?.base_project?.name) ||
                                          '',
                                      )}
                                    </span>{' '}
                                    <span>
                                      {_process?.quantity > 0
                                        ? currencyFormatter.format(
                                            _process?.quantity,
                                          )
                                        : ''}{' '}
                                    </span>
                                    <span>
                                      {_process?.quantity > 0
                                        ? _process?.additional_unit ||
                                          _process?.product?.unit
                                        : ''}
                                    </span>
                                    <div>
                                      {_process?.customer?.type_code || ''}{' '}
                                      {_process?.customer?.name || ''}
                                      {!_.isEmpty(
                                        _process?.other_customers,
                                      ) && (
                                        <span>
                                          {' '}
                                          + {_.size(
                                            _process?.other_customers,
                                          )}{' '}
                                          ลูกค้า
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {showInsideStatus && (
                                    <div className="self-center">
                                      {renderProcessData(_process)}
                                    </div>
                                  )}
                                </div>
                              </li>
                            ),
                          )}
                          {_.size(row?.process) > 4 && (
                            <div>และอีก {_.size(row?.process) - 4} รายการ</div>
                          )}
                        </ul>
                      </TableCell>
                      {showInsideStatus && (
                        <TableCell>
                          {' '}
                          {!row?.process && <Skeleton variant="text" />}
                          {_.map(row?.process, (process) => (
                            <div className="mb-1">
                              {renderStepAndStatus(process)}
                            </div>
                          ))}
                        </TableCell>
                      )}{' '}
                      {showSales && (
                        <TableCell>
                          QT{row?.quotation?.running_number}
                          <br />
                          {row?.quotation?.sales?.firstname}{' '}
                          {row?.quotation?.sales?.lastname}
                        </TableCell>
                      )}
                      {showTotalPrice && (
                        <TableCell>
                          {currencyFormatter.format(findTotalPrice(row))}
                        </TableCell>
                      )}
                      <TableCell>
                        <div>
                          {row?.assigner?.firstname} {row?.assigner?.lastname}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-col flex-wrap gap-1">
                          {information?.setting?.mms?.dashboard
                            ?.showDashboardA && (
                            <Link
                              to={`/mms/manufacturing-orders/view/${row?.id}`}
                            >
                              <Button
                                variant="contained"
                                color="info"
                                size={'small'}
                                className="w-24"
                              >
                                รายละเอียด{' '}
                              </Button>
                            </Link>
                          )}{' '}
                          {information?.setting?.mms?.dashboard
                            ?.showDashboardB && (
                            <Link
                              to={`/mms/manufacturing-orders-b/view/${row?.id}`}
                            >
                              <Button
                                variant="contained"
                                color="info"
                                size={'small'}
                                className="w-24"
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                          )}
                          {information?.setting?.mms?.dashboard
                            ?.showDashboardC && (
                            <Link
                              to={`/mms/manufacturing-orders-c/view/${row?.id}`}
                            >
                              <Button
                                variant="contained"
                                color="info"
                                size={'small'}
                                className="w-24"
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                          )}
                          {information?.setting?.mms?.dashboard
                            ?.showBomDashboard && (
                            <Link
                              to={`/mms/manufacturing-orders/bom-checking/${row?.id}`}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                size={'small'}
                                className="w-24"
                              >
                                วัตถุดิบส่วนผสม (BOM)
                              </Button>
                            </Link>
                          )}{' '}
                          {_.map(
                            information?.setting?.mms?.customDashboard,
                            (eachCustomDashboard, ecdIndex) => (
                              <Link
                                key={`dashboard-${ecdIndex}`}
                                to={`/mms/manufacturing-orders/custom/${eachCustomDashboard?._id}/${row?.id}`}
                              >
                                <Button
                                  color="info"
                                  variant="contained"
                                  size="small"
                                  className="w-24"
                                >
                                  {eachCustomDashboard?.name}
                                </Button>
                              </Link>
                            ),
                          )}
                          {displayCancleButton && (
                            <Button
                              variant="contained"
                              color={'error'}
                              size={'small'}
                              className="w-24"
                              onClick={() => handleDelete(row.id)}
                              disabled={!isAdminPosition}
                            >
                              ยกเลิก
                            </Button>
                          )}{' '}
                          <Button
                            variant="contained"
                            color={'teal'}
                            size={'small'}
                            className="w-24"
                            onClick={() => handlePrint(row)}
                          >
                            พิมพ์
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          showFirstButton
          showLastButton
        />
      </Paper>
    </div>
  );
}

ManufacturingOrderTable.propTypes = {
  manufacturingOrder: PropTypes.object,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleDelete: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  displayCancleButton: PropTypes.bool,
  displayStatusBadge: PropTypes.bool,
  statusCenter: PropTypes.bool,
  showInsideStatus: PropTypes.bool,
  orderByField: PropTypes.string,
  orderBy: PropTypes.string,
  setOrderByField: PropTypes.func,
  setOrderBy: PropTypes.func,
  showSales: PropTypes.bool,
  showTotalPrice: PropTypes.bool,
};
