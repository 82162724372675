import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ExternalLink } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { unique } from '@antv/s2';
import {
  BackButton,
  DaySpotAndRangePicker,
  Error,
  Loading,
  LoadingLinear,
  MaterialPivotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  MATERIAL_STOCK_STATUS,
  REPORT_SUB_PLACE_IN_WAREHOUSE,
} from '@iarcpsu/emanufac-constant';
import { currencyFormatter } from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config, useQuery } from '@/configs';
import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import * as actions from '@/redux/actions';
import generateMaterialActionLink from '@/utils/functions/IMS/generateMaterialActionLink';
import generateMaterialByMaterialSource from '@/utils/functions/IMS/generateMaterialByMaterialSource';

/**
 * @function MaterialByGroupWithClickableTable
 */

export default function MaterialByGroupWithClickableTable({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const { findWordInPlace, findWord } = useContext(WordLocalizeContext);
  const { pathname, search } = useLocation();

  // Global State
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const pdfReport = useSelector((state) => state.pdfReport);

  // Local state
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [total, setTotal] = useState();
  const [displayPivotTable, setIsDisplayPivotTable] = useState(true);
  const [usePurchaseDateSearch, setUsePurchaseDateSearch] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs(query.get('start') || undefined).toDate(),
  );
  const [endDate, setEndDate] = useState(
    dayjs(query.get('end') || undefined).toDate(),
  );
  const [startPurchaseDate, setStartPurchaseDate] = useState(
    dayjs(query.get('start') || undefined).toDate(),
  );
  const [endPurchaseDate, setEndPurchaseDate] = useState(
    dayjs(query.get('end') || undefined).toDate(),
  );
  const [isTotalBySource, setIsTotalBySource] = useState(true);
  const [showRemainAmount, setIsShowRemainAmount] = useState(false);
  const [filterOutZero, setIsFilterOutZero] = useState(false);
  const [showRunOutOff, setIsShowRunOutOff] = useState(false);
  const [filterByProcess, setFilterByProcess] = useState(false);

  const sourceOfData = {
    'material-source': {
      type_code: 'material-source',
      description: 'ที่มาวัตถุดิบ',
      title: query.get('mistakeplace')
        ? findWordInPlace('stranger_material') || 'วัตถุดิบไม่ตรงคลัง'
        : findWordInPlace('group_by_source') || 'วัตถุดิบตามกลุ่มที่มา',
    },
  };

  const queryDataFromServer = async () => {
    try {
      if (usePurchaseDateSearch) {
        dispatch(
          actions.materialStockLotGroupAll({
            purchaseStartDate: dayjs(startPurchaseDate).format('YYYY-MM-DD'),
            purchaseEndDate: dayjs(endPurchaseDate).format('YYYY-MM-DD'),
            groupBy: query.get('by'),
            place: query.get('place') || '',
            size,
            page,
            status: !showRunOutOff
              ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
              : '',
            placeRestricNotify: query.get('mistakeplace') ? true : '',
            strangerOnly: query.get('mistakeplace') ? true : '',
            fetchProcess: true,
          }),
        );
      } else {
        dispatch(
          actions.materialStockLotGroupAll({
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            groupBy: query.get('by'),
            place: query.get('place') || '',
            size,
            page,
            status: !showRunOutOff
              ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
              : '',
            placeRestricNotify: query.get('mistakeplace') ? true : '',
            strangerOnly: query.get('mistakeplace') ? true : '',
            fetchProcess: true,
          }),
        );
      }
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    dispatch(
      actions.materialAll({
        placeRestriction: query.get('place') !== '' ? 'true' : '',
        place: query.get('place'),
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(
      actions.pdfReportAll({
        page: 1,
        size: config.maxFetchSize,
        warehouse: query.get('place'),
      }),
    );

    return () => {};
  }, [query.get('place')]);

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, [
    page,
    size,
    startDate,
    endDate,
    showRunOutOff,
    usePurchaseDateSearch,
    startPurchaseDate,
    endPurchaseDate,
  ]);

  useEffect(() => {
    setTotal(materialStockLot?.total);

    return () => {};
  }, [materialStockLot]);

  useEffect(() => {
    if (query.get('place') && (query.get('place') !== '') !== '') {
      dispatch(actions.placeGet(query.get('place')));
    }

    return () => {};
  }, [query.get('place')]);

  useEffect(() => {
    dispatch(actions.pdfReportAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  const generatedData = generateMaterialByMaterialSource({
    materialStockLotBySource: materialStockLot?.rows,
    material: material?.rows,
    selectedDate: startDate,
    isTotalBySource,
    place,
    filterOutZero,
    nonRestrictPlace: query.get('mistakeplace')
      ? Boolean(query.get('mistakeplace'))
      : false,
    divideByProcess: filterByProcess,
  });

  const checkTurnOnProcessFilter = () => {
    if (query.get('mistakeplace') === 'true') {
      return place?.place_action?.for_stranger?.enable_group_by_process;
    }

    return place?.place_action?.enable_group_by_process;
  };

  // prettier-ignore
  const filterPDFReport = _.filter(pdfReport?.rows, (each) =>
    (query.get('mistakeplace') === 'true'
      ? each?.sub_place_in_warehose ===
        REPORT_SUB_PLACE_IN_WAREHOUSE.GROUP_BY_NOT_IN_PLACE.status_code
      : each?.sub_place_in_warehose ===
        REPORT_SUB_PLACE_IN_WAREHOUSE.GROUP_BY_IN_PLACE.status_code),);

  const isUsePointDate = () => {
    if (usePurchaseDateSearch) {
      if (dayjs(startPurchaseDate).isSame(dayjs(endPurchaseDate), 'day')) {
        return true;
      }
      return false;
    }

    if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
      return true;
    }
    return false;
  };

  const returnPointDate = () => {
    if (isUsePointDate()) {
      return usePurchaseDateSearch ? startPurchaseDate : startDate;
    }
    return null;
  };

  useEffect(() => {
    if (place?.place_action?.enable) {
      setFilterByProcess(checkTurnOnProcessFilter());
    }

    return () => {};
  }, [place]);

  const renderTitle = () => (
    <ViewTitle
      title={`${sourceOfData[query.get('by')]?.title}`}
      subtitle={subtitle}
    />
  );

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  console.log('Generated Data', generatedData);
  console.log('Material StockLot Rows', materialStockLot?.rows);

  const reducedMaterialList = _.reduce(
    materialStockLot?.rows,
    (result, groupedRow) => {
      _.map(groupedRow?.rows, (mslRow) => {
        result.push(mslRow);
      });
      return result;
    },
    [],
  );

  const uniqMaterial = _.uniqBy(reducedMaterialList, 'material._id');
  console.log('Unique Material', uniqMaterial);

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div>
            <BackButton />
          </div>
          <div className="flex flex-row justify-end pb-4">
            <Link to={`/ims/material-stock/group${search || ''}`}>
              <Button variant="contained">กลับไปหน้าแบบเดิม</Button>
            </Link>
            {_.map(filterPDFReport, (pdf) => (
              <Button
                variant="contained"
                onClick={() => {
                  history.push({
                    pathname: '/ims/report-display',
                    state: {
                      materialStockLot: generatedData,
                      report: pdf,
                      startDate: usePurchaseDateSearch
                        ? startPurchaseDate
                        : startDate,
                      endDate: usePurchaseDateSearch
                        ? endPurchaseDate
                        : endDate,
                      pointDate: returnPointDate(),
                    },
                  });
                }}
              >
                {pdf.printing_label || pdf?.name}{' '}
              </Button>
            ))}
          </div>
        </div>
        <div className="my-2">
          <Paper>
            <div className="flex flex-wrap py-4 px-2">
              <div className="w-full lg:w-1/3">
                <div className="flex gap-2 p-2 border rounded-md items-center">
                  <div>กรองตามวันที่เข้าคลังปัจจุบัน </div>
                  <Switch
                    checked={usePurchaseDateSearch}
                    onChange={(e) => {
                      setUsePurchaseDateSearch(e.target.checked);
                    }}
                  />
                  <div>กรองตาม{findWord('purchase_date') || 'วันที่ซื้อ'}</div>
                </div>
              </div>
              <div className="w-full lg:w-2/3 px-2">
                {usePurchaseDateSearch ? (
                  <DaySpotAndRangePicker
                    startDate={startPurchaseDate}
                    endDate={endPurchaseDate}
                    setEndDate={setEndPurchaseDate}
                    setStartDate={setStartPurchaseDate}
                    flex
                    state="date"
                    addSearchQuery={true}
                    anotherQuery={`?by=${query.get('by') || ''}&place=${
                      query.get('place') || ''
                    }&mistakeplace=${query.get('mistakeplace') || ''}`}
                  />
                ) : (
                  <DaySpotAndRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    state="date"
                    flex
                    addSearchQuery={true}
                    anotherQuery={`?by=${query.get('by') || ''}&place=${
                      query.get('place') || ''
                    }&mistakeplace=${query.get('mistakeplace') || ''}`}
                  />
                )}
              </div>
            </div>
          </Paper>
        </div>
        <div className="my-2">
          {query.get('place') && (
            <Card>
              <CardContent>
                <div className="flex justify-between flex-wrap">
                  <div className="w-full lg:w-1/2">
                    <div>คลังวัตถุดิบ</div>
                    <div className="text-lg font-semibold">{place?.name}</div>
                    {query.get('mistakeplace') === 'true' && (
                      <div>
                        <Chip color="warning" label="ไม่ตรงคลัง" />
                      </div>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-wrap p-2 border">
                    <div className="lg:w-2/3  rounded-md p-2 flex items-center">
                      <div>แยกเป็นล็อต</div>
                      <div>
                        <Switch
                          checked={isTotalBySource}
                          onChange={(e) => {
                            setIsTotalBySource(e.target.checked);
                            // setIsDisplayPivotTable(false);
                          }}
                        />
                      </div>
                      <div>รวมแหล่งเดียวกันไว้ด้วยกัน</div>
                    </div>
                    <div className="lg:w-1/3  rounded-md p-2 flex items-center">
                      <div></div>
                      <div>
                        <Checkbox
                          checked={showRemainAmount}
                          onChange={(e) =>
                            setIsShowRemainAmount(e.target.checked)
                          }
                        />
                      </div>
                      <div>แสดงคงเหลือ</div>
                    </div>
                    <div className="w-full p-2 flex gap-2">
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={filterOutZero}
                            onChange={(e) =>
                              setIsFilterOutZero(e.target.checked)
                            }
                          />
                        </div>
                        <div>กรองวัตถุดิบที่ไม่มีออก</div>
                      </div>{' '}
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={showRunOutOff}
                            onChange={(e) =>
                              setIsShowRunOutOff(e.target.checked)
                            }
                          />
                        </div>
                        <div>แสดงล็อตที่หมดแล้ว</div>
                      </div>
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={filterByProcess}
                            onChange={(e) =>
                              setFilterByProcess(e.target.checked)
                            }
                          />
                        </div>
                        <div>จัดกลุ่มตามการผลิต</div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <div className="my-2">
          {displayPivotTable ? (
            <div>
              <Paper>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        {' '}
                        <TableCell>
                          <div className="font-bold"> ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">แหล่งที่มา</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ดำเนินการ</div>
                        </TableCell>
                        {_.map(uniqMaterial, (eachMaterial, index) => (
                          <TableCell key={index}>
                            <div className="font-bold">
                              {eachMaterial?.material?.name}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(materialStockLot?.rows) ? (
                        _.map(materialStockLot?.rows, (mslRow, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <div className="text-xs">
                                {(page - 1) * size + index + 1}
                              </div>
                            </TableCell>
                            <TableCell>
                              {mslRow?.source.overall_code}
                              {mslRow?.source?.name}
                              <Link
                                to={`/ims/many-material-lots?lots=${_.map(
                                  mslRow?.rows,
                                  (e) => e?._id,
                                )}&place=${place?._id}`}
                              >
                                <IconButton>
                                  <ExternalLink size={16} />
                                </IconButton>
                              </Link>
                            </TableCell>{' '}
                            <TableCell>
                              <Link
                                to={generateMaterialActionLink(
                                  place,
                                  _.map(mslRow?.rows, (e) => e?._id),
                                  true,
                                )}
                              >
                                <Button variant="contained" size="small">
                                  {findWordInPlace('transfrom_to_another') ||
                                    'แปลงวัตถุดิบ'}
                                </Button>
                              </Link>
                            </TableCell>
                            {_.map(uniqMaterial, (eachMaterial, matIndex) => {
                              const lotWithThisMaterial = _.filter(
                                mslRow?.rows,
                                (each) =>
                                  each?.material?._id ===
                                  eachMaterial?.material?._id,
                              );

                              const sumQuantity = _.sumBy(
                                lotWithThisMaterial,
                                'quantity',
                              );

                              return (
                                <TableCell
                                  key={`${index}-${matIndex}`}
                                  className="flex gap-1"
                                >
                                  {currencyFormatter.format(sumQuantity)}
                                  <div>
                                    <Link
                                      to={generateMaterialActionLink(
                                        place,
                                        _.map(
                                          lotWithThisMaterial,
                                          (e) => e?._id,
                                        ),
                                        true,
                                      )}
                                    >
                                      <Button size="small" variant="outlined">
                                        บันทึก
                                      </Button>
                                    </Link>
                                  </div>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div className="text-center">ไม่มีข้อมูล</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>{' '}
                  <TablePagination
                    component="div"
                    count={total}
                    page={page - 1}
                    onPageChange={(event, newValue) => {
                      setPage(newValue + 1);
                    }}
                    rowsPerPage={size}
                    onRowsPerPageChange={(event) => {
                      setSize(event.target.value);
                      setPage(1);
                    }}
                  />
                </TableContainer>
              </Paper>
            </div>
          ) : (
            <LoadingLinear />
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialByGroupWithClickableTable.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialByGroupWithClickableTable.defaultProps = {
  title: '',
  subtitle: '',
};
