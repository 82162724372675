import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function ExportCostEstimationResult
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ExportCostEstimationResult({ title, subtitle }) {
  const dispatch = useDispatch();
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.costEstimationProfileAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.costEstimationProfileError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (costEstimationProfile.isLoading) {
    return <Loading />;
  }

  if (!costEstimationProfile.isLoading && costEstimationProfile.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div></div>
      </div>
    );
  }
  return <Error />;
}

ExportCostEstimationResult.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ExportCostEstimationResult.defaultProps = {
  title: '',
  subtitle: '',
};
