import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  INVENTORY_WORKFLOW,
  MATERIAL_STOCK_STATUS,
} from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MaterialStockFilterBox = ({
  place,
  customer,
  source,
  material,
  selectedPlace,
  setSelectedPlace = () => {},
  selectedSupplier,
  setSelectedSupplier = () => {},
  selectedSource,
  setSelectedSource = () => {},
  selectedMaterial,
  setSelectedMaterial = () => {},
  selectedStockStatus,
  setSelectedStockStatus = () => {},
  allowAddToSeachQuery,
  anotherQuery = '?',
  selectedWorkflow,
  setSelectedWorkflow = () => {},
  showSourceFilter = true,
  showSupplierFilter = true,
  showWorkflowFilter = false,
  searchTerm,
  setSearchTerm = () => {},
}) => {
  const history = useHistory();
  return (
    <Card>
      <CardContent>
        <div className="mb-2 font-semibold">กรองตามรายการต่างๆ</div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 lg:w-1/4 p-1">
            <TextField
              label="ค้นหา"
              size="small"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 p-1">
            <div>
              {!_.isEmpty(place?.rows) && (
                <Autocomplete
                  disablePortal
                  options={place?.rows}
                  size="small"
                  placeholder="เลือกคลังวัตถุดิบ"
                  value={selectedPlace}
                  onChange={(e, newValue) => {
                    setSelectedPlace(newValue);
                    if (allowAddToSeachQuery) {
                      history.push(
                        `${anotherQuery}&place=${
                          newValue?._id || ''
                        }&material=${selectedMaterial?._id || ''}&source=${
                          selectedSource?._id || ''
                        }&supplier=${selectedSupplier?._id || ''}&stockStatus=${
                          selectedStockStatus || ''
                        }&workflow=${selectedWorkflow || ''}`,
                      );
                    }
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
            }
                  renderInput={(params) => (
                    <TextField label="เลือกคลังวัตถุดิบ" {...params} />
                  )}
                />
              )}
            </div>
          </div>
          {showSupplierFilter && (
            <div className="w-full md:w-1/2 lg:w-1/4 p-1">
              <div>
                {!_.isEmpty(customer?.rows) && (
                  <Autocomplete
                    disablePortal
                    options={customer?.rows}
                    size="small"
                    placeholder="เลือกคู่ค้า"
                    onChange={(e, newValue) => {
                      setSelectedSupplier(newValue);
                      if (allowAddToSeachQuery) {
                        history.push(
                          `${anotherQuery}&place=${
                            selectedPlace?._id || ''
                          }&material=${selectedMaterial?._id || ''}&source=${
                            selectedSource?._id || ''
                          }&supplier=${newValue?._id || ''}&stockStatus=${
                            selectedStockStatus || ''
                          }&workflow=${selectedWorkflow || ''}`,
                        );
                      }
                    }}
                    value={selectedSupplier}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
            }
                    renderInput={(params) => (
                      <TextField label="เลือกคู่ค้า" {...params} />
                    )}
                  />
                )}
              </div>
            </div>
          )}
          <div className="w-full md:w-1/2 lg:w-1/4 p-1">
            <div>
              {!_.isEmpty(material?.rows) && (
                <Autocomplete
                  disablePortal
                  options={material?.rows}
                  size="small"
                  placeholder="เลือกวัตถุดิบ"
                  onChange={(e, newValue) => {
                    setSelectedMaterial(newValue);
                    if (allowAddToSeachQuery) {
                      history.push(
                        `${anotherQuery}&place=${
                          selectedPlace?._id || ''
                        }&material=${newValue?._id || ''}&source=${
                          selectedSource?._id || ''
                        }&supplier=${selectedSupplier?._id || ''}&stockStatus=${
                          selectedStockStatus || ''
                        }&workflow=${selectedWorkflow || ''}`,
                      );
                    }
                  }}
                  value={selectedMaterial}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
            }
                  renderInput={(params) => (
                    <TextField label="เลือกวัตถุดิบ" {...params} />
                  )}
                />
              )}
            </div>
          </div>
          {showSourceFilter && (
            <div className="w-full md:w-1/2 lg:w-1/4 p-1">
              <div>
                {!_.isEmpty(source?.rows) && (
                  <Autocomplete
                    disablePortal
                    options={source?.rows}
                    size="small"
                    placeholder="เลือกแหล่งวัตถุดิบ"
                    onChange={(e, newValue) => {
                      setSelectedSource(newValue);
                      if (allowAddToSeachQuery) {
                        history.push(
                          `${anotherQuery}&place=${
                            selectedPlace?._id || ''
                          }&material=${selectedMaterial?._id || ''}&source=${
                            newValue?._id || ''
                          }&supplier=${
                            selectedSupplier?._id || ''
                          }&stockStatus=${selectedStockStatus || ''}&workflow=${
                            selectedWorkflow || ''
                          }`,
                        );
                      }
                    }}
                    value={selectedSource}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
            }
                    renderInput={(params) => (
                      <TextField label="เลือกแหล่งวัตถุดิบ" {...params} />
                    )}
                  />
                )}
              </div>
            </div>
          )}
          <div className="w-full md:w-1/2 lg:w-1/4 p-1 ">
            <FormControl fullWidth size="small">
              <InputLabel>สถานะ</InputLabel>
              <Select
                label="สถานะ"
                size="small"
                value={selectedStockStatus}
                onChange={(event) => {
                  setSelectedStockStatus(event.target.value);
                  if (allowAddToSeachQuery) {
                    history.push(
                      `${anotherQuery}&place=${
                        selectedPlace?._id || ''
                      }&material=${selectedMaterial?._id || ''}&source=${
                        selectedSource?._id || ''
                      }&supplier=${selectedSupplier?._id || ''}&stockStatus=${
                        event.target.value || ''
                      }&workflow=${selectedWorkflow || ''}`,
                    );
                  }
                }}
              >
                <MenuItem value={null}>ทุกสถานะ</MenuItem>
                {_.map(MATERIAL_STOCK_STATUS, (status, key) => (
                  <MenuItem key={key} value={status.status_code}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {showWorkflowFilter && (
            <div className="w-full md:w-1/2 lg:w-1/4 p-1">
              <div>
                <FormControl fullWidth size="small">
                  <InputLabel>กิจกรรมของล็อต</InputLabel>
                  <Select
                    label="กิจกรรมของล็อต"
                    size="small"
                    value={selectedWorkflow}
                    onChange={(event) => {
                      setSelectedWorkflow(event.target.value);
                      if (allowAddToSeachQuery) {
                        history.push(
                          `${anotherQuery}&place=${
                            selectedPlace?._id || ''
                          }&material=${selectedMaterial?._id || ''}&source=${
                            selectedSource?._id || ''
                          }&supplier=${
                            selectedSupplier?._id || ''
                          }&stockStatus=${selectedStockStatus || ''}&workflow=${
                            event.target.value || ''
                          }`,
                        );
                      }
                    }}
                  >
                    <MenuItem value={null}>ทุกกิจกรรม</MenuItem>
                    {_.map(INVENTORY_WORKFLOW, (status, key) => (
                      <MenuItem key={key} value={status.status_code}>
                        {status.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MaterialStockFilterBox;

MaterialStockFilterBox.propTypes = {
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  source: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func,
  selectedSupplier: PropTypes.object,
  setSelectedSupplier: PropTypes.func,
  selectedSource: PropTypes.object,
  setSelectedSource: PropTypes.func,
  selectedMaterial: PropTypes.object,
  setSelectedMaterial: PropTypes.func,
  selectedStockStatus: PropTypes.string,
  setSelectedStockStatus: PropTypes.func,
  allowAddToSeachQuery: PropTypes.bool,
  anotherQuery: PropTypes.string,
  selectedWorkflow: PropTypes.string,
  setSelectedWorkflow: PropTypes.func,
  showSourceFilter: PropTypes.bool,
  showSupplierFilter: PropTypes.bool,
  showWorkflowFilter: PropTypes.bool,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};
