import { MATERIAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import calculateCostFromMaterialPercent from './material/calculateCostFromMaterialPercent';
import calculateCostFromMaterialAmount from './material/calculateCostFromMaterialAmount';

const calculateMaterialCost = async ({
  warehouseProfile,
  previousLotList,
  currentLot,
  api,
  isFrontend,
  mongooseModel = {},
}) => {
  console.log(
    `On Material Cost, Estimatie Using ${warehouseProfile?.material?.method}`,
  );
  let cost = 0;
  let costList = [];
  let tempData = { cost: 0, costList: [] };
  switch (warehouseProfile?.material?.method) {
    case MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_UNIT_COST.status_code:
      tempData = await calculateCostFromMaterialAmount({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    case MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_PERCENT_USED_COST
      .status_code:
      tempData = await calculateCostFromMaterialPercent({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    default:
      break;
  }
  return { cost, costList };
};

export default calculateMaterialCost;
