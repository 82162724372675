import dayjs from 'dayjs';
import _ from 'lodash';
import filterAllowanceMaterial from './filterAllowanceMaterial';

const getDataFrontend = async ({ maxFetchSize, currentDate, place, api }) => {
  const searchParams = new URLSearchParams({
    page: 1,
    size: maxFetchSize,
    startDate: dayjs(currentDate).format('YYYY-MM-DD'),
    endDate: dayjs(currentDate).format('YYYY-MM-DD'),
    place: place?._id,
  });

  const { data } = await api.get(
    `${
      process.env.REACT_APP_API_URL
    }/material-stock-lot?${searchParams.toString()}`,
  );
  return data;
};

const getDataBackend = async ({
  currentDate,
  maxFetchSize,
  MaterialStockLot,
  place,
}) => {
  try {
    const pipeline = [
      {
        $match: {
          receipt_date: {
            $gte: dayjs(currentDate).startOf('date').toDate(),
            $lte: dayjs(currentDate).endOf('date').toDate(),
          },
          place: place?._id,
        },
      },
      {
        $limit: maxFetchSize,
      },
      {
        $lookup: {
          from: 'materials',
          localField: 'material',
          foreignField: '_id',
          as: 'material',
        },
      },
      {
        $unwind: '$material',
      },
    ];
    const data = await MaterialStockLot.aggregate(pipeline);
    return data;
  } catch (error) {
    console.error('Cannot Get Material Usage On Place', error.message);
    return [];
  }
};

const getTotalMaterialUsageOnPlace = async ({
  place,
  currentDate,
  axios: api,
  maxFetchSize = 10000,
  isFrontend = true,
  MaterialStockLot,
}) => {
  try {
    let data = [];

    if (isFrontend) {
      ({ rows: data } = await getDataFrontend({
        maxFetchSize,
        currentDate,
        place,
        api,
      }));
    } else {
      data = await getDataBackend({
        currentDate,
        maxFetchSize,
        MaterialStockLot,
        place,
      });
    }

    const todayAllLotAllowMaterial = filterAllowanceMaterial(data, place);

    const allLotUnitList = _.map(todayAllLotAllowMaterial, (each) => {
      if (each?.material?.use_unit_conversion) {
        return each?.material?.unit_input?.short_sign;
      }
      return each?.material?.unit;
    });

    // Get Unit
    const uniqUnit = _.uniq(allLotUnitList);
    const selectedUnit = _.size(uniqUnit) > 1 ? '' : uniqUnit?.[0];

    const totalAllLotWeight = _.sum(
      _.map(todayAllLotAllowMaterial, (each) => each?.quantity),
    );

    return {
      total: totalAllLotWeight,
      unit: selectedUnit,
      lotList: todayAllLotAllowMaterial,
    };
  } catch (error) {
    console.error('Cannot Get Material Usage On Place', error);
    return {
      total: 0,
      unit: '',
    };
  }
};

export default getTotalMaterialUsageOnPlace;
