import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  LoadingLinear,
  MaterialLumpSumCostInput,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function MaterialCommonPrice
 * @description Display a collections or a list of MaterialCommonPrice from database
 */

export default function EditMaterialLumpSumLaborCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const browserQuery = useQuery();
  const history = useHistory();
  const page = parseInt(browserQuery.get('page'), 10) || 1;
  const size = parseInt(browserQuery.get('size'), 10) || 25;
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: { material: material?.rows },
  });
  const [allMaterials, setAllMaterials] = useState();
  const [isReady, setIsReady] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialAll({
          page,
          size,
          name: browserQuery.get('name') || '',
          selectMaterialType: browserQuery.get('material-type') || '',
        }),
      );

      const searchParams = new URLSearchParams({
        page: 1,
        size: config.maxFetchSize,
      });
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material?${searchParams}`,
      );
      setAllMaterials(data?.rows);
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  useEffect(() => {
    if (material?.rows && !_.isEmpty(allMaterials)) {
      const adjustedMaterialRow = _.map(material?.rows, (row) => ({
        ...row,
        labor_cost_with_condition: {
          enable: row?.labor_cost_with_condition?.enable,
          conditions: _.map(
            row?.labor_cost_with_condition?.conditions,
            (eachCondition) => {
              const foundedMaterial = _.find(
                allMaterials,
                (eachMaterial) => eachMaterial?._id === eachCondition?.material,
              );
              return {
                material: foundedMaterial,
                cost: eachCondition?.cost,
              };
            },
          ),
        },
      }));
      setValue('material', adjustedMaterialRow);
      console.log('Adjusted Material Row', adjustedMaterialRow);
      setIsReady(true);
    }

    return () => {};
  }, [material, allMaterials]);

  const handleUpdateMaterialCommonPrice = async (data) => {
    try {
      const materialRow = material?.rows;
      // ควบให้ข้อมูลวัตถุดิบ กับ ข้อมูลต้นทุนค่าแรงต่อหน่วย มาอยู่ด้วยกัน
      const zippedData = _.zip(materialRow, data.material);
      console.log('Data', data);
      console.log('Zipped Data', zippedData);

      // จัดการ Payload จากเป็น Array / 2 ตัว มาเป็น Object
      const zippedPayloadList = _.map(zippedData, (zipped) => {
        const [eachMaterial, eachLaborCost] = zipped;
        return {
          _id: eachMaterial?._id,
          name: eachMaterial?.name,
          ...eachLaborCost,
          unit_labor_cost: parseFloat(eachLaborCost?.unit_labor_cost),
          average_unit_time: parseFloat(eachLaborCost?.average_unit_time),
          average_labor_amount: parseFloat(eachLaborCost?.average_labor_amount),
        };
      });
      const filterLaborCost = _.filter(
        zippedPayloadList,
        (each) =>
          each?.unit_labor_cost > 0 ||
          each?.average_unit_time > 0 ||
          each?.average_labor_amount > 0 ||
          each?.labor_cost_with_condition?.enable === true,
      );
      console.log('Filter Zipped Payload List', filterLaborCost);
      await dispatch(actions.materialPut('many', filterLaborCost));
      Swal.fire({
        icon: 'success',
        title: 'บันทึกสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ดำเนินการไม่สำเร็จ',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <BackButton />
          <div>
            <Button
              onClick={handleSubmit(handleUpdateMaterialCommonPrice)}
              variant="contained"
            >
              บันทึก
            </Button>
          </div>
        </div>
        <div className="my-2">
          {isReady ? (
            <form onSubmit={handleSubmit(handleUpdateMaterialCommonPrice)}>
              <Paper>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <colgroup>
                      <col width="5%" />
                      <col width="10%" />
                      <col width="25%" />
                      <col width="10%" />
                      <col width="12%" />
                      <col width="12%" />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="font-bold">ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ชื่อวัตถุดิบ</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ต้นทุนค่าแรงต่อหน่วย</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">หน่วย</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">เวลาเฉลี่ยต่อหน่วย</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">
                            ปริมาณแรงงานที่ใช้เฉลี่ย
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(material?.rows) ? (
                        _.map(material?.rows, (row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <div className="text-xs">
                                {(page - 1) * size + index + 1}
                              </div>
                            </TableCell>
                            <TableCell>
                              {row?.type_code || ''} {row?.name}
                            </TableCell>
                            <TableCell>
                              {!_.isEmpty(allMaterials) ? (
                                <MaterialLumpSumCostInput
                                  control={control}
                                  defaultValue={row}
                                  index={index}
                                  watch={watch}
                                  materials={allMaterials}
                                />
                              ) : (
                                <Skeleton variant="text" width={200} />
                              )}
                            </TableCell>
                            <TableCell>
                              {row?.use_unit_conversion ? (
                                <div>บาท /{row?.unit_input?.name}</div>
                              ) : (
                                `บาท /${row?.unit}`
                              )}
                            </TableCell>
                            <TableCell>
                              <Controller
                                control={control}
                                name={`material.${index}.average_unit_time`}
                                defaultValue={row?.average_unit_time || 0}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="เวลาเฉลี่ยในการผลิต 1 หน่วย (นาที)"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </TableCell>{' '}
                            <TableCell>
                              <Controller
                                control={control}
                                name={`material.${index}.average_labor_amount`}
                                defaultValue={row?.average_labor_amount || 0}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="จำนวนแรงงานที่ใช้เฉลี่ย (คน)"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div className="text-center">ไม่มีข้อมูล</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <div className="flex justify-end my-1">
                <Button type="submit" variant="contained">
                  บันทึก
                </Button>
              </div>
            </form>
          ) : (
            <LoadingLinear />
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterialLumpSumLaborCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialLumpSumLaborCost.defaultProps = {
  title: '',
  subtitle: '',
};
