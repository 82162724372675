const calculateRealTDABC = async ({
  warehouseProfile,
  previousLotList,
  currentLot: inputCurrentLot,
  api,
  isFrontend,
  information,
}) => {
  const cost = 0;
  const costList = [];
  // TODO: Implement It
  return { cost, costList };
};

export default calculateRealTDABC;
