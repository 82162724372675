import React from 'react';
import { Controller } from 'react-hook-form';
import {
  MATERIAL_GROUP_BY_PAGE_TYPE_INPLACE,
  MATERIAL_GROUP_BY_PAGE_TYPE_MISPLACE,
  MATERIAL_GROUP_OPTION,
  PRODUCT_QUANTITY_DIVIDE_BY,
} from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MaterialStockPageActionButtons from '../../Button/MaterialStockPageActionButtons';
import LocalizationForm from '../Information/LocalizationForm';

export function PlaceForm({
  errors,
  place,
  control,
  department,
  information,
  materialType,
  watch,
  allPlaces,
  warehouse,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={place ? place.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสถานที่"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'address'}
          control={control}
          defaultValue={place ? place.address : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'allow_all_department'}
          control={control}
          defaultValue={place ? place.allow_all_department : true}
          render={({ field }) => (
            <FormControlLabel
              label="อนุญาตทุกแผนก"
              control={<Checkbox {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(department?.rows) && (
          <Controller
            name="allow_departments"
            control={control}
            defaultValue={place?.allow_departments}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                disablePortal
                options={department?.rows}
                size="small"
                placeholder="แผนก"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                className="z-40"
                // prettier-ignore
                getOptionLabel={(option) => `${option?.name || ''}`
          }
                renderInput={(params) => (
                  <TextField label="แผนกที่อนุญาต" {...params} />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'wip_place'}
          control={control}
          defaultValue={place?.wip_place}
          render={({ field }) => (
            <FormControlLabel
              label="คลังสินค้าระหว่างการผลิต (คลัง WIP)"
              control={<Checkbox {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
      {information?.setting?.ims?.useInventoryCategoryRestriction && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'inventory_restriction'}
            control={control}
            defaultValue={place?.inventory_restriction}
            render={({ field }) => (
              <FormControlLabel
                label="จำกัดเฉพาะบางประเภทของวัตถุดิบ"
                control={<Checkbox {...field} defaultChecked={field.value} />}
              />
            )}
          />
        </div>
      )}
      {information?.setting?.ims?.useInventoryCategoryRestriction && (
        <div className="w-full px-1 py-2">
          {!_.isEmpty(materialType?.rows) && (
            <Controller
              name="allow_categories"
              control={control}
              defaultValue={place?.allow_categories}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  disablePortal
                  options={materialType?.rows}
                  size="small"
                  placeholder="ประเภทของวัตถุดิบ"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
          }
                  renderInput={(params) => (
                    <TextField label="ประเภทของวัตถุดิบที่อนุญาต" {...params} />
                  )}
                />
              )}
            />
          )}
        </div>
      )}{' '}
      <div className="w-full px-1 py-2">
        <div className="w-full px-1 py-2">
          <Controller
            name={'place_action.enable'}
            control={control}
            defaultValue={place?.place_action?.enable}
            render={({ field }) => (
              <FormControlLabel
                label="เพิ่มกิจกรรมของวัตถุดิบในคลัง"
                control={<Checkbox {...field} defaultChecked={field.value} />}
              />
            )}
          />
        </div>
      </div>
      {watch('place_action.enable') && (
        <div className="w-full px-1 py-2">
          <div className="w-full px-1 py-2">
            {!_.isEmpty(allPlaces?.rows) && (
              <Controller
                name="place_action.destination_place"
                control={control}
                defaultValue={place?.place_action?.destination_place}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={allPlaces?.rows}
                    size="small"
                    placeholder="คลังวัตถุดิบเป้าหมาย"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
          }
                    renderInput={(params) => (
                      <TextField label="คลังวัตถุดิบเป้าหมาย" {...params} />
                    )}
                  />
                )}
              />
            )}
          </div>{' '}
          <div className="w-full px-1 py-2 flex justify-between">
            <div className="w-full lg:w-1/2">
              กิจกรรมที่ทำได้ในการส่งสู่คลังเป้าหมาย
            </div>
            <div className="w-full lg:w-1/2 flex flex-col">
              <Controller
                name={'place_action.move'}
                control={control}
                defaultValue={place?.place_action?.move}
                render={({ field }) => (
                  <FormControlLabel
                    label="ย้ายวัตถุดิบ"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              <Controller
                name={'place_action.transform'}
                control={control}
                defaultValue={place?.place_action?.transform}
                render={({ field }) => (
                  <FormControlLabel
                    label="แปลงวัตถุดิบ"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.enable_group_by_process'}
                control={control}
                defaultValue={place?.place_action?.enable_group_by_process}
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงปุ่มกรองโดยใช้ Process / คำสั่งผลิต (พร้อมตั้งค่าให้กรอง Process เป็นค่าเริ่มต้น)"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.show_total_previous'}
                control={control}
                defaultValue={place?.place_action?.show_total_previous}
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงปริมาณรวมจากขั้นที่แล้ว"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.show_remainder_from_previous'}
                control={control}
                defaultValue={place?.place_action?.show_remainder_from_previous}
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงเศษเหลือจากขั้นที่แล้ว"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.show_yield_by_group'}
                control={control}
                defaultValue={place?.place_action?.show_yield_by_group}
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดง Yield จากข้อมูลขั้นที่แล้ว"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.enable_add_prefer_product'}
                control={control}
                defaultValue={place?.place_action?.enable_add_prefer_product}
                render={({ field }) => (
                  <FormControlLabel
                    label="ให้มีการใส่สินค้าที่คาดว่าจะได้รับ"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.enable_customer'}
                control={control}
                defaultValue={place?.place_action?.enable_customer}
                render={({ field }) => (
                  <FormControlLabel
                    label="ให้ใส่ลูกค้าของวัตถุดิบ"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
            </div>
          </div>{' '}
          <div className="w-full py-2">
            <div>ตัวอย่างปุ่ม</div>
            <MaterialStockPageActionButtons
              place={watch()}
              allowNavigation={false}
              information={information}
            />
          </div>
          <div className="w-full px-1 py-2 flex justify-between">
            <div className="w-full lg:w-1/2">
              ปุ่มที่จะแสดงในหน้าวัตถุดิบในคลัง
            </div>
            <div className="w-full lg:w-1/2 flex flex-col">
              <Controller
                name={'place_action.display_material_lot_group_by_button'}
                control={control}
                defaultValue={
                  place?.place_action?.display_material_lot_group_by_button
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงปุ่มจัดกลุ่มตามแหล่งที่มา"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.display_material_lot_group_misplace'}
                control={control}
                defaultValue={
                  place?.place_action?.display_material_lot_group_misplace
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงปุ่มจัดกลุ่มตามแหล่งที่มา (สำหรับวัตถุดิบไม่ตรงคลัง)"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              <Controller
                name={'place_action.page_for_material_by_group_inplace'}
                control={control}
                defaultValue={
                  place?.place_action?.page_for_material_by_group_inplace
                }
                render={({ field }) => (
                  <div className="my-1">
                    <FormControl fullWidth size="small">
                      <InputLabel>
                        รูปแบบหน้าของการจัดกลุ่มตามแหล่งที่มา
                      </InputLabel>
                      <Select
                        label="รูปแบบหน้าของการจัดกลุ่มตามแหล่งที่มา"
                        {...field}
                      >
                        {_.map(
                          MATERIAL_GROUP_BY_PAGE_TYPE_INPLACE,
                          (each, index) => (
                            <MenuItem key={index} value={each?.status_code}>
                              {each?.description}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </div>
                )}
              />{' '}
              <Controller
                name={'place_action.page_for_material_by_group_misplace'}
                control={control}
                defaultValue={
                  place?.place_action?.page_for_material_by_group_misplace
                }
                render={({ field }) => (
                  <div className="my-1">
                    <FormControl fullWidth size="small">
                      <InputLabel>
                        รูปแบบหน้าของการจัดกลุ่มตามแหล่งที่มา (ไม่ตรงคลัง)
                      </InputLabel>
                      <Select
                        label="รูปแบบหน้าของการจัดกลุ่มตามแหล่งที่มา (ไม่ตรงคลัง)"
                        {...field}
                      >
                        {_.map(
                          MATERIAL_GROUP_BY_PAGE_TYPE_MISPLACE,
                          (each, index) => (
                            <MenuItem key={index} value={each?.status_code}>
                              {each?.description}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </div>
                )}
              />
            </div>
          </div>{' '}
          <div className="w-full px-1 py-2 flex justify-between">
            <div className="w-full lg:w-1/2">ให้เพิ่มผลผลิตลงในคลังสินค้า</div>
            <div className="w-full lg:w-1/2">
              {' '}
              <Controller
                name={'place_action.add_to_product_warehouse'}
                control={control}
                defaultValue={place?.place_action?.add_to_product_warehouse}
                render={({ field }) => (
                  <FormControlLabel
                    label="เพิ่มลงคลังสินค้า"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              {watch('place_action.add_to_product_warehouse') &&
                !_.isEmpty(warehouse?.rows) && (
                  <Controller
                    name={'place_action.destination_warehouse'}
                    control={control}
                    defaultValue={_.find(
                      warehouse?.rows,
                      (each) =>
                        each?._id ===
                        place?.place_action?.destination_warehouse,
                    )}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={warehouse?.rows}
                        size="small"
                        placeholder="คลังสินค้าเป้าหมาย"
                        onChange={(e, newValue) => {
                          field.onChange(newValue);
                        }}
                        className="z-40"
                        // prettier-ignore
                        getOptionLabel={(option) => `${option?.name || ''}`
          }
                        renderInput={(params) => (
                          <TextField label="คลังสินค้าเป้าหมาย" {...params} />
                        )}
                      />
                    )}
                  />
                )}
              {watch('place_action.add_to_product_warehouse') && (
                <div className="py-2">
                  <Controller
                    name={'place_action.product_quantity_divide_by'}
                    control={control}
                    defaultValue={
                      place?.place_action?.product_quantity_divide_by
                    }
                    render={({ field }) => (
                      <FormControl fullWidth className="py-2">
                        <InputLabel>
                          หารปริมาณวัตถุดิบด้วยสิ่งเหล่านี้เพื่อให้ได้ปริมาณสินค้า
                        </InputLabel>
                        <Select
                          {...field}
                          label="หารปริมาณวัตถุดิบด้วยสิ่งเหล่านี้เพื่อให้ได้ปริมาณสินค้า"
                          size="small"
                        >
                          {_.map(PRODUCT_QUANTITY_DIVIDE_BY, (each, index) => (
                            <MenuItem key={index} value={each?.status_code}>
                              {each?.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              )}{' '}
            </div>
          </div>{' '}
          <div className="w-full px-1 py-2 flex justify-between">
            <div className="w-full lg:w-1/2">
              กิจกรรมที่ทำในกรณีวัตถุแปลกปลอมเข้ามา
            </div>
            <div className="w-full lg:w-1/2 flex flex-col">
              <Controller
                name={'place_action.for_stranger.move'}
                control={control}
                defaultValue={place?.place_action?.for_stranger?.move}
                render={({ field }) => (
                  <FormControlLabel
                    label="ย้ายวัตถุดิบออก"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              <Controller
                name={'place_action.for_stranger.transform'}
                control={control}
                defaultValue={place?.place_action?.for_stranger?.transform}
                render={({ field }) => (
                  <FormControlLabel
                    label="แปลงวัตถุดิบเป็นชนิดอื่น"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              <Controller
                name={'place_action.for_stranger.show_total_inout'}
                control={control}
                defaultValue={
                  place?.place_action?.for_stranger?.show_total_inout
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงผลรวมของน้ำหนักวัตถุดิบเข้าและออก"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.for_stranger.enable_group_by_process'}
                control={control}
                defaultValue={
                  place?.place_action?.for_stranger?.enable_group_by_process
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงปุ่มกรองโดยใช้ Process / คำสั่งผลิต (พร้อมตั้งค่าให้กรอง Process เป็นค่าเริ่มต้น)"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.for_stranger.show_total_previous'}
                control={control}
                defaultValue={
                  place?.place_action?.for_stranger?.show_total_previous
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="แสดงปริมาณรวมจากขั้นที่แล้ว"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
            </div>
          </div>
          {watch('place_action.for_stranger.transform') && (
            <div className="w-full px-1 py-2 flex justify-between">
              <div className="w-full lg:w-1/2">
                การจัดการวัตถุดิบที่ได้จากการแปลงวัตถุดิบแปลกปลอม
              </div>
              <div className="w-full lg:w-1/2 flex flex-col">
                <div className="w-full">
                  <Controller
                    name={
                      'place_action.for_stranger.group_transfrom_result.enable'
                    }
                    control={control}
                    defaultValue={
                      place?.place_action?.for_stranger?.group_transfrom_result
                        ?.enable
                    }
                    render={({ field }) => (
                      <FormControlLabel
                        label="เปิดใช้การจัดกลุ่ม"
                        control={
                          <Switch {...field} defaultChecked={field.value} />
                        }
                      />
                    )}
                  />{' '}
                </div>
                {watch(
                  'place_action.for_stranger.group_transfrom_result.enable',
                ) && (
                  <div className="flex flex-col gap-2">
                    {' '}
                    <Controller
                      name={
                        'place_action.for_stranger.group_transfrom_result.group_by'
                      }
                      control={control}
                      defaultValue={
                        place?.place_action?.for_stranger
                          ?.group_transfrom_result?.group_by
                      }
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel>จัดกลุ่มตาม</InputLabel>
                          <Select
                            {...field}
                            label="จัดกลุ่มตาม"
                            fullWidth
                            size="small"
                          >
                            {_.map(MATERIAL_GROUP_OPTION, (each, key) => (
                              <MenuItem value={each?.status_code} key={key}>
                                {each?.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {watch(
                      'place_action.for_stranger.group_transfrom_result.group_by',
                    ) === MATERIAL_GROUP_OPTION.TAG.status_code && (
                      <Controller
                        name={
                          'place_action.for_stranger.group_transfrom_result.tag_to_group'
                        }
                        control={control}
                        defaultValue={
                          place?.place_action?.for_stranger
                            ?.group_transfrom_result?.tag_to_group
                        }
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>จัดกลุ่มตาม</InputLabel>
                            <Select
                              {...field}
                              label="จัดกลุ่มตาม"
                              fullWidth
                              size="small"
                            >
                              {_.map(
                                information?.setting?.ims?.additionalAttribute,
                                (each, index) => (
                                  <MenuItem value={each?.name} key={index}>
                                    {each?.name}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                    )}
                  </div>
                )}{' '}
                <Controller
                  name={'place_action.filter_product_by_peer_material'}
                  control={control}
                  defaultValue={
                    place?.place_action?.filter_product_by_peer_material
                  }
                  render={({ field }) => (
                    <FormControlLabel
                      label="เปิดการกรองสินค้าจากวัตถุดิบที่จับคู่กัน"
                      control={
                        <Checkbox {...field} defaultChecked={field.value} />
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="w-full px-1 py-2 flex flex-wrap justify-between">
            <div className="w-full lg:w-1/2">
              ให้มีการสร้าง โพรเซศการผลิตอัตโนมัติ
              <br />
              หลังจากผ่านการออกจากคลังนี้ (กรณีล็อตนั้นไม่ได้ติดกับคำสั่งผลิต)
            </div>
            <div className="w-full lg:w-1/2">
              <Controller
                name={'place_action.create_or_update_process_after'}
                control={control}
                defaultValue={
                  place?.place_action?.create_or_update_process_after
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="อนุญาตให้ทำ"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
            </div>
          </div>
          <div className="w-full px-1 py-2 flex flex-wrap justify-between">
            <div className="w-full lg:w-1/2">
              การประมาณการต้นทุน
              <div className="text-sm">
                การตั้งค่าเหล่านี้จะอัพเดทอัตโนมัติเมื่อมีการตั้งค่า Cost
                Estimation Profile
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <Controller
                name={'place_action.cost_labor_lumpsum_by_weight'}
                control={control}
                defaultValue={place?.place_action?.cost_labor_lumpsum_by_weight}
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนแรงงาน ให้มีการคิดต้นทุนแบบเหมาจ่ายตามน้ำหนัก"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.cost_labor_divide_by_weight'}
                control={control}
                defaultValue={place?.place_action?.cost_labor_divide_by_weight}
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนแรงงาน คิดแบบหารจากค่าแรงทั้งหมดตามน้ำหนัก"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              <Controller
                name={'place_action.cost_labor_tdabc'}
                control={control}
                defaultValue={place?.place_action?.cost_labor_tdabc}
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนแรงงาน คิดแบบต้นทุนกิจกรรมตามเวลา"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />{' '}
              <Controller
                name={'place_action.cost_machine_divide_by_weight'}
                control={control}
                defaultValue={
                  place?.place_action?.cost_machine_divide_by_weight
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนเครื่องจักร คิดแบบหารเฉลี่ยตามน้ำหนัก"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.cost_machine_tdabc'}
                control={control}
                defaultValue={place?.place_action?.cost_machine_tdabc}
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนเครื่องจักร คิดแบบต้นทุนกิจกรรมตามเวลา"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.cost_utility_divide_by_weight'}
                control={control}
                defaultValue={
                  place?.place_action?.cost_utility_divide_by_weight
                }
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนค่าน้ำ/ไฟ และอื่นๆ คิดแบบหารเฉลี่ยตามน้ำหนัก"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                name={'place_action.cost_utility_tdabc'}
                control={control}
                defaultValue={place?.place_action?.cost_utility_tdabc}
                render={({ field }) => (
                  <FormControlLabel
                    label="ต้นทุนค่าน้ำ/ไฟ และอื่นๆ แบบต้นทุนกิจกรรมตามเวลา"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className="w-full px-1 py-2">
        <div className="font-semibold my-2 text-lg">การใช้ภาษา</div>
        <LocalizationForm information={place} control={control} />
      </div>
    </div>
  );
}

PlaceForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    address: PropTypes.object,
  }),
  place: PropTypes.object,
  control: PropTypes.object,
  department: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  information: PropTypes.object,
  materialType: PropTypes.shape({
    rows: PropTypes.object,
  }),
  watch: PropTypes.func,
  allPlaces: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  warehouse: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

PlaceForm.defaultProps = {
  place: null,
};

export default PlaceForm;
