import EditPlace from './EditPlace';
import LocaleWordSetting from './LocaleWordSetting';
import Place from './Place';
import SettingIMS from './SettingIMS';
import SettingInventoryOperation from './SettingInventoryOperation';

export {
  EditPlace,
  LocaleWordSetting,
  Place,
  SettingIMS,
  SettingInventoryOperation,
};
export default {
  EditPlace,
  LocaleWordSetting,
  Place,
  SettingIMS,
  SettingInventoryOperation,
};
