import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { getFactoryCapitalCostInDay } from '@iarcpsu/emanufac-utils/functions';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function DetailFactoryCapitalDate
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailFactoryCapitalDate({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialStockLotGet(params.id));
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const getCapitalCost = async () => {
    try {
      const tempFactoryCapitalCost = await getFactoryCapitalCostInDay({
        currentDate: materialStockLot?.inventory_date,
        isFrontend: true,
        axios: api,
        maxFetchSize: config.maxFetchSize,
        information,
        enableElement: {},
      });
      //   setFactoryCapitalCost(tempFactoryCapitalCost);
    } catch (error) {
      console.error('Cannot Get Factory Capital Cost', error);
    }
  };

  useEffect(() => {
    if (materialStockLot) {
      getCapitalCost();
    }

    return () => {};
  }, [materialStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div></div>
      </div>
    );
  }
  return <Error />;
}

DetailFactoryCapitalDate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailFactoryCapitalDate.defaultProps = {
  title: '',
  subtitle: '',
};
