import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  MaterialLotCostEstimationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Card, CardContent, FormControlLabel, Switch } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function BeginnerLots
 * @description Display a collections or a list of BeginnerLots from database
 */

export default function BeginnerLots({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const beginningPlace =
    information?.setting?.ims?.cost_estimation
      ?.begining_place_in_cost_estimation;
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [total, setTotal] = useState(0);
  const [enable, setEnable] = useState(false);

  const queryDataFromServer = async () => {
    try {
      if (beginningPlace) {
        dispatch(
          actions.materialStockLotAll({
            page,
            size,
            place: beginningPlace,
            stockStatus: '',
            startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
            endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          }),
        );
      }
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, beginningPlace, startDate, endDate]);

  useEffect(() => {
    if (materialStockLot) {
      setTotal(materialStockLot.total);
    }

    return () => {};
  }, [materialStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (!beginningPlace) {
    return (
      <Error message="ทางผู้ดูแลระบบไม่ได้ระบุคลังเริ่มต้นของการประมาณการต้นทุน" />
    );
  }

  const handleEnable = (e) => {
    if (!e.target.checked) {
      setStartDate();
      setEndDate();
    }
    setEnable(e.target.checked);
  };

  if (materialStockLot.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex justify-between items-center">
                <div className="w-full lg:w-1/4">
                  <FormControlLabel
                    label="เปิดการกรอง"
                    control={<Switch value={enable} onChange={handleEnable} />}
                  />
                </div>
                <div className="w-full lg:w-3/4">
                  <DaySpotAndRangePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    enable={enable}
                    flex
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <MaterialLotCostEstimationTable
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            imsSetting={information?.setting?.ims}
            materialStockLot={materialStockLot}
            total={total}
            showCalculatedCost={false}
            showCostDriverButton={false}
            showCostInChidLotButton={true}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

BeginnerLots.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

BeginnerLots.defaultProps = {
  title: '',
  subtitle: '',
};
