import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DesktopDatepicker } from '../../DatePicker';

const ProcessDeliveryForm = ({
  control,
  defaultDelivery,
  shippingType,
  showDeliverySwitch,
}) => (
  <div className="flex flex-wrap">
    {showDeliverySwitch && (
      <div className="w-full px-2 py-1">
        <Controller
          control={control}
          defaultValue={defaultDelivery?.delivered}
          name="delivery.delivered"
          render={({ field }) => (
            <FormControlLabel
              label="จัดส่งแล้ว"
              control={<Switch {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
    )}
    <div className="w-full px-2 py-1">
      <Controller
        control={control}
        defaultValue={defaultDelivery?.delivery_date}
        name="delivery.delivery_date"
        render={({ field }) => (
          <DesktopDatepicker
            label="วันที่จัดส่ง"
            {...field}
            size="small"
            fullWidth
          />
        )}
      />
    </div>

    <div className="w-full px-2 py-1">
      <Controller
        control={control}
        defaultValue={defaultDelivery?.delivery_method?._id}
        name="delivery.delivery_method"
        render={({ field }) => (
          <FormControl fullWidth size="small">
            <InputLabel>วิธีการจัดส่ง</InputLabel>
            <Select {...field} label="วิธีการจัดส่ง">
              {_.map(shippingType?.rows, (eachType, index) => (
                <MenuItem key={index} value={eachType?._id}>
                  {eachType?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </div>
    <div className="w-full px-2 py-1">
      <Controller
        control={control}
        defaultValue={defaultDelivery?.delivery_remark}
        name="delivery.delivery_remark"
        render={({ field }) => (
          <TextField {...field} fullWidth label="หมายเหตุ" multiline rows={3} />
        )}
      />
    </div>
  </div>
);

export default ProcessDeliveryForm;

ProcessDeliveryForm.propTypes = {
  control: PropTypes.object,
  defaultDelivery: PropTypes.object,
  shippingType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  showDeliverySwitch: PropTypes.bool,
};
