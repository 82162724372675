import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const WordLocalizeContext = React.createContext();

const WordLocalizeProvider = ({ children }) => {
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);
  // หา Word ทั้วไป
  const findWord = (keyword) => information?.localization?.[keyword] || null;
  // หา Word ในคลัง
  const findWordInPlace = (keyword) => place?.localization?.[keyword] || null;
  const findWordInPlaceByCustomPlace = (keyword, customPlace) =>
    customPlace?.localization?.[keyword] || null;

  return (
    <WordLocalizeContext.Provider
      value={{
        findWord,
        findWordInPlace,
        findWordInPlaceByCustomPlace,
      }}
    >
      {children}
    </WordLocalizeContext.Provider>
  );
};

WordLocalizeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default WordLocalizeProvider;
