import React, { useState } from 'react';
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MaterialLotCostDriverBox from '../Material/MaterialLotCostDriverBox';
import EmployeeTimeDrivenBox from '../Product/EmployeeTimeDrivenBox';
import MachineTimeDrivenBox from '../Product/MachineTimeDrivenBox';

import CostProfileRendering from './CostProfileRendering';

const CostEstimationOverallContainer = ({
  information,
  previousLotList,
  costEstimationProfile,
  currentLot,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const defaultTDABCCost = (
    <div>
      <div className="my-2">
        <Card>
          <CardContent>
            <EmployeeTimeDrivenBox
              defaultSplittedParent={false}
              previousLotList={previousLotList}
            />
          </CardContent>{' '}
        </Card>
      </div>{' '}
      <div className="my-2">
        <Card>
          <CardContent>
            <MachineTimeDrivenBox
              previousLotList={previousLotList}
              defaultSplittedParent={false}
            />
          </CardContent>{' '}
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <MaterialLotCostDriverBox previousLotList={previousLotList} />
          </CardContent>{' '}
        </Card>
      </div>
    </div>
  );

  const costEstimationProfileUse = (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(parseInt(newValue, 10))}
        >
          {_.map(
            _.orderBy(costEstimationProfile?.rows, ['createdAt'], ['asc']),
            (row, index) => (
              <Tab label={row.name} key={index} value={index} />
            ),
          )}
        </Tabs>
      </Box>

      <CostProfileRendering
        costEstimatioProfile={
          _.orderBy(costEstimationProfile?.rows, ['createdAt'], ['asc'])?.[
            currentTab
          ]
        }
        previousLotList={previousLotList}
        currentLot={currentLot}
        isCurrentAsProductLot={false}
      />
    </div>
  );
  return (
    <div>
      {information?.setting?.ims?.cost_estimation?.use_cost_estimation_profile
        ? costEstimationProfileUse
        : defaultTDABCCost}
    </div>
  );
};

export default CostEstimationOverallContainer;

CostEstimationOverallContainer.propTypes = {
  information: PropTypes.object,
  previousLotList: PropTypes.arrayOf(PropTypes.object),
  costEstimationProfile: PropTypes.object,
  currentLot: PropTypes.object,
};
