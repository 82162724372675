/* eslint-disable arrow-body-style */
import React from 'react';
import { COST_ESTIMATION_ELEMENT } from '@iarcpsu/emanufac-constant';
import { Card, CardContent, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CapitalCost from './CapitalCost';
import LaborCost from './LaborCost';
import MaterialCost from './MaterialCost';

const CostProfileRendering = ({
  costEstimatioProfile,
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
}) => {
  const renderCostProfile = (profile) => {
    switch (profile?.cost_estimation_element) {
      case COST_ESTIMATION_ELEMENT.MATERIAL.status_code:
        return (
          <MaterialCost
            method={profile?.material?.method}
            useCostFromPreviousMaterial={
              profile?.material?.use_cost_from_pervious_material
            }
            previousLotList={previousLotList}
            place={profile?.place}
            peerPlace={profile?.material?.calculate_percent_from_place}
          />
        );
      case COST_ESTIMATION_ELEMENT.LABOR.status_code:
        return (
          <LaborCost
            method={profile?.labor?.method}
            previousLotList={previousLotList}
            place={profile?.place}
            currentLot={currentLot}
            isCurrentAsProductLot={isCurrentAsProductLot}
            useTDABCArtifact={profile?.labor?.use_artifact_time_in_tdabc}
          />
        );
      case COST_ESTIMATION_ELEMENT.CAPITAL_COST.status_code:
        return (
          <CapitalCost
            method={profile?.capital_cost?.method}
            enableElement={profile?.capital_cost?.element}
            previousLotList={previousLotList}
            place={profile?.place}
            currentLot={currentLot}
            isCurrentAsProductLot={isCurrentAsProductLot}
            useTDABCArtifact={profile?.capital_cost?.use_artifact_time_in_tdabc}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      {_.map(costEstimatioProfile?.warehouses, (profile, index) => {
        return (
          <div key={index} className="my-2">
            <Card>
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: 'text.secondary', fontSize: 14 }}
                >
                  {profile?.use_warehouse_instead
                    ? profile?.warehouse?.name
                    : profile?.place?.name}
                </Typography>
                <h2 className="font-semibold">{profile?.name}</h2>

                {renderCostProfile(profile)}
              </CardContent>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default CostProfileRendering;

CostProfileRendering.propTypes = {
  costEstimatioProfile: PropTypes.shape({
    warehouses: PropTypes.arrayOf(PropTypes.object),
  }),
  previousLotList: PropTypes.array,
};
