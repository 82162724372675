/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import filterOnlyChildMaterial from '../../../cost-estimation/filterOnlyChildMaterial';
import filterAllowanceMaterial from '../../../cost-estimation/filterAllowanceMaterial';
import getFactoryCapitalCostInDay from '../../../cost-estimation/getFactoryCapitalCostInDay';
import { config } from '../../../../config';

const calculateCostPerMaerial = (row, costPerMinute) => {
  const minuteUse =
    (row?.material?.average_unit_time || 0) * (row?.quantity || 0);

  const costPerOneMan = costPerMinute * minuteUse;
  return costPerOneMan;
};

const generateCostList = async ({
  allowChildMaterial,
  currentDate,
  isFrontend,
  api,
  information,
  enableElement,
  FactoryCapitalCost,
}) => {
  const factoryCapitalCost = await getFactoryCapitalCostInDay({
    currentDate,
    isFrontend,
    axios: api,
    maxFetchSize: config.maxFetchSize,
    information,
    enableElement,
    FactoryCapitalCost,
  });
  const hourPerDay = information?.setting?.pams?.cc_working_hour_per_day;
  const minutePerDay = hourPerDay * 60;
  const costPerMinute = factoryCapitalCost / minutePerDay;

  const newCostList = _.map(allowChildMaterial, (row) => ({
    ...row,
    artifact_cost: true,
    average_unit_time_per_quantity: row?.material?.average_unit_time,
    artifact_unit_cost: costPerMinute,
    artifact_used_time:
      (row?.material?.average_unit_time || 0) * (row?.quantity || 0),
    cost: calculateCostPerMaerial(row, costPerMinute),
  }));

  return newCostList;
};

const calculateArtifactTDABC = async ({
  warehouseProfile,
  previousLotList,
  currentLot: inputCurrentLot,
  api,
  isFrontend,
  information,
  FactoryCapitalCost,
  splitCostDriver = false,
}) => {
  try {
    const place = warehouseProfile?.place;
    const allowMaterial = _.filter(
      previousLotList,
      (each) => each?.place?._id?.toString() === place?._id?.toString(),
    );
    const enableElement = warehouseProfile?.capital_cost?.element;
    const allowanceMaterial = filterAllowanceMaterial(previousLotList, place);
    const currentLot = inputCurrentLot || allowanceMaterial[0];
    const currentDate = currentLot?.receipt_date;

    console.log('CAPITAL COST -  TDABC ARTIFACT ');

    const allowChildMaterial = filterOnlyChildMaterial({
      allowCategory: _.map(place?.allow_categories, (e) => e.toString()),
      currentLotMaterial: currentLot?.material,
      filterLotOnPlace: allowMaterial,
    });
    let newCostList = [];
    if (splitCostDriver) {
      console.log('Splitted Cost Driver');
      const enableCostObject = _.reduce(
        enableElement,
        (result, current, key) => {
          if (current) {
            result.push(key);
          }
          return result;
        },
        [],
      );

      console.log('Enable Cost Element', enableCostObject);

      for await (const eachElement of enableCostObject) {
        const tempEnableElement = {};
        tempEnableElement[eachElement] = true;
        console.log(`Find Cost List For  ${eachElement}`);
        const costList = await generateCostList({
          allowChildMaterial,
          currentDate,
          isFrontend,
          api,
          information,
          enableElement: tempEnableElement,
          FactoryCapitalCost,
        });

        const costListWithTitle = _.map(costList, (e) => ({
          ...e,
          artifact_cost_title: eachElement,
        }));
        newCostList = [...newCostList, ...costListWithTitle];
      }
    } else {
      console.log('Combined Cost Driver');

      newCostList = await generateCostList({
        allowChildMaterial,
        currentDate,
        isFrontend,
        api,
        information,
        enableElement,
        FactoryCapitalCost,
      });
    }

    const totalCost = _.sumBy(newCostList, 'cost');

    return {
      costList: newCostList,
      cost: totalCost,
    };
  } catch (error) {
    console.error(
      'Cannot calculate artificial TDABC Capital Cost',
      error.message,
    );
    return {
      cost: 0,
      costList: [],
    };
  }
};

export default calculateArtifactTDABC;
