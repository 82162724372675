/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LABOR_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  filterOnlyChildMaterial,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

const LaborTDABCArtifact = ({ previousLotList, place, currentLot }) => {
  const allowMaterial = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;
  const allowCategoryWithComma = _.join(allowCategory, ',');
  const allowChildMaterial = filterOnlyChildMaterial({
    allowCategory: place?.allow_categories,
    currentLotMaterial: currentLot?.material,
    filterLotOnPlace: allowMaterial,
  });
  const information = useSelector((state) => state.information);
  const hourPerDay = information?.setting?.pams?.daily_payroll?.working_hour;
  const minutePerDay = hourPerDay * 60;

  const calculateTotalCostPerMaterial = (lot) => {
    const costPerMinute = lot?.place?.average_labor_cost / minutePerDay;
    const minuteUse = (lot?.material?.average_unit_time || 0) * lot?.quantity;

    const costPerOneMan = costPerMinute * minuteUse;
    const costPerManyPeople =
      costPerOneMan * (lot?.material?.average_labor_amount || 1);
    return costPerManyPeople;
  };
  return (
    <div>
      <div className="flex justify-between my-2">
        <div className="font-semibold">
          ต้นทุนค่าแรง: {LABOR_COST_ESTIMATION_METHOD.TDABC.description}
        </div>
        <Link
          to={`/ims/materials/labor-cost/edit?material-type=${allowCategoryWithComma}`}
        >
          <Button variant="outlined" color="warning" size="small">
            แก้ไขค่าแรง
          </Button>
        </Link>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิงล็อต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    เวลาโดยประมาณ
                    <br />
                    ต่อหน่วย
                  </div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ปรืมาณ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รวมเวลา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    ต้นทุนเฉลี่ยต่อคน <br />
                    ต่อวัน{' '}
                  </div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">
                    ต้นทุนเฉลี่ยต่อคน
                    <br />
                    ต่อนาที
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนคนเฉลี่ย</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ต้นทุนรวม</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(allowChildMaterial) ? (
                _.map(allowChildMaterial, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>{row?.running_number}</TableCell>
                    <TableCell>{row?.material?.name}</TableCell>
                    <TableCell>
                      {row?.material?.average_unit_time || '-'} นาที
                    </TableCell>{' '}
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        noWrap
                        quantity={row?.quantity}
                      />
                    </TableCell>
                    <TableCell>
                      {(row?.material?.average_unit_time || 0) * row?.quantity}{' '}
                      นาที
                    </TableCell>
                    <TableCell>{row?.place?.average_labor_cost} บาท</TableCell>
                    <TableCell>
                      {currencyFormatter.format(
                        row?.place?.average_labor_cost / minutePerDay,
                      )}{' '}
                      บาท
                    </TableCell>
                    <TableCell>
                      {row?.material?.average_labor_amount} คน
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(
                        calculateTotalCostPerMaterial(row),
                      )}{' '}
                      บาท
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {!_.isEmpty(allowChildMaterial) && (
                <TableRow>
                  <TableCell colSpan={9}>
                    <div className="text-center font-bold">รวม</div>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <div className="font-bold">
                      {currencyFormatter.format(
                        _.sumBy(allowChildMaterial, (o) =>
                          calculateTotalCostPerMaterial(o),
                        ),
                      )}{' '}
                      บาท
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default LaborTDABCArtifact;

LaborTDABCArtifact.propTypes = {
  previousLotList: PropTypes.arrayOf(PropTypes.object),
  place: PropTypes.object,
  currentLot: PropTypes.object,
};
