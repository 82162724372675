import React from 'react';
import { Link } from 'react-router-dom';
import { LABOR_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  findMaterialOriginalCost,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

function LaborCostLumpsumFromOriginal({
  previousLotList,
  place,
  currentLot,
  isCurrentAsProductLot,
}) {
  /**
   * currentLot and currentLotMaterial can be null
   * when it called from cost estimation from beginer log
   * it will automatically be non-working in process
   * and the material will be the material prodcut
   * กรณีไม่ส่ง currentLot มา แสดงว่ามันมาจากหน้าต้นทุนจากล็อตเริ่มต้น
   * จะเป็นการไม่ Specify Current lot จะถถือว่าวัตถุดิบไม่ใช่ WIP และแสดงผลจากทุกอัน
   */
  const currentLotMaterial = currentLot?.material;
  const filterLotOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;
  const allowCategoryWithComma = _.join(allowCategory, ',');

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  let filterAllowanceMaterial;
  // กรณีเป็น Work in process เอามาเฉพาะที่สืบอดมาได้เท่านั้น
  if (currentLotMaterial?.is_workin_process) {
    const inputMaterialList = _.map(
      currentLotMaterial?.input_materials,
      (each) => each?.material,
    );

    filterAllowanceMaterial = _.filter(
      filterLotOnPlace,
      (each) =>
        _.includes(allowCategory, each?.material?.material_type) &&
        _.includes(inputMaterialList, each?.material?._id),
    );
    // กรณีไม่เป็น WIP เอามาทุกตัว
  } else {
    filterAllowanceMaterial = _.filter(filterLotOnPlace, (each) =>
      _.includes(allowCategory, each?.material?.material_type),
    );
  }

  const getUnitLaborCost = (selectedMaterial, originalMaterial) => {
    if (selectedMaterial?.labor_cost_with_condition?.enable) {
      const foundCondition = _.find(
        selectedMaterial?.labor_cost_with_condition?.conditions,
        (eachCondition) =>
          eachCondition?.material?._id === originalMaterial?._id ||
          eachCondition?.material === originalMaterial?._id,
      );
      return foundCondition?.cost || 0;
    }
    if (originalMaterial?.unit_labor_cost) {
      return originalMaterial?.unit_labor_cost;
    }
    return 0;
  };

  const originalCostList = findMaterialOriginalCost({
    allowanceMaterial: filterAllowanceMaterial,
    allLotList: previousLotList,
    materialOnPlace: filterLotOnPlace,
    useCostFromPrevious: true,
  });

  const originalCostWithLaborCost = _.map(originalCostList, (each) => {
    const amountOfOriginalCost = _.size(each?.original_costs);
    const quantityDivideBySize = each?.quantity / amountOfOriginalCost;

    const result = {
      ...each,
      original_costs: _.map(each?.original_costs, (eachOriginal) => {
        const unitLaborCost = getUnitLaborCost(
          each?.material,
          eachOriginal?.material,
        );
        return {
          ...eachOriginal,
          unit_labor_cost: unitLaborCost,
          labor_cost: quantityDivideBySize * unitLaborCost,
        };
      }),
    };

    const totalCost = _.sumBy(
      result?.original_costs,
      (eachCost) => eachCost?.labor_cost,
    );

    return { ...result, total_labor_cost: totalCost };
  });

  const totalCostList = _.map(
    originalCostWithLaborCost,
    (each) => each?.total_labor_cost,
  );
  const totalCost = _.sum(totalCostList);

  if (isCurrentAsProductLot) {
    return <div>ระบบยังไม่รองรับจากคลังสินค้า</div>;
  }
  return (
    <div className="my-2">
      <div className="flex justify-between">
        <div className="font-semibold my-2">
          ต้นทุนแรงงาน:{' '}
          {LABOR_COST_ESTIMATION_METHOD.LUMPSUM_BY_MATERIAL.description}
        </div>
        <div className="flex gap-1">
          {currentLot && (
            <Link
              to={`/ims/materials/ingredient/edit/${currentLotMaterial?._id}`}
            >
              <Button variant="outlined" color="warning" size="small">
                แก้ไขวัตถุดิบส่วนประกอบ
              </Button>
            </Link>
          )}
          <Link
            to={`/ims/materials/labor-cost/edit?material-type=${allowCategoryWithComma}`}
          >
            <Button variant="outlined" color="warning" size="small">
              แก้ไขค่าแรง
            </Button>
          </Link>
        </div>
      </div>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">เลขอ้างอิงล็อต</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ปริมาณที่ได้</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">วัตถุดิบต้นทาง</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">ค่าแรงต่อหน่วย</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">รวมมูลค่า</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(originalCostWithLaborCost) ? (
              _.map(originalCostWithLaborCost, (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <div className="text-xs">{index + 1}</div>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/ims/cost-driver/${row?.lot?._id}`}
                      className="underline text-gray-700"
                    >
                      {row?.lot?.running_number}
                    </Link>
                  </TableCell>
                  <TableCell>{row?.material?.name}</TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={row?.material}
                      quantity={row?.quantity}
                    />
                  </TableCell>
                  <TableCell>
                    {_.map(row?.original_costs, (each, orgIndex) => (
                      <TableRow key={orgIndex}>{each?.material?.name}</TableRow>
                    ))}
                  </TableCell>
                  <TableCell>
                    {_.map(row?.original_costs, (each, orgIndex) => (
                      <TableRow key={orgIndex}>
                        {each?.unit_labor_cost} บาท
                      </TableRow>
                    ))}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(row?.total_labor_cost)}
                    บาท
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
            {!_.isEmpty(originalCostWithLaborCost) && (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" colSpan={6}>
                  <div className="font-semibold">รวม</div>
                </TableCell>
                <TableCell>{currencyFormatter.format(totalCost)} บาท</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default LaborCostLumpsumFromOriginal;

LaborCostLumpsumFromOriginal.propTypes = {
  previousLotList: PropTypes.array,
  place: PropTypes.object,
  currentLot: PropTypes.object,
  isCurrentAsProductLot: PropTypes.bool,
};
