import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { PRODUCT_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import { MultiUnitProdOrMatBox } from '../../Box';
import { ProdOrMatQuantityInput } from '../../Inputs';

export default function ProductLotSelector({
  product,
  warehouse,
  information,
  watch,
  setValue,
  control,
  productIndex,
}) {
  const [stockLotList, setStockLotList] = useState([]);
  const wmsSetting = information?.setting?.wms;
  const fetchingProductStockLot = async () => {
    const gqlQuery = gql`
      query FindProductStockLot($pslInput: ProductStockLotInput) {
        findProductStockLots(input: $pslInput) {
          currPage
          lastPage
          total
          rows {
            _id
            running_number
            warehouse {
              name
            }
            status
            lot_number
            production_date
            expiration_date
            inventory_date
            amount
            quantity
          }
        }
      }
    `;
    if (!product) {
      setStockLotList([]);
      return;
    }
    const queryResult = await graphqlClient.request(gqlQuery, {
      pslInput: {
        page: 1,
        size: config.maxFetchSize,
        warehouse: warehouse?._id,
        product: product?._id,
        status: PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
      },
    });

    const tempFindStockLot = queryResult?.findProductStockLots?.rows;
    console.log('Temp Find Stock Lot', tempFindStockLot);
    setStockLotList(tempFindStockLot);
  };

  useEffect(() => {
    setStockLotList([]);
    fetchingProductStockLot();

    return () => {};
  }, [product]);

  if (!product) {
    return <div></div>;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <div>เลือก</div>
          </TableCell>
          <TableCell>
            <div>เลขอ้างอิง</div>
          </TableCell>{' '}
          {(wmsSetting?.display?.production_date ||
            wmsSetting?.display?.expiration_date) && (
            <TableCell>
              <div>
                {wmsSetting?.display?.production_date && <span>วันผลิต</span>}{' '}
                {wmsSetting?.display?.expiration_date && <span> /หมดอายุ</span>}{' '}
              </div>
            </TableCell>
          )}
          {wmsSetting?.productAttribute?.prefer_customer && (
            <TableCell>
              <div> ลูกค้า</div>
            </TableCell>
          )}
          <TableCell>
            <div> คงเหลือ</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> จำนวนที่เบิก</div>
          </TableCell>
          {wmsSetting?.display?.price && (
            <TableCell>
              <div className="font-bold"> ราคาขาย</div>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {!_.isEmpty(stockLotList) ? (
          stockLotList?.map((lot, index) => (
            <TableRow key={lot.id}>
              <TableCell>
                {' '}
                <Controller
                  control={control}
                  name={`products[${productIndex}].lot[${index}].enable`}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(
                          `products[${productIndex}].lot[${index}].lot`,
                          lot,
                        );
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                {lot?.running_number}
                {lot?.lot_number && (
                  <div className="text-xs">เลขล็อต {lot?.lot_number}</div>
                )}
                <div className="text-xs">เข้าคลัง</div>
                <div className="text-sm">
                  {' '}
                  {dayjs(lot?.inventory_date).format('D MMM BB')}
                </div>
              </TableCell>

              {wmsSetting?.productAttribute?.prefer_customer && (
                <TableCell>{lot?.customer?.name || '-'}</TableCell>
              )}
              {(wmsSetting?.display?.production_date ||
                wmsSetting?.display?.expiration_date) && (
                <TableCell>
                  {wmsSetting?.display?.production_date && (
                    <div>
                      <div className="text-xs">ผลิต</div>
                      <div className="text-sm">
                        {dayjs(lot?.production_date)
                          .locale('th')
                          .format('D MMM BB')}
                      </div>
                    </div>
                  )}
                  {wmsSetting?.display?.expiration_date && (
                    <div>
                      <div className="text-xs">หมดอายุ</div>
                      <div className="text-sm">
                        {dayjs(lot?.expiration_date)
                          .locale('th')
                          .format('D MMM BB')}
                      </div>
                    </div>
                  )}
                </TableCell>
              )}

              <TableCell>
                <MultiUnitProdOrMatBox
                  foundMaterial={product}
                  quantity={lot?.amount}
                />
              </TableCell>
              <TableCell>
                <ProdOrMatQuantityInput
                  controllerName={`products[${productIndex}].lot[${index}].quantity`}
                  artificialControllerName={`products[${productIndex}].lot[${index}].artificial_quantity`}
                  selectedUnitControllerName={`products[${productIndex}].lot[${index}].unit_quantity`}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  selectedProduct={product}
                  index={index}
                  disabled={
                    !watch(`products[${productIndex}].lot[${index}].enable`)
                  }
                />
              </TableCell>
              {wmsSetting?.display?.price && (
                <TableCell>
                  <Controller
                    name={`products[${productIndex}].lot[${index}].price`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ราคาขาย"
                        fullWidth
                        size={'small'}
                      />
                    )}
                  />
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow key={''}>
            <TableCell colSpan={7}>
              <div className="text-center">ไม่มีข้อมูล</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

ProductLotSelector.propTypes = {
  product: PropTypes.object,
  warehouse: PropTypes.object,
  information: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
};
