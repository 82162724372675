import _ from 'lodash';
import filterAllowanceMaterial from '../../../cost-estimation/filterAllowanceMaterial';
import getTotalMaterialUsageOnPlace from '../../../cost-estimation/getTotalMaterialUsageOnPlace';
import getFactoryCapitalCostInDay from '../../../cost-estimation/getFactoryCapitalCostInDay';
import { config } from '../../../../config';

const calculateCCDvideByLotWeight = async ({
  warehouseProfile,
  previousLotList,
  currentLot: inputCurrentLot,
  api,
  isFrontend,
  information,
  FactoryCapitalCost,
  MaterialStockLot,
}) => {
  try {
    const place = warehouseProfile?.place;
    const allowMaterial = filterAllowanceMaterial(previousLotList, place);
    const currentLot = inputCurrentLot || allowMaterial[0];
    const currentDate = currentLot?.receipt_date;
    const enableElement = warehouseProfile?.capital_cost?.element;
    const { total: totalAllLotWeight } = await getTotalMaterialUsageOnPlace({
      place,
      currentDate,
      axios: api,
      maxFetchSize: config.maxFetchSize,
      isFrontend,
      MaterialStockLot,
    });

    const factoryCapitalCost = await getFactoryCapitalCostInDay({
      currentDate,
      isFrontend,
      axios: api,
      maxFetchSize: config.maxFetchSize,
      information,
      enableElement,
      FactoryCapitalCost,
    });

    const capitalCostPerUnit = factoryCapitalCost / totalAllLotWeight;
    const materialListWithCost = _.map(allowMaterial, (each) => {
      const materialCost = (each?.quantity || 0) * capitalCostPerUnit;
      return {
        ...each,
        cost: materialCost,
      };
    });

    const totalCost = _.sumBy(materialListWithCost, 'cost');
    return { cost: totalCost, costList: materialListWithCost };
  } catch (error) {
    console.error('API Error on Capital Cost Divide by Weight', error.message);
    return { cost: 0, costList: [] };
  }
};

export default calculateCCDvideByLotWeight;
