import React from 'react';

import CapitalCostTDABCArtifact from './CapitalCostTDABCArtifact';

function CapitalCostTDABC({
  enableElement,
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
  useTDABCArtifact,
  place,
}) {
  return (
    <div>
      {useTDABCArtifact ? (
        <CapitalCostTDABCArtifact
          previousLotList={previousLotList}
          place={place}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
          enableElement={enableElement}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default CapitalCostTDABC;
