import React from 'react';
import { Controller } from 'react-hook-form';
import {
  CAPITAL_COST_ESTIMATION_METHOD,
  COST_ESTIMATION_ELEMENT,
  LABOR_COST_ESTIMATION_METHOD,
  MATERIAL_COST_ESTIMATION_METHOD,
} from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const EachCostEstimationWarehouse = ({
  control,
  index,
  eachField,
  watch,
  place,
  warehouse,
  remove,
  move,
}) => (
  <Card className="my-2">
    <CardContent>
      <div className="flex flex-wrap">
        <div className="w-full md:w-3/5 my-1">
          <Controller
            control={control}
            name={`warehouses[${index}].use_warehouse_instead`}
            defaultValue={eachField?.use_warehouse_instead}
            render={({ field }) => (
              <div className="flex items-center gap-2">
                <div>ใช้คลังวัตถุดิบ</div>
                <Switch {...field} defaultChecked={field.value} />
                <div>ใช้คลังสินค้า</div>
              </div>
            )}
          />
        </div>
        <div className="w-full md:w-2/5 my-1 flex justify-end">
          <div className="flex gap-1">
            <Button
              size="small"
              variant="contained"
              color="inherit"
              disabled={index === 0}
              onClick={() => move(index, index - 1)}
            >
              เลื่อนขึ้น
            </Button>
            <Button
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => move(index, index + 1)}
            >
              เลื่อนลง
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => remove(index)}
            >
              ลบ
            </Button>
          </div>
        </div>
        {watch(`warehouses[${index}].use_warehouse_instead`) ? (
          <div className="w-full my-1">
            {!_.isEmpty(warehouse?.rows) && (
              <Controller
                control={control}
                defaultValue={eachField?.warehouse}
                name={`warehouses[${index}].warehouse`}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={warehouse?.rows}
                    size="small"
                    placeholder="คลังสินค้า"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
                }
                    renderInput={(params) => (
                      <TextField label="คลังสินค้า" {...params} />
                    )}
                  />
                )}
              />
            )}
          </div>
        ) : (
          <div className="w-full my-1">
            {!_.isEmpty(place?.rows) && (
              <Controller
                control={control}
                name={`warehouses[${index}].place`}
                defaultValue={eachField?.place}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={place?.rows}
                    size="small"
                    placeholder="คลังวัตถุดิบ"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
                }
                    renderInput={(params) => (
                      <TextField label="คลังวัตถุดิบ" {...params} />
                    )}
                  />
                )}
              />
            )}
          </div>
        )}
        <div className="w-full pr-2 my-2">
          <Controller
            control={control}
            name={`warehouses[${index}].name`}
            defaultValue={eachField?.name}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อเรียกขั้นตอนนี้"
                fullWidth
                size="small"
              />
            )}
          />
        </div>
        <div className="my-2 font-semibold w-full">
          วิธีการคำนวณต้นทุนในคลัง
        </div>
        <div className="w-full pr-2 my-2">
          <Controller
            control={control}
            name={`warehouses[${index}].cost_estimation_element`}
            defaultValue={eachField?.cost_estimation_element}
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel>ส่วนที่จะคำนวณ</InputLabel>
                <Select {...field} fullWidth size="small">
                  {_.map(COST_ESTIMATION_ELEMENT, (value, key) => (
                    <MenuItem key={key} value={value?.status_code}>
                      {value.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        {watch(`warehouses[${index}].cost_estimation_element`) ===
          COST_ESTIMATION_ELEMENT.MATERIAL.status_code && (
          <div className="w-full  pr-2 my-2">
            <div>ต้นทุนวัตถุดิบ</div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <Controller
                  control={control}
                  name={`warehouses[${index}].material.method`}
                  defaultValue={eachField?.material?.method}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel>วิธีการคำนวณ</InputLabel>
                      <Select
                        {...field}
                        label="วิธีการคำนวณ"
                        size="small"
                        fullWidth
                      >
                        {_.map(
                          MATERIAL_COST_ESTIMATION_METHOD,
                          (value, key) => (
                            <MenuItem key={key} value={value?.status_code}>
                              {value?.description}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>

              <div className="w-full my-2">
                <Controller
                  control={control}
                  name={`warehouses[${index}].material.use_cost_from_pervious_material`}
                  defaultValue={
                    eachField?.material?.use_cost_from_pervious_material
                  }
                  render={({ field }) => (
                    <FormControlLabel
                      label="ดึงต้นทุนจากวัตถุดิบก่อนหน้าที่ส่งมาถึงตัวเอง"
                      control={
                        <Checkbox {...field} defaultChecked={field.value} />
                      }
                    />
                  )}
                />
              </div>

              {watch(`warehouses[${index}].material.method`) ===
                MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_PERCENT_USED_COST
                  .status_code &&
                !_.isEmpty(place?.rows) && (
                  <div className="w-full my-2">
                    <Controller
                      control={control}
                      name={`warehouses[${index}].material.calculate_percent_from_place`}
                      defaultValue={
                        eachField?.material?.calculate_percent_from_place
                      }
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          options={place?.rows}
                          size="small"
                          placeholder="คลังที่ต้องการให้นำมาคำนวณเปอร์เซนต์"
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          className="z-40"
                          isOptionEqualToValue={(option, value) =>
                            option?._id === value?._id || option?._id === value
                          }
                          // prettier-ignore
                          getOptionLabel={(option) => `${option?.name || ''}`
                        }
                          renderInput={(params) => (
                            <TextField
                              label="คลังที่ต้องการให้นำมาคำนวณเปอร์เซนต์"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
        {watch(`warehouses[${index}].cost_estimation_element`) ===
          COST_ESTIMATION_ELEMENT.LABOR.status_code && (
          <div className="w-full  pr-2 my-2">
            <div>ต้นทุนค่าแรง</div>

            <Controller
              control={control}
              name={`warehouses[${index}].labor.method`}
              defaultValue={eachField?.labor?.method}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>วิธีการคำนวณ</InputLabel>
                  <Select
                    {...field}
                    label="วิธีการคำนวณ"
                    size="small"
                    fullWidth
                  >
                    {_.map(LABOR_COST_ESTIMATION_METHOD, (value, key) => (
                      <MenuItem key={key} value={value?.status_code}>
                        {value?.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            {watch(`warehouses[${index}].labor.method`) ===
              LABOR_COST_ESTIMATION_METHOD.TDABC.status_code && (
              <div className="my-2">
                <Controller
                  control={control}
                  name={`warehouses[${index}].labor.use_artifact_time_in_tdabc`}
                  defaultValue={eachField?.labor?.use_artifact_time_in_tdabc}
                  render={({ field }) => (
                    <div className="flex gap-1">
                      <div>ใช้เวลาจากการผลิตตามเวลาที่บันทึกในล็อต</div>
                      <FormControlLabel
                        label="ใช้เวลาโดยประมาณจากปริมาณผลผลิต"
                        control={
                          <Switch {...field} defaultChecked={field.value} />
                        }
                      />
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        )}
        {watch(`warehouses[${index}].cost_estimation_element`) ===
          COST_ESTIMATION_ELEMENT.CAPITAL_COST.status_code && (
          <div className="w-full  my-2">
            <div>ต้นทุนการลงทุน</div>

            <div>
              <Controller
                control={control}
                name={`warehouses[${index}].capital_cost.element.machine`}
                defaultValue={eachField?.capital_cost?.element?.machine}
                render={({ field }) => (
                  <FormControlLabel
                    label="เครื่องจักร"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name={`warehouses[${index}].capital_cost.element.electric`}
                defaultValue={eachField?.capital_cost?.element?.electric}
                render={({ field }) => (
                  <FormControlLabel
                    label="ค่าไฟฟ้า"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name={`warehouses[${index}].capital_cost.element.water_supply`}
                defaultValue={eachField?.capital_cost?.element?.water_supply}
                render={({ field }) => (
                  <FormControlLabel
                    label="ค่าน้ำประปา"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name={`warehouses[${index}].capital_cost.method`}
                defaultValue={eachField?.capital_cost?.method}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>วิธีการคำนวณ</InputLabel>
                    <Select
                      {...field}
                      label="วิธีการคำนวณ"
                      size="small"
                      fullWidth
                    >
                      {_.map(CAPITAL_COST_ESTIMATION_METHOD, (value, key) => (
                        <MenuItem key={key} value={value?.status_code}>
                          {value?.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              {watch(`warehouses[${index}].capital_cost.method`) ===
                CAPITAL_COST_ESTIMATION_METHOD.TDABC.status_code && (
                <div className="my-2">
                  <Controller
                    control={control}
                    name={`warehouses[${index}].capital_cost.use_artifact_time_in_tdabc`}
                    defaultValue={
                      eachField?.capital_cost?.use_artifact_time_in_tdabc
                    }
                    render={({ field }) => (
                      <div className="flex gap-1">
                        <div>ใช้เวลาจากการผลิตตามเวลาที่บันทึกในล็อต</div>
                        <FormControlLabel
                          label="ใช้เวลาโดยประมาณจากปริมาณผลผลิต"
                          control={
                            <Switch {...field} defaultChecked={field.value} />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </CardContent>
  </Card>
);

export default EachCostEstimationWarehouse;

EachCostEstimationWarehouse.propTypes = {
  control: PropTypes.object,
  index: PropTypes.number,
  eachField: PropTypes.object,
  watch: PropTypes.func,
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  warehouse: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  remove: PropTypes.func,
  move: PropTypes.func,
};
