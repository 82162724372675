import DetailMaterialReportDashboard from './DetailMaterialReportDashboard';
import InventoryAndWarehouseDashboard from './InventoryAndWarehouseDashboard';
import MaterialReportDashboard from './MaterialReportDashboard';
import MaterialSummaryReport from './MaterialSummaryReport';
import ReportDisplay from './ReportDisplay';

export {
  DetailMaterialReportDashboard,
  InventoryAndWarehouseDashboard,
  MaterialReportDashboard,
  MaterialSummaryReport,
  ReportDisplay,
};

export default {
  DetailMaterialReportDashboard,
  MaterialReportDashboard,
  MaterialSummaryReport,
  ReportDisplay,
  InventoryAndWarehouseDashboard,
};
