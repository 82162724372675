import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _, { set } from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import 'dayjs/locale/th';

import NameBox from '../../Box/Common/NameBox';
import RatingTag from '../../Box/CRM/RatingTag';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

export default function QuotationTable({
  quotation,
  page,
  size,
  total,
  setPage,
  setSize,
  handleDelete,
  handlePrintPdf,
  showPreviousQuotation = true,
  showDelete = true,
  showEdit = true,
  setRatingDialogOpen,
  setRatingDialogCustomer = () => {},
}) {
  const me = useSelector((state) => state.me);
  const checkEditButtonAllowance = (row) => {
    if (row?.completed || row?.deleted) {
      return false;
    }

    // Owner of the quotation
    if (row?.creator?._id === me?.userData?._id) {
      return true;
    }

    // Wildcard for Superuser
    if (me?.userData?.role?.level >= 2) {
      return true;
    }

    return false;
  };

  const checkDeleteButtonAllowance = (row) => {
    if (row?.completed || row?.deleted) {
      return false;
    }

    // Wildcard for Superuser
    if (me?.userData?.role?.level >= 2) {
      return true;
    }

    return false;
  };

  const renderStatus = (selectRow) => {
    if (selectRow?.revised) {
      return (
        <div className="bg-gray-300 text-center p-1 rounded-md my-1">
          ถูกสร้างเป็นใบเสนอราคาใหม่แล้ว
        </div>
      );
    }

    if (selectRow?.completed) {
      return (
        <div className="bg-green-300 text-center p-1 rounded-md my-1">
          สั่งผลิตแล้ว
        </div>
      );
    }

    if (selectRow?.deleted) {
      return (
        <div className="bg-red-300 text-center p-1 rounded-md my-1">ปฏิเสธ</div>
      );
    }

    if (selectRow?.express) {
      return (
        <div className="bg-yellow-300 text-center p-1 rounded-md my-1">
          ด่วน
        </div>
      );
    }

    if (selectRow?.revising) {
      return (
        <div className="bg-gray-300 text-center p-1 rounded-md my-1">
          เสนอใหม่
        </div>
      );
    }

    if (selectRow?.revising && selectRow?.express) {
      return (
        <div className="flex flex-row gap-1">
          <div className="bg-gray-300 text-center p-1 rounded-md my-1">
            เสนอใหม่
          </div>
          <div className="bg-yellow-300 text-center p-1 rounded-md my-1">
            ด่วน
          </div>
        </div>
      );
    }

    return <div></div>;
  };

  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
              <col width="10%" />
              <col width="15%" />
              <col width="5%" />
              <col width="8%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">หมายเลข</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">วันที่เสนอราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่กำหนดจัดส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้เสนอราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(quotation?.rows) ? (
                quotation?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>{' '}
                    <TableCell>
                      {row?.running_number}
                      {renderStatus(row)}
                      {row?.revising && showPreviousQuotation && (
                        <div>
                          <Link
                            to={`/spm/quotation/find-previous/${row?.original_quotation}`}
                          >
                            <Button variant="outlined" size="small">
                              ใบเสนอเดิม
                            </Button>
                          </Link>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.start_date).locale('th').format('D MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.expected_date)
                        .locale('th')
                        .format('D MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/crm/customer/mini-detail/${row?.customer?._id}`}
                      >
                        {row?.customer?.type_code || ''} {row?.customer?.name}
                      </Link>
                      {row?.customer && (
                        <RatingTag
                          customer={row?.customer}
                          allowUpdate={_.isFunction(setRatingDialogOpen)}
                          asBox
                          showEvenIsEmpty
                          handleUpdateRating={() => {
                            setRatingDialogOpen(true);
                            setRatingDialogCustomer(row?.customer);
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {_.map(row?.processes, (eachProdOfOrder, prodIndex) => {
                        if (eachProdOfOrder?.produce_material_instead) {
                          return (
                            <div key={prodIndex}>
                              <span>
                                {prodIndex + 1}
                                {'. '}
                              </span>
                              <span className=" font-semibold font-display">
                                {
                                  eachProdOfOrder?.product_as_material
                                    ?.type_code
                                }
                              </span>{' '}
                              <span>
                                {eachProdOfOrder?.product_as_material?.name}
                              </span>{' '}
                              {eachProdOfOrder?.additional_unit ? (
                                <span>
                                  {eachProdOfOrder?.quantity}{' '}
                                  {eachProdOfOrder?.additional_unit}{' '}
                                </span>
                              ) : (
                                <MultiUnitProdOrMatBox
                                  foundMaterial={
                                    eachProdOfOrder?.product_as_material
                                  }
                                  quantity={eachProdOfOrder?.quantity}
                                />
                              )}
                            </div>
                          );
                        }

                        return (
                          <div key={prodIndex}>
                            <span>
                              {prodIndex + 1}
                              {'. '}
                            </span>
                            <span className=" font-semibold font-display">
                              {eachProdOfOrder?.product?.type_code}
                            </span>{' '}
                            <span>{eachProdOfOrder?.product?.name}</span>{' '}
                            {eachProdOfOrder?.additional_unit ? (
                              <span>
                                {eachProdOfOrder?.quantity}{' '}
                                {eachProdOfOrder?.additional_unit}{' '}
                              </span>
                            ) : (
                              <MultiUnitProdOrMatBox
                                foundMaterial={eachProdOfOrder?.product}
                                quantity={eachProdOfOrder?.quantity}
                              />
                            )}
                          </div>
                        );
                      })}
                      {!_.isEmpty(row?.internal_deposits) && (
                        <div className="text-xs bg-gray-200 rounded-sm p-1 my-1">
                          มี {_.size(row?.internal_deposits)} รายการเบิกภายใน
                        </div>
                      )}
                      {row?.deleted && (
                        <div className="text-xs bg-red-200 rounded-sm p-1 my-1">
                          สาเหตุ: {row?.delete_note || '-'}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.total_price)}
                    </TableCell>
                    <TableCell>
                      <NameBox user={row?.sales} />
                    </TableCell>
                    <TableCell>
                      {' '}
                      {!row?.completed && !row?.deleted && (
                        <div className="flex flex-row gap-1  mb-2">
                          <div>
                            <Link
                              to={`/mms/manufacturing-orders/create?quotation=${row?._id}`}
                            >
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                              >
                                สั่งผลิต
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Link to={`/spm/quotation/reject/${row?._id}`}>
                              <Button
                                variant="contained"
                                color="error"
                                size="small"
                              >
                                ปฏิเสธ
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Link to={`/spm/quotation/revise/${row?._id}`}>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                              >
                                Revise
                              </Button>
                            </Link>
                          </div>{' '}
                          <div>
                            <Link
                              to={`/spm/internal-deposit/create?quotation=${row?._id}`}
                            >
                              <Button
                                variant="contained"
                                color="magenta"
                                size="small"
                              >
                                เบิกภายใน
                              </Button>{' '}
                            </Link>
                          </div>
                        </div>
                      )}
                      <div className="flex flex-row flex-wrap gap-1">
                        <div>
                          <Link
                            to={
                              row?.completed || row?.deleted
                                ? `/spm/quotation-log/detail/${row?._id}`
                                : `/spm/quotation/detail/${row?._id}`
                            }
                          >
                            <Button
                              variant="contained"
                              color="info"
                              size={'small'}
                            >
                              รายละเอียด
                            </Button>
                          </Link>
                        </div>{' '}
                        {showEdit && (
                          <Link
                            to={
                              checkEditButtonAllowance(row)
                                ? `/spm/quotation/edit/${row?._id}`
                                : ''
                            }
                          >
                            <Button
                              variant="contained"
                              color={'warning'}
                              size={'small'}
                              disabled={!checkEditButtonAllowance(row)}
                            >
                              แก้ไข
                            </Button>
                          </Link>
                        )}
                        {showDelete && (
                          <Button
                            variant="contained"
                            color={'error'}
                            size={'small'}
                            onClick={() => {
                              handleDelete(row?._id);
                            }}
                            disabled={!checkDeleteButtonAllowance(row)}
                          >
                            ลบ
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color={'teal'}
                          size={'small'}
                          onClick={() => {
                            handlePrintPdf(row);
                          }}
                        >
                          พิมพ์
                        </Button>
                        <Link to={`/spm/quotation/transaction/${row?._id}`}>
                          <Button
                            variant="contained"
                            color="inherit"
                            size="small"
                          >
                            ประวัติกิจกรรม
                          </Button>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={(event) => {
            setSize(event.target.value);
            setPage(1);
          }}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={(event, newValue) => {
            setPage(newValue + 1);
          }}
        />
      </Paper>
    </div>
  );
}

QuotationTable.propTypes = {
  quotation: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  information: PropTypes.object,
  handleDelete: PropTypes.func,
  handlePrintPdf: PropTypes.func,
  showPreviousQuotation: PropTypes.bool,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  setRatingDialogOpen: PropTypes.func,
  setRatingDialogCustomer: PropTypes.func,
};
