import _ from 'lodash';

const filterAllowanceMaterial = (allLots, place) => {
  const filterPlaceLot = _.filter(
    allLots,
    (each) =>
      each?.place?._id?.toString() === place?._id?.toString() ||
      each?.place?.toString() === place?._id?.toString(),
  );

  const allowCategory = _.map(place?.allow_categories, (e) => e.toString());
  const allowMaterialLots = _.filter(filterPlaceLot, (each) =>
    _.includes(allowCategory, each?.material?.material_type?.toString()),
  );

  return allowMaterialLots;
};

export default filterAllowanceMaterial;
