const MATERIAL_GROUP_BY_PAGE_TYPE_INPLACE = {
  DEFAULT: {
    status_code: "DEFAULT",
    description: "แบบปกติ แสดงเป็น PivotTable",
  },
};

const MATERIAL_GROUP_BY_PAGE_TYPE_MISPLACE = {
  DEFAULT: {
    status_code: "DEFAULT",
    description: "แบบปกติ แสดงเป็น PivotTable",
  },
  CLICKABLE_TABLE: {
    status_code: "CLICKABLE_TABLE",
    description: "แสดงเป็นตารางที่คลิกแต่ละวัตถุดิบได้",
  },
};

module.exports = {
  MATERIAL_GROUP_BY_PAGE_TYPE_INPLACE,
  MATERIAL_GROUP_BY_PAGE_TYPE_MISPLACE,
};
