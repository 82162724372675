import React, { Fragment } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ProductAutocomplete } from '../../Inputs';

import ProductLotSelector from './ProductLotSelector';

export function DecreaseProductForm({
  product,
  filterWarehouse,
  control,
  watch,
  setValue,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { wms: wmsSetting },
  } = information;

  const { fields, remove, append } = useFieldArray({
    name: 'products',
    control,
  });

  return (
    <div>
      <div className="mb-2">
        <Card>
          <TableContainer>
            <Table size="small" className="border-lg w-full ">
              <TableHead className="bg-blue-100 w-full">
                <TableRow>
                  <TableCell>
                    <div className="font-bold"> สินค้า /ล็อตที่เลือก</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold"> ดำเนินการ</div>
                  </TableCell>{' '}
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(fields) ? (
                  fields.map((_product, index) => (
                    <Fragment key={_product.id}>
                      <TableRow>
                        <TableCell>
                          <div className="flex items-center gap-1">
                            <div className="mr-2">{index + 1}.</div>
                            {!_.isEmpty(product) && (
                              <Controller
                                name={`products[${index}].product`}
                                control={control}
                                defaultValue={_product?.product || null}
                                render={({ field }) => (
                                  <ProductAutocomplete
                                    field={field}
                                    information={information}
                                    productRows={product}
                                  />
                                )}
                              />
                            )}{' '}
                          </div>
                        </TableCell>{' '}
                        <TableCell>
                          <Button
                            color={'error'}
                            variant="contained"
                            size={'small'}
                            onClick={() => remove(index)}
                          >
                            ลบ
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow key={_product?.id}>
                        <TableCell colSpan={2}>
                          <ProductLotSelector
                            control={control}
                            information={information}
                            product={watch(`products[${index}].product`)}
                            setValue={setValue}
                            warehouse={filterWarehouse}
                            watch={watch}
                            productIndex={index}
                          />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <TableRow key={''}>
                    <TableCell colSpan={2}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  {/** TODO: Find Col Amount */}
                  <TableCell colSpan={2}>
                    <div>
                      {' '}
                      <Button
                        variant="outlined"
                        size={'small'}
                        color={'secondary'}
                        onClick={() => append({})}
                      >
                        เพิ่ม
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                {/* {wmsSetting?.display?.price && (
                  <TableRow key={'total'}>
                    <TableCell
                      colSpan={
                        2 +
                        (wmsSetting?.display?.expiration_date ? 1 : 0) +
                        (wmsSetting?.display?.production_date ? 1 : 0) +
                        (wmsSetting?.display?.lot_number ? 1 : 0)
                      }
                    >
                      <div className="text-right">ทั้งหมด</div>
                    </TableCell>
                    <TableCell>
                      <div className="text-center">
                        {_.size(selectProductLot) ? (
                          <p>
                            {_.sumBy(selectProductLot, function (lot) {
                              return lot.price;
                            })}
                          </p>
                        ) : (
                          <p>{0}</p>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-left">บาท</div>
                    </TableCell>
                  </TableRow>
                )}
                {wmsSetting?.display?.price && (
                  <TableRow key={''}>
                    <TableCell
                      colSpan={
                        2 +
                        (wmsSetting?.display?.expiration_date ? 1 : 0) +
                        (wmsSetting?.display?.production_date ? 1 : 0) +
                        (wmsSetting?.display?.lot_number ? 1 : 0)
                      }
                    >
                      <div className="text-right">ส่วนลด</div>
                    </TableCell>
                    <TableCell>
                      <div className="text-center">
                        <Controller
                          name={'discount'}
                          control={control}
                          defaultValue={discount ? `${discount}` : '0'}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setDiscount(e.target.value);
                                setReload(!reload);
                              }}
                              label="ส่วนลด"
                              fullWidth
                              inputProps={{
                                min: 0,
                                style: { textAlign: 'center' },
                              }}
                              size={'small'}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-left">บาท</div>
                    </TableCell>
                  </TableRow>
                )}
                {wmsSetting?.display?.price && (
                  <TableRow key={''}>
                    <TableCell
                      colSpan={
                        2 +
                        (wmsSetting?.display?.expiration_date ? 1 : 0) +
                        (wmsSetting?.display?.production_date ? 1 : 0) +
                        (wmsSetting?.display?.lot_number ? 1 : 0)
                      }
                    >
                      <div className="text-right">รวม</div>
                    </TableCell>
                    <TableCell>
                      <div className="text-center">
                        {_.size(selectProductLot) ? (
                          <p>
                            {_.sumBy(selectProductLot, function (lot) {
                              return lot.price;
                            }) - discount}
                          </p>
                        ) : (
                          <p>{0}</p>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-left">บาท</div>
                    </TableCell>
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </div>
  );
}

DecreaseProductForm.propTypes = {
  product: PropTypes.arrayOf(PropTypes.object),
  filterWarehouse: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

DecreaseProductForm.defaultProps = {
  product: null,
  warehouse: null,
  type: null,
  selectProductLot: null,
  filterProduct: null,
  filterWarehouse: null,
  discount: null,
};

export default DecreaseProductForm;
