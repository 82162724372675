import React from 'react';
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import { currencyFormatter } from '@iarcpsu/emanufac-utils/functions';
import _ from 'lodash';

const QuotationPriceAndQuantityByStatus = ({ priceAndQuantityRows }) => {
  const successRecord = _.find(priceAndQuantityRows, {
    status: QUOTATION_STATUS.SUCCESS.status_code,
  });
  const inProgressRecord = _.find(priceAndQuantityRows, {
    status: QUOTATION_STATUS.IN_PROGRESS.status_code,
  });
  const cancelRecord = _.find(priceAndQuantityRows, {
    status: QUOTATION_STATUS.CANCLE.status_code,
  });

  const color = {
    green: 'bg-green-300',
    red: 'bg-red-300',
    blue: 'bg-blue-300',
  };

  const renderEachStatus = (
    groupedRecord,
    selectedColor,
    statusDescription,
  ) => (
    <div className="w-full md:w-1/3 px-2">
      <div className={`py-1 px-2 rounded-md my-1 ${color[selectedColor]}`}>
        {statusDescription}
      </div>
      <div>
        <div className="flex justify-between">
          <div>
            <div>จำนวน</div>
            <div>
              {currencyFormatter.format(groupedRecord?.total_quantity || 0)}{' '}
              รายการ
            </div>
          </div>

          <div>
            <div>มูลค่า</div>
            <div>
              {currencyFormatter.format(groupedRecord?.total_price || 0)} บาท
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-wrap">
      {renderEachStatus(
        successRecord,
        'green',
        QUOTATION_STATUS.SUCCESS.description,
      )}{' '}
      {renderEachStatus(
        inProgressRecord,
        'blue',
        QUOTATION_STATUS.IN_PROGRESS.description,
      )}{' '}
      {renderEachStatus(
        cancelRecord,
        'red',
        `${QUOTATION_STATUS.CANCLE.description} + ${QUOTATION_STATUS.REVISED.description}`,
      )}
    </div>
  );
};

export default QuotationPriceAndQuantityByStatus;
