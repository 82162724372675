/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  INVENTORY_WORKFLOW,
  LABOR_COST_ESTIMATION_METHOD,
} from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  findLotWithCostRealTDABC,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';

import { api } from '@/configs';

const LaborTDABCReal = ({
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
  place,
}) => {
  // console.log('Previous Lot List', previousLotList);
  const [allowedLot, setAllowedLot] = useState([]);
  const information = useSelector((state) => state.information);

  const getCost = async () => {
    try {
      const realTDABCLot = await findLotWithCostRealTDABC({
        information,
        api,
        previousLotList,
        isFrontend: true,
        includeAnotherEmployee: true,
        selectedPlace: place,
      });
      setAllowedLot(realTDABCLot);
    } catch (error) {
      console.error('Cannot Get COst', error?.message);
    }
  };

  useEffect(() => {
    getCost();

    return () => {};
  }, []);

  return (
    <div>
      <div className="flex justify-between my-2">
        <div className="font-semibold">
          ต้นทุนค่าแรง: {LABOR_COST_ESTIMATION_METHOD.TDABC.description}
        </div>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell colSpan={2}>
                  <div className="font-bold">ล็อตเริ่มต้น</div>
                </TableCell>
                <TableCell colSpan={2}>
                  <div className="font-bold">ล็อตสิ้นสุด</div>
                </TableCell>
                <TableCell rowSpan={2}>
                  <div className="font-bold">งานที่ทำ</div>
                </TableCell>
                <TableCell rowSpan={2}>
                  <div className="font-bold">พนักงาน</div>
                </TableCell>
                <TableCell rowSpan={2}>
                  <div className="font-bold">ต้นทุนต่อหน่วย</div>
                </TableCell>
                <TableCell rowSpan={2}>
                  <div className="font-bold">เวลา</div>
                </TableCell>{' '}
                <TableCell rowSpan={2}>
                  <div className="font-bold">รวมต้นทุน</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">คลัง</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(allowedLot) ? (
                _.map(allowedLot, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      {_.truncate(row.representStartLotMaterialName, {
                        length: 15,
                      })}
                    </TableCell>
                    <TableCell>{row.startLot?.place?.name}</TableCell>{' '}
                    <TableCell>
                      {_.truncate(row.representEndLotMaterialName, {
                        length: 15,
                      })}
                    </TableCell>
                    <TableCell>{row.endLot?.place?.name}</TableCell>
                    <TableCell>
                      {INVENTORY_WORKFLOW[row?.inventory_workflow]?.status_code}
                    </TableCell>
                    <TableCell>{row?.employee?.firstname}</TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.unitCost)}
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.duration)} นาที
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.cost)} บาท
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default LaborTDABCReal;
