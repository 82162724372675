import _ from 'lodash';
import findMaterialOriginalCost from '../../../cost-estimation/findMaterialOriginalCost';

const calculateCostLumpsumByMaterialOriginal = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
}) => {
  const place = warehouseProfile?.place;
  /**
   * currentLot and currentLotMaterial can be null
   * when it called from cost estimation from beginer log
   * it will automatically be non-working in process
   * and the material will be the material prodcut
   * กรณีไม่ส่ง currentLot มา แสดงว่ามันมาจากหน้าต้นทุนจากล็อตเริ่มต้น
   * จะเป็นการไม่ Specify Current lot จะถถือว่าวัตถุดิบไม่ใช่ WIP และแสดงผลจากทุกอัน
   */
  const currentLotMaterial = currentLot?.material;
  console.log('LABOR COST: Calculate Cost Lumpsum By Material Original');
  const filterLotOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id?.toString() === place?._id?.toString(),
  );
  const allowCategory = _.map(place?.allow_categories, (e) => e?.toString());

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  let filterAllowanceMaterial;
  // กรณีเป็น Work in process เอามาเฉพาะที่สืบอดมาได้เท่านั้น
  if (currentLotMaterial?.is_workin_process) {
    const inputMaterialList = _.map(
      currentLotMaterial?.input_materials,
      (each) => each?.material?.toString(),
    );

    filterAllowanceMaterial = _.filter(
      filterLotOnPlace,
      (each) =>
        _.includes(allowCategory, each?.material?.material_type?.toString()) &&
        _.includes(inputMaterialList, each?.material?._id?.toString()),
    );
    // กรณีไม่เป็น WIP เอามาทุกตัว
  } else {
    filterAllowanceMaterial = _.filter(filterLotOnPlace, (each) =>
      _.includes(allowCategory, each?.material?.material_type?.toString()),
    );
  }

  const getUnitLaborCost = (selectedMaterial, originalMaterial) => {
    if (selectedMaterial?.labor_cost_with_condition?.enable) {
      const foundCondition = _.find(
        selectedMaterial?.labor_cost_with_condition?.conditions,
        (eachCondition) =>
          eachCondition?.material?._id?.toString() ===
            originalMaterial?._id?.toString() ||
          eachCondition?.material?.toString() ===
            originalMaterial?._id?.toString(),
      );
      return foundCondition?.cost || 0;
    }
    if (originalMaterial?.unit_labor_cost) {
      return originalMaterial?.unit_labor_cost;
    }
    return 0;
  };

  const originalCostList = findMaterialOriginalCost({
    allowanceMaterial: filterAllowanceMaterial,
    allLotList: previousLotList,
    materialOnPlace: filterLotOnPlace,
    useCostFromPrevious: true,
  });

  const originalCostWithLaborCost = _.map(originalCostList, (each) => {
    const amountOfOriginalCost = _.size(each?.original_costs);
    const quantityDivideBySize = (each?.quantity || 0) / amountOfOriginalCost;

    const result = {
      ...each,
      original_costs: _.map(each?.original_costs, (eachOriginal) => {
        const unitLaborCost = getUnitLaborCost(
          each?.material,
          eachOriginal?.material,
        );
        return {
          ...eachOriginal,
          unit_labor_cost: unitLaborCost,
          labor_cost: quantityDivideBySize * unitLaborCost,
        };
      }),
    };

    const totalCost = _.sumBy(
      result?.original_costs,
      (eachCost) => eachCost?.labor_cost,
    );

    return { ...result, total_labor_cost: totalCost };
  });

  const totalCostList = _.map(
    originalCostWithLaborCost,
    (each) => each?.total_labor_cost,
  );
  const totalCost = _.sum(totalCostList);
  return { cost: totalCost, costList: originalCostWithLaborCost };
};

export default calculateCostLumpsumByMaterialOriginal;
