/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  CardChildLotByPlace,
  CardInfoMaterialStockLot,
  CostEstimationOverallContainer,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  calculateCostOnProfile,
  currencyFormatter,
} from '@iarcpsu/emanufac-utils/functions';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function DetailCostEstimationOnBeginnerLot
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailCostEstimationOnBeginnerLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const params = useParams();
  const [currentLot, setCurrentLot] = useState(null);
  const [overallResults, setOverallResults] = useState([]);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          page: 1,
          size: config.maxFetchSize,
          ancestor: params.id,
          stockStatus: '',
        }),
      );

      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-stock-lot/${params.id}`,
      );
      setCurrentLot(data);
      dispatch(
        actions.costEstimationProfileAll({
          page: 1,
          size: config.maxFetchSize,
          notHidden: process.env.NODE_ENV === 'development' ? '' : true,
        }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const getCostOnProfile = async () => {
    try {
      const orderProfile = _.orderBy(
        costEstimationProfile?.rows,
        ['createdAt'],
        ['asc'],
      );
      const tempTotalResult = [];
      for await (const profile of orderProfile) {
        const tempResult = await calculateCostOnProfile({
          api,
          currentLot,
          information,
          isFrontend: true,
          previousLotList: [currentLot, ...(materialStockLot.rows || [])],
          profile,
        });
        const tempPayload = {
          name: profile?.name,
          ...tempResult,
        };

        tempTotalResult.push(tempPayload);
      }
      setOverallResults(tempTotalResult);
      console.log('Total Result', tempTotalResult);
    } catch (error) {
      console.error('Get Cost On Profile error', error.message);
    }
  };

  useEffect(() => {
    if (
      costEstimationProfile?.rows &&
      !_.isEmpty(materialStockLot?.rows) &&
      currentLot
    ) {
      console.log('Ready to calculate cost of profile');
      getCostOnProfile();
    }

    return () => {};
  }, [materialStockLot, costEstimationProfile, currentLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <CardInfoMaterialStockLot
            information={information}
            materialStockLot={currentLot}
            showDetail={false}
          />
        </div>
        <div className="my-2 flex flex-wrap">
          <div className="w-full md:w-1/3">
            <Card>
              <CardContent>
                <div className="my-2 font-semibold">ผลการประมาณการ</div>
                {_.map(overallResults, (each, index) => (
                  <div
                    className="flex justify-between items-center"
                    key={index}
                  >
                    <div>{_.truncate(each?.name, { length: 20 })}</div>
                    <div className="text-lg">
                      {currencyFormatter.format(each?.cost)}
                      {'  '}
                      <span className="text-base">บาท</span>
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
            <div className="my-2">
              <CardChildLotByPlace lotList={materialStockLot.rows} />
            </div>
          </div>
          <div className="w-full md:w-2/3 md:pl-1">
            <CostEstimationOverallContainer
              costEstimationProfile={costEstimationProfile}
              information={information}
              currentLot={null}
              previousLotList={[currentLot, ...(materialStockLot.rows || [])]}
            />
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCostEstimationOnBeginnerLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCostEstimationOnBeginnerLot.defaultProps = {
  title: '',
  subtitle: '',
};
