import React, { useContext, useEffect, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  LotInformationForm,
  SelectMaterialToMoveForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  INVENTORY_WORKFLOW,
  MATERIAL_STOCK_STATUS,
  MQ_TASK,
} from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api, app as appConfig } from '@/configs';
import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

function FixedMoveMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { control, setValue, handleSubmit, watch } = useForm();
  const { findWordInPlace } = useContext(WordLocalizeContext);

  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const place = useSelector((state) => state.place);
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const machine = useSelector((state) => state.machine);

  const [destinationPlace, setDestinationPlace] = useState();
  const [dialogLoadingOn, setDialogLoadingOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState();
  const [statusLabel, setStatusLabel] = useState('');
  const [allPlace, setAllPlace] = useState();

  const imsSetting = information?.setting?.ims;

  const getAllPlace = async () => {
    try {
      const res = await api.get(`${process.env.REACT_APP_API_URL}/place`);
      setAllPlace(res.data);
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getDestination = async () => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_API_URL}/place/${query.get('destination')}`,
      );
      setDestinationPlace(res.data);
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  useEffect(() => {
    const tempRef = hash({ id: query.get('source'), date: new Date() });
    setReferenceNumber(tempRef);
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.placeGet(query.get('source')));
    getDestination();
    dispatch(actions.materialStockLotReset());
    dispatch(
      actions.employeeAll({
        page: 1,
        size: appConfig.maxFetchSize,
      }),
    );
    dispatch(actions.departmentAll({ page: 1, size: appConfig.maxFetchSize }));
    dispatch(actions.machineAll({ page: 1, size: appConfig.maxFetchSize }));
    dispatch(
      actions.materialStockLotAll({
        size: appConfig.maxFetchSize,
        page: 1,
        ids: query.get('lots'),
        stockStatus: '',
      }),
    );
    if (!query.get('destination')) {
      getAllPlace();
    }
    return () => {};
  }, []);

  useEffect(() => {
    const inStockMaterial = _.filter(
      materialStockLot?.rows,
      (each) => each?.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
    );

    const inStockMaterialWithStatus = _.map(inStockMaterial, (each) => ({
      ...each,
      selected: true,
    }));
    setValue('materialIn', inStockMaterialWithStatus);

    return () => {};
  }, [materialStockLot]);

  const handleMoveMaterial = async (data) => {
    try {
      // console.log('Data', data);
      setDialogLoadingOn(true);
      const items = _.map(
        _.filter(
          data?.materialIn,
          (eachFilter) => eachFilter?.selected === true,
        ),
        (each) => ({
          material: each?.material,
          material_stock_lot: each,
          quantity: parseFloat(each?.amount),
        }),
      );

      if (_.isEmpty(items)) {
        setDialogLoadingOn(false);
        alert('ไม่ได้เลือกล็อตวัตถุดิบ');
        return;
      }

      const anotherEmployees = _.map(data?.another_employees, (each) => ({
        employee: each?.employee?._id,
        amount: parseFloat(each?.amount),
      }));

      const filterAnotherEmployees = _.filter(
        anotherEmployees,
        (each) => each?.amount > 0,
      );

      const payload = {
        items,
        referenceNumber,
        place: destinationPlace?._id,
        forceMove: query.get('forcemove'),
        employee: data.employee?._id,
        receipt_date: dayjs(data.receipt_date).toDate(),
        order_department: data.order_department,
        remark: data.remark,
        another_employees: filterAnotherEmployees,
        duration: data.duration,
        inventory_workflow: INVENTORY_WORKFLOW.WAITING.status_code,
      };
      console.log('Move Material Payload', payload);
      dispatch(actions.moveMaterialStockLot(query.get('source'), payload));
    } catch (error) {
      console.error('Cannot Move Material', error?.message);
      alert(`ไม่สามารถย้ายวัตถุดิบได้ ${error?.message}`);
    }
  };

  const renderTitle = () => (
    <ViewTitle
      title={
        query.get('move-after-action') === 'true'
          ? findWordInPlace('go_to_next') || 'ย้ายวัตถุดิบ'
          : `${title} ${place?.name}`
      }
      subtitle={subtitle}
    />
  );

  return (
    <div>
      {renderTitle()}
      <LoadingDialogWithTimeout
        action="REFRESH"
        label={statusLabel}
        isLoading={dialogLoadingOn}
      />
      <SocketIOLoading
        handleFail={(args) => {
          setDialogLoadingOn(false);
          alert('ดำเนินการไม่สำเร็จ');
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: args?.message,
          });
        }}
        handleSuccess={() => {
          setDialogLoadingOn(false);
          Swal.fire({
            icon: 'success',
            title: 'ย้ายวัตถุดิบสำเร็จ',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push(
              `/ims/material-stock/move/success/${
                query.get('destination') || ''
              }?source=${query.get('source') || ''}`,
            );
          });
        }}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusLabel}
        taskCode={MQ_TASK.MOVE_MATERIAL.status_code}
      />
      <div className="flex justify-between">
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
      </div>
      <div>
        <Card>
          <CardContent>
            <div className="flex flex-wrap justify-center items-center">
              <div className="w-1/3 lg:w-1/6 p-2">
                <div>คลังต้นทาง</div>
                <div className="text-lg font-semibold">{place?.name}</div>
              </div>
              <div className="w-1/3 lg:w-1/6">
                <ArrowRight />
              </div>
              <div className="w-1/3 lg:w-1/6">
                <div>คลังปลายทาง</div>
                {query.get('destination') ? (
                  <div className="text-lg font-semibold">
                    {destinationPlace?.name}
                  </div>
                ) : (
                  <div>
                    {!_.isEmpty(allPlace?.rows) && (
                      <Autocomplete
                        disablePortal
                        options={allPlace?.rows}
                        size="small"
                        placeholder="เลือกคลัง"
                        onChange={(e, newValue) => {
                          setDestinationPlace(newValue);
                        }}
                        className="z-40"
                        // prettier-ignore
                        getOptionLabel={(option) => `${option?.name || ''}`
                    }
                        renderInput={(params) => (
                          <TextField label="เลือกคลัง" {...params} />
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <LotInformationForm
              control={control}
              defaultValue={null}
              employee={employee}
              departments={department?.rows}
              me={me?.userData}
              machine={machine}
              allowShowMachineInput={false}
              allowGroupEmployeeInput={
                imsSetting?.operationDisplay?.showGroupEmployeeInputWhenInMove
              }
              allowDurationInput={
                imsSetting?.operationDisplay?.showDurationInputWhenInMove
              }
              previousLotReciptDate={materialStockLot?.rows?.[0]?.receipt_date}
              setValue={setValue}
              watch={watch}
            />
          </CardContent>
        </Card>
      </div>
      <div className="my-4 text-lg font-semibold">วัตถุดิบที่จะถูกย้าย</div>
      <div className="my-2">
        <SelectMaterialToMoveForm
          control={control}
          information={information}
          setValue={setValue}
          watch={watch}
        />
      </div>
      <div className="flex justify-end my-2">
        <Button onClick={handleSubmit(handleMoveMaterial)} variant="contained">
          ยืนยันการเบิก
        </Button>
      </div>
    </div>
  );
}

FixedMoveMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

FixedMoveMaterial.defaultProps = {
  title: '',
  subtitle: '',
};

export default FixedMoveMaterial;
