import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ProcessDeliveryForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function ProcessDelivery
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ProcessDelivery({ title, subtitle, onFirstDelivery }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const shippingType = useSelector((state) => state.shippingType);
  const history = useHistory();
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processGet(params.id));
      dispatch(
        actions.shippingTypeAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      delivery: {
        delivered: true,
      },
    },
  });

  const onSubmit = async (data) => {
    try {
      const payload = {
        delivery: {
          ...data.delivery,
          delivered: onFirstDelivery ? true : data?.delivery?.delivered,
          used_to_deliverd: true,
        },
      };
      await dispatch(actions.processPut(params.id, payload));
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลการจัดส่งสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <ProcessDeliveryForm
                  control={control}
                  defaultDelivery={currentProcess?.delivery}
                  shippingType={shippingType}
                  showDeliverySwitch={!onFirstDelivery}
                />
              </CardContent>
            </Card>
            <div className="my-2 flex justify-end">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProcessDelivery.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onFirstDelivery: PropTypes.bool,
};

ProcessDelivery.defaultProps = {
  title: '',
  subtitle: '',
};
