import React from 'react';
import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import { RATING } from '@iarcpsu/emanufac-constant';
import { Button, Dialog, DialogContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const UpdateRatingDialog = ({
  open,
  handleCloseDialog,
  handleUpdate,
  customer,
}) => (
  <Dialog open={open} onClose={handleCloseDialog}>
    <DialogContent>
      <div className="flex justify-between items-center">
        ให้คะแนนคู่ค้า/ลูกค้า คนนี้{' '}
        <Button
          size="small"
          endIcon={<X size={16} />}
          onClick={handleCloseDialog}
        >
          ปิด
        </Button>
      </div>
      <div className="flex">
        {_.map(RATING, (rating, key) => (
          <div key={key}>
            <div
              className="text-2xl hover:scale-125 hover:text-4xl cursor-pointer p-2 hover:bg-gray-100 rounded-md"
              onClick={() => {
                handleCloseDialog(false);
                handleUpdate(customer?._id, key);
              }}
            >
              {rating.emoji}
            </div>
          </div>
        ))}
      </div>
      <div className="text-sm">
        <Link to={`/crm/customer/mini-detail/${customer?._id}`}>
          <Button>ดูและแสดงความคิดเห็นเกี่ยวกับลูกค้า/คู่ค้านี้</Button>
        </Link>
      </div>
    </DialogContent>
  </Dialog>
);

export default UpdateRatingDialog;

UpdateRatingDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleUpdate: PropTypes.func,
  customer: PropTypes.object,
};
