import React from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const ProductAutocomplete = ({ field, productRows, information }) => {
  let usedInformation;
  if (information) {
    usedInformation = information;
  } else {
    usedInformation = useSelector((state) => state.information);
  }

  const autoCompleteSetting =
    usedInformation?.setting?.wms?.autocompleteAttribute;
  return (
    <Autocomplete
      {...field}
      disablePortal
      options={productRows}
      size="small"
      placeholder="เลือกสินค้า"
      onChange={(e, newValue) => {
        field.onChange(newValue);
      }}
      className="z-40"
      fullWidth
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      getOptionLabel={(option) =>
        `${autoCompleteSetting?.type_code ? option?.type_code || '' : ''} ${
          autoCompleteSetting?.name ? option?.name || '' : ''
        } ${
          autoCompleteSetting?.prefer_customer
            ? option?.prefer_customer?.name || ''
            : ''
        } ${
          autoCompleteSetting?.net
            ? `${option?.net || ''} ${option?.unit_net}` || ''
            : ''
        }  ${
          autoCompleteSetting?.package
            ? `${option?.packing_size || ''} ${option?.packing_unit}` || ''
            : ''
        } ${
          autoCompleteSetting?.additionalAttribute1?.enable
            ? `${autoCompleteSetting?.additionalAttribute1?.label || ''} ${
                option?.additional?.[
                  autoCompleteSetting?.additionalAttribute1?.name
                ]
              }` || ''
            : ''
        }  ${
          autoCompleteSetting?.additionalAttribute2?.enable
            ? `${autoCompleteSetting?.additionalAttribute2?.label || ''} ${
                option?.additional?.[
                  autoCompleteSetting?.additionalAttribute2?.name
                ]
              }` || ''
            : ''
        }`
      }
      renderInput={(params) => <TextField label="เลือกสินค้า" {...params} />}
    />
  );
};

export default ProductAutocomplete;

ProductAutocomplete.propTypes = {
  field: PropTypes.object,
  productRows: PropTypes.arrayOf(PropTypes.object),
  information: PropTypes.object,
};
