import CardChildLotByPlace from './CardChildLotByPlace';
import CardCustomer from './CardCustomer';
import CardDeliveryInProcess from './CardDeliveryInProcess';
import CardEmployeeInfo from './CardEmployeeInfo';
import CardInfoMaterialStockLot from './CardInfoMaterialStockLot';
import CardInfoProductStockLot from './CardInfoProductStockLot';
import CardInternalDeposit from './CardInternalDeposit';
import CardListProductOrMaterial from './CardListProductOrMaterial';
import CardManufacturingOrder from './CardManufacturingOrder';
import CardManufacturingOrderAnalysis from './CardManufacturingOrderAnalysis';
import CardMaterialInOutTotalOnMove from './CardMaterialInOutTotalOnMove';
import CardMaterialList from './CardMaterialList';
import CardMaterialStockLotListInPlace from './CardMaterialStockLotListInPlace';
import CardMOList from './CardMOList';
import CardProcessInfo from './CardProcessInfo';
import CardQuotation from './CardQuotation';
import CardStepList from './CardStepList';
import EmployeeWorkLoadCard from './EmployeeWorkLoadCard';
/**
 * @namespace Component/Card
 * @description Component ต่างๆ ที่อยู่ใน Card
 */

export {
  CardChildLotByPlace,
  CardCustomer,
  CardDeliveryInProcess,
  CardEmployeeInfo,
  CardInfoMaterialStockLot,
  CardInfoProductStockLot,
  CardInternalDeposit,
  CardListProductOrMaterial,
  CardManufacturingOrder,
  CardManufacturingOrderAnalysis,
  CardMaterialInOutTotalOnMove,
  CardMaterialList,
  CardMaterialStockLotListInPlace,
  CardMOList,
  CardProcessInfo,
  CardQuotation,
  CardStepList,
  EmployeeWorkLoadCard,
};

export default {
  CardManufacturingOrder,
  CardMOList,
  CardListProductOrMaterial,
  CardStepList,
  CardMaterialList,
  CardMaterialInOutTotalOnMove,
  CardInfoMaterialStockLot,
  CardMaterialStockLotListInPlace,
  EmployeeWorkLoadCard,
  CardManufacturingOrderAnalysis,
  CardInfoProductStockLot,
  CardEmployeeInfo,
  CardProcessInfo,
  CardQuotation,
  CardInternalDeposit,
  CardCustomer,
  CardChildLotByPlace,
  CardDeliveryInProcess,
};
