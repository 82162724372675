import calTDABCArtifact from './calTDABCArtifact';
import calTDABCReal from './calTDABCReal';

const calculateCostTDABC = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
  information,
  PayrollAdjustment,
  ObjectId,
}) => {
  let cost = 0;
  let costList = [];
  let tempData = { cost: 0, costList: [] };
  if (warehouseProfile?.labor?.use_artifact_time_in_tdabc) {
    tempData = await calTDABCArtifact({
      previousLotList,
      currentLot,
      warehouseProfile,
      api,
      isFrontend,
      information,
    });
    cost = tempData.cost;
    costList = tempData.costList;
  } else {
    tempData = await calTDABCReal({
      previousLotList,
      currentLot,
      warehouseProfile,
      api,
      isFrontend,
      information,
      PayrollAdjustment,
      ObjectId,
    });
    cost = tempData.cost;
    costList = tempData.costList;
  }

  return { cost, costList };
};

export default calculateCostTDABC;
