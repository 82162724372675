import React from 'react';
import { useHistory } from 'react-router';
import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import NotFoundImage from '../../assets/images/undraw_page_not_found_re_e9o6.svg';

export default function NotFound() {
  const history = useHistory();
  const theme = useTheme();

  return (
    <div className="p-4 my-8">
      <div className="flex justify-center">
        <div className="w-full lg:w-1/3 my-2">
          <img src={NotFoundImage} className="w-64" />
          <div
            className="font-semibold text-4xl"
            style={{ color: theme.palette.primary.main }}
          >
            ไม่พบหน้านี้
          </div>
          <div className="text-lg my-2">
            ขออภัย เราไม่พบหน้าที่ท่านต้องการ ลิงค์หรือ URL
            ที่ท่านได้รับมาอาจมีความผิดพลาด
          </div>
          <div className="flex  my-4 gap-2">
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับหน้าเดิม
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/');
              }}
            >
              กลับสู่หน้าหลัก
            </Button>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

NotFound.propTypes = {
  message: PropTypes.string,
};

NotFound.defaultProps = {
  message: '',
};
