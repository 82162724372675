import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function ModuleSettingCRM
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ModuleSettingCRM({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  const { control, handleSubmit } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const handleUpdateSetting = async (data) => {
    try {
      const payload = {
        setting: {
          ...information?.setting,
          crm: {
            ...data.setting.crm,
          },
        },
      };
      console.log('payload', payload);
      await dispatch(actions.informationPut(information?._id, payload));
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
      });
      queryDataFromServer();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        text: error.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <form onSubmit={handleSubmit(handleUpdateSetting)}>
            <Card>
              <CardContent>
                <div className="flex flex-wrap">
                  <div className="font-semibold w-full">
                    ตั้งค่าระบบลูกค้า/คู่ค้า
                  </div>
                  <div className="w-full md:w-1/2 my-1">
                    อนุญาติให้ลบคอมเมนต์
                  </div>
                  <div className="w-full md:w-1/2 my-1">
                    <Controller
                      control={control}
                      defaultValue={
                        information?.setting?.crm?.allow_remove_comment
                      }
                      name="setting.crm.allow_remove_comment"
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox defaultChecked={field.value} />}
                          label="อนุญาตให้ลบคอมเมนต์"
                        />
                      )}
                    />{' '}
                  </div>
                </div>
              </CardContent>
            </Card>
            <div className="flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

ModuleSettingCRM.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ModuleSettingCRM.defaultProps = {
  title: '',
  subtitle: '',
};
