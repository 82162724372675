import React, { useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import DesktopDatepicker from './DesktopDatepicker';
import WeekPicker from './WeekPicker';

const DaySpotAndRangePicker = ({
  startDate,
  endDate,
  setStartDate = () => {},
  setEndDate = () => {},
  flex = false,
  size = 'small',
  state = 'date',
  addSearchQuery = false,
  anotherQuery = '',
  defaultUseRange = false,
  enable = true,
  label = 'เลือกวันที่ต้องการ',
  setParentUseRange = () => {},
  showTypeSelector = false,
}) => {
  const [isUseRange, setIsUseRange] = useState(defaultUseRange);
  const [insideStartDate, setInsideStartDate] = useState(startDate);
  const [insideEndDate, setInsideEndDate] = useState(endDate);
  const [insideDate, setInsideDate] = useState(startDate);
  const [selectedState, setSelectedState] = useState(state);
  const history = useHistory();

  const changeMainStateForRange = (newStartDate, newEndDate) => {
    const newStart = dayjs(newStartDate).startOf('date').toDate();
    const newEnd = dayjs(newEndDate).endOf('date').toDate();
    setStartDate(newStart);
    setEndDate(newEnd);
  };

  const changeMainStateForNonRange = (newDate) => {
    setStartDate(dayjs(newDate).startOf(selectedState).toDate());
    setEndDate(dayjs(newDate).endOf(selectedState).toDate());
  };

  const stateInfo = {
    date: {
      type_code: 'date',
      thai_name: 'วัน',
      views: ['day'],
      dayjsAddingWord: 'day',
      displayFormat: 'DD/MM/YYYY',
    },
    week: {
      type_code: 'week',
      thai_name: 'สัปดาห์',
      views: ['day'],
      dayjsAddingWord: 'week',
      displayFormat: 'DD/MM/YYYY',
    },
    month: {
      type_code: 'month',
      thai_name: 'เดือน',
      views: ['month'],
      dayjsAddingWord: 'month',
      displayFormat: 'MMMM YYYY',
    },
    year: {
      type_code: 'year',
      thai_name: 'ปี',
      views: ['year'],
      dayjsAddingWord: 'year',
      displayFormat: 'YYYY',
    },
  };

  return (
    <div>
      <div className={` ${flex ? 'md:flex items-center' : ''}`}>
        <div className="w-full">
          <div className="flex items-center gap-2 w-full">
            <div>จุดเวลา</div>
            <Switch
              disabled={!enable}
              onChange={(event) => {
                setIsUseRange(event.target.checked);
                setParentUseRange(event.target.checked);
                if (addSearchQuery) {
                  history.push(
                    `${anotherQuery}&isUseRange=${
                      event.target.checked === true ? 'true' : ''
                    }`,
                  );
                }
                if (event.target.checked) {
                  setInsideStartDate(startDate);
                  setInsideEndDate(endDate);
                }
              }}
              checked={isUseRange}
            />
            <div>ช่วงเวลา</div>
          </div>
          {showTypeSelector && !isUseRange && (
            <div className="my-2">
              <ToggleButtonGroup
                value={selectedState}
                exclusive
                onChange={(e) => {
                  const date = dayjs(insideDate)
                    .startOf(stateInfo[e.target.value]?.dayjsAddingWord)
                    .toDate();

                  setSelectedState(e.target.value);
                  setInsideDate(date);
                  changeMainStateForNonRange(date);
                  changeMainStateForRange(
                    date,
                    dayjs(date)
                      .add(1, stateInfo[e.target.value]?.dayjsAddingWord)
                      .endOf('date')
                      .subtract(1, 'day')
                      .toDate(),
                  );
                }}
                size="small"
              >
                <ToggleButton value="date">รายวัน</ToggleButton>
                <ToggleButton value="week">รายสัปดาห์</ToggleButton>
                <ToggleButton value="month">รายเดือน</ToggleButton>
                <ToggleButton value="year">รายปี </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
        </div>
        <div className={'w-full mx-2'}>
          {isUseRange ? (
            <div className="flex gap-2">
              <DesktopDatepicker
                disabled={!enable}
                label="เริ่มต้น"
                size={size}
                onChange={(newValue) => {
                  setInsideStartDate(newValue);
                  changeMainStateForRange(newValue, insideEndDate);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newValue).format(
                        'YYYY-MM-DD',
                      )}&end=${dayjs(insideEndDate).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
                value={insideStartDate}
              />{' '}
              <DesktopDatepicker
                label="สิ้นสุด"
                size={size}
                disabled={!enable}
                onChange={(newValue) => {
                  setInsideEndDate(newValue);
                  changeMainStateForRange(insideStartDate, newValue);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(insideStartDate).format(
                        'YYYY-MM-DD',
                      )}&end=${dayjs(newValue).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
                value={insideEndDate}
                inputFormat={stateInfo[selectedState]?.displayFormat}
              />
            </div>
          ) : (
            <div className="my-2 ">
              {selectedState === 'week' ? (
                <WeekPicker
                  label={'เลือกสัปดาห์'}
                  size="small"
                  onChange={(newValue) => {
                    setInsideDate(newValue);
                    changeMainStateForNonRange(newValue);
                    if (addSearchQuery) {
                      history.push(
                        `${anotherQuery}&isUseRange=${
                          isUseRange === true ? 'true' : ''
                        }&start=${dayjs(newValue).format(
                          'YYYY-MM-DD',
                        )}&end=${dayjs(newValue).format('YYYY-MM-DD')}`,
                      );
                    }
                  }}
                  value={insideDate}
                />
              ) : (
                <DesktopDatepicker
                  label={label}
                  size={size}
                  disabled={!enable}
                  views={stateInfo[selectedState]?.views}
                  onChange={(newValue) => {
                    setInsideDate(newValue);
                    changeMainStateForNonRange(newValue);
                    if (addSearchQuery) {
                      history.push(
                        `${anotherQuery}&isUseRange=${
                          isUseRange === true ? 'true' : ''
                        }&start=${dayjs(newValue).format(
                          'YYYY-MM-DD',
                        )}&end=${dayjs(newValue).format('YYYY-MM-DD')}`,
                      );
                    }
                  }}
                  value={insideDate}
                  fullWidth
                  inputFormat={stateInfo[selectedState]?.displayFormat}
                />
              )}
            </div>
          )}
        </div>
        {!isUseRange && (
          <div className="w-full flex gap-2 items-center">
            <div>
              <Button
                variant="outlined"
                disabled={!enable}
                startIcon={<ArrowLeft />}
                onClick={() => {
                  const newDay = dayjs(insideDate).subtract(
                    1,
                    stateInfo[selectedState]?.dayjsAddingWord,
                  );
                  setInsideDate(newDay);
                  changeMainStateForNonRange(newDay);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newDay).format('YYYY-MM-DD')}&end=${dayjs(
                        newDay,
                      ).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
              >
                {stateInfo?.[selectedState]?.thai_name}ก่อนหน้า
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                disabled={!enable}
                endIcon={<ArrowRight />}
                onClick={() => {
                  const newDay = dayjs(insideDate).add(
                    1,
                    stateInfo[selectedState]?.dayjsAddingWord,
                  );
                  setInsideDate(newDay);
                  changeMainStateForNonRange(newDay);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newDay).format('YYYY-MM-DD')}&end=${dayjs(
                        newDay,
                      ).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
              >
                {stateInfo[selectedState]?.thai_name}ถัดไป
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaySpotAndRangePicker;

DaySpotAndRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  /** ให้มันอยู่บรรทัดเดียวกันมั้ย */
  flex: PropTypes.bool,
  /** Size ของ Datepicker small หรือ medium */
  size: PropTypes.string,
  /** ใช้สำหรับเลือกว่าจะให้เป็นวัน หรือเดือน เป็นค่า date หรือ month เท่านั้น */
  state: PropTypes.string,
  addSearchQuery: PropTypes.bool,
  anotherQuery: PropTypes.string,
  defaultUseRange: PropTypes.bool,
};
