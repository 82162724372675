import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { useQuery } from '@/utils/functions';

/**
 * @function SuccessMoveMaterial
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function SuccessMoveMaterial({ title, subtitle }) {
  const query = useQuery();
  const params = useParams();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="text-2xl text-center font-semibold">
              ย้ายวัตถุดิบสำเร็จ
            </div>
            <div className="text-center">จะดำเนินการอย่างไรต่อไป</div>
            <div className="flex justify-center my-4">
              <div className="md:1/2">
                <div className="my-2 flex gap-2 justify-between">
                  <Link
                    to={`/ims/material-stock/group?by=material-source&place=${query.get(
                      'source',
                    )}`}
                  >
                    <Button variant="contained" color="primary">
                      กลับไปหน้าเดิม
                    </Button>
                  </Link>
                  <Link to={`/ims/material-stock/place/${query.get('source')}`}>
                    <Button variant="contained" color="secondary">
                      กลับไปที่คลังวัตถุดิบเดิม
                    </Button>
                  </Link>
                  <Link to={`/ims/material-stock/place/${params.id}`}>
                    <Button variant="contained" color="success">
                      ไปที่คลังวัตถุดิบเป้าหมาย
                    </Button>
                  </Link>
                </div>{' '}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
SuccessMoveMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SuccessMoveMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
