import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  MaterialLotCostEstimationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function CostEstimationByLots
 * @description Display a collections or a list of CostEstimationByLots from database
 */

export default function CostEstimationByProcess({ title, subtitle }) {
  const dispatch = useDispatch();
  const processOfOrder = useSelector((state) => state.process);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [total, setTotal] = useState(0);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.processAll({
          page,
          size,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
        }),
      );
    } catch (error) {}
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate]);

  useEffect(() => {
    if (processOfOrder) {
      setTotal(processOfOrder.total);
    }

    return () => {};
  }, [processOfOrder]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (processOfOrder.isLoading) {
    return <Loading />;
  }
  if (!processOfOrder.isLoading && processOfOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/5 pr-2"></div>
                <div className="w-full md:w-4/5">
                  <DaySpotAndRangePicker
                    flex
                    enable
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">หมายเลขไลน์การผลิต</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">วันที่สร้าง</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(processOfOrder?.rows) ? (
                    _.map(processOfOrder?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}{' '}
                          </div>
                        </TableCell>
                        <TableCell>{row?.running_number}</TableCell>
                        <TableCell>
                          {dayjs(row.createdAt).format('D MMM BBBB HH:mm')}
                        </TableCell>
                        <TableCell>
                          <Link to={`/ims/cost-driver/process/${row?._id}`}>
                            <Button variant="contained" size="small">
                              รายละเอียด
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(event, newPage) => setPage(newPage + 1)}
              onRowsPerPageChange={(event) => setSize(event.target.value)}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

CostEstimationByProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CostEstimationByProcess.defaultProps = {
  title: '',
  subtitle: '',
};
