import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function MainDashboard
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DashboardMenu({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const history = useHistory();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.informationAll());
    } catch (error) {
      alert('เกิดข้อผิดพลาดในการโหลดข้อมูล');
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const dashboardItemList = [
    {
      title: 'การผลิต',
      subtitle: 'คำสั่งผลิตของรายเดือน ทั้งรายบุคคลและทั้งแบบองค์กร',
      link: '/mms/dashboard',
    },
    {
      title: 'สถานะคลัง',
      subtitle: 'สินค้าและวัตถุดิบคงเหลือในคลัง ณ ปัจจุบัน',
      link: '/ims/dashboard/inventory-warehouse',
    },
    {
      title: 'รายงานเบิก/นำเข้าวัตถุดิบ',
      subtitle: 'วัตถุดิบที่นำเข้าและเบิกออกในแต่ละวัน / เดือน',
      link: '/ims/dashboard/summary',
    },
    {
      title: 'การจัดซื้อวัตถุดิบ',
      subtitle: 'รายการที่ต่ำกว่าจุดสั่งซื้อ และ ที่รอการอนุมัติ',
      link: '/dashboard/purchase',
    },
    {
      title: 'รายงานพนักงานในการผลิต',
      subtitle: 'รายงานการทำงานของพนักงานทั้งหมด',
      link: '/mms/dashboard/employee',
    },
    {
      title: 'รายงานใบเสนอราคา',
      subtitle: 'รายงานการเสนอราคา สินค้าที่เสนอราคา พนักงาน',
      link: '/spm/quotation/dashboard',
    },
  ];

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {dashboardItemList.map((item, index) => (
              <Link to={item?.link} key={index}>
                <div className="p-4 bg-white rounded-lg shadow-md h-full">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-lg font-semibold text-gray-800">
                        {item.title}
                      </p>
                      <p className="text-sm text-gray-600">{item.subtitle}</p>
                    </div>
                    <button className="p-2 bg-blue-500 text-white rounded-full">
                      <i className="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

DashboardMenu.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DashboardMenu.defaultProps = {
  title: '',
  subtitle: '',
};
